<template>
<v-form ref="form">
    <v-container>
      <v-dialog v-model="showDialog" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >We have a winniner!</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-btn color="blue darken-1" text @click="closeDialog"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
      <v-dialog v-model="showNoWinningsDialog" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >No winnings. Best of luck next time.</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeNoWinningsDialog"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card class="card" dark>
            <v-autocomplete
              class="mx-2 pb-2 mt-2"
              clearable
              :persistent-hint="true"
              solo
              light
              hide-details
              auto-select-first
              :label="'Select a session'"
              v-model="selectedSchedule"
              :items="getScheduleList"
              :item-value="(obj) => obj"
              :item-text="
                (obj) => obj.title + ' - ' + obj.date
              "
            ></v-autocomplete>
            <v-row v-if="selectedSchedule && !proceed" class="mx-2">
              <v-col
                md="3"
                v-for="product in getProductsForSale"
                :key="product.id + product.doublePrice"
              >
                <v-card max-width="344" @click="cardClickEvent(product)" light>
                  <v-card-title>{{
                    product.productTemplateDto.title
                  }}</v-card-title>
                  <v-card-subtitle>{{
                    product.doublePrice ? "Double" : "Single"
                  }}</v-card-subtitle>
                  <v-img
                    height="60"
                    contain
                    :src="
                      'data:image/jpeg;base64,' +
                      product.productTemplateDto.logo
                    "
                  />
                  <v-card-actions>
                    <v-card-text>€{{ product.price }}</v-card-text>
                    <span> {{ itemCount(product) }} </span>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row align="center"  class="mx-2" v-if="selectedSchedule && proceed">
                <v-col/>
                <v-col md="4" cols="10">
                    <v-btn @click="keyPadEvent('1')">1</v-btn>
                    <v-btn class="mx-5" @click="keyPadEvent('2')">2</v-btn>
                    <v-btn @click="keyPadEvent('3')">3</v-btn>
                </v-col>
                <v-col/>
          </v-row>
          <v-row align="center"  class="mx-2" v-if="selectedSchedule && proceed">
                <v-col/>
                <v-col md="4" cols="10">
                    <v-btn @click="keyPadEvent('4')">4</v-btn>
                    <v-btn class="mx-5" @click="keyPadEvent('5')">5</v-btn>
                    <v-btn @click="keyPadEvent('6')">6</v-btn>
                </v-col>
                <v-col/>
          </v-row>
          <v-row align="center"  class="mx-2" v-if="selectedSchedule && proceed">
                <v-col/>
                <v-col md="4" cols="10">
                    <v-btn @click="keyPadEvent('7')">7</v-btn>
                    <v-btn class="mx-5" @click="keyPadEvent('8')">8</v-btn>
                    <v-btn @click="keyPadEvent('9')">9</v-btn>
                </v-col>
                <v-col/>
          </v-row>
          <v-row align="center"  class="mx-2" v-if="selectedSchedule && proceed">
                <v-col/>
                <v-col md="4" cols="10">
                    <v-btn @click="keyPadEvent('.')">.</v-btn>
                    <v-btn class="mx-5" @click="keyPadEvent('0')">0</v-btn>
                    <v-btn @click="keyPadEvent('DEL')">DEL</v-btn>
                </v-col>
                <v-col/>
          </v-row>
            <v-card-actions>
              <v-row>
                <v-col cols="2">
              <v-autocomplete
                    class="mt-2 mb-2"
                    hide-details
                    v-if="selectedSchedule && !proceed"
                    :return-object="false"
                    v-model="selectedCustomer"
                    :items="getCustomerList"
                    clearable
                    :persistent-hint="true"
                    solo
                    light
                    :item-text="
                    (obj) =>
                      obj.displayName +
                      ', ' +
                      obj.firstName +
                      ' ' +
                      obj.lastName +
                      ', ' +
                      obj.email +
                      ', ' +
                      obj.barCodeId
                      "
                      :item-value="(obj) => obj"
                      :label="'Find a customer'"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-card-text>Due: {{ totalDueFormatted }}</v-card-text>
                </v-col>
                <v-col>
                  <v-card-text>Cash: {{ cashTendered }}</v-card-text>
                </v-col>
                <v-col>
                  <v-card-text>Change: {{ changeDue }}</v-card-text>
                </v-col>
                <v-col cols="4" align="right">
                  <v-btn class="accent mt-2 mb-2 mr-2" :disabled="items.length == 0" @click="clear"
                    >Clear</v-btn>
                  <v-btn class="primary main_button mt-2 mb-2" :disabled="items.length == 0" v-if="!proceed" @click="proceedToSale"
                  >Proceed</v-btn>
                  <v-btn class="primary main_button mt-2 mb-2" :disabled="amountTendered == 0" v-if="proceed" @click="buyItems"
                  >Complete</v-btn>
                </v-col>
              </v-row>
            </v-card-actions
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  <div id="printMe">
  </div>
</v-form>
</template>

<script>
import * as PrintJS from "print-js";
import { mapActions, mapGetters } from "vuex";
import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import {EventBus} from "@/main";
export default {
  name: "PointOfSale",
  created() {
    this.fetchUpcoming();
    this.fetchCustomers();
    EventBus.$on("BARCODE_SCANNED", data => {
      this.onDecode(data);
    });
  },
  destroyed: function () {

  },
  directives: {
    print   
  }, 
  data() {
    return {
      selectedSchedule: null,
      selectedCustomer: null,
      items: [],
      products: [],
      books: [],
      booksInPackage: [],
      booksNotInPackage: [],
      amountTendered: "",
      proceed: false,
      pay: false,
      bookInput: null,
      showDialog: false,
      showNoWinningsDialog: false,
      value:  "",
      show:  1,
       printObj: {
              url: "book/printOnDemand/123",
       }
    };
  },
  computed: {
    ...mapGetters(["getScheduleList", "getProductsForSale", "getSelectedBook", "getCustomerList", "getProductOrderPurchased"]),
    DOWNLOAD_URL: () => process.env.VUE_APP_BASE_URL + ENDPOINTS.BOOKS.PRINT_ON_DEMAND_BOOKS,
    totalDueFormatted() {
      return this.currencyFormat(this.totalDue());
    },
    cashTendered() {
      if ("" == this.amountTendered) {
        return this.currencyFormat(0);
      }
      else {
        return this.currencyFormat(parseFloat(this.amountTendered));
      }
    },
    changeDue() {
      console.log("total due " + this.totalDue);
      let change = this.amountTendered - this.totalDue();
      return this.currencyFormat(change > 0 ? change : 0);
    }
  },
  methods: {
    ...mapActions(["fetchUpcoming", "fetchProductsForSchedule", "fetchBooksById"
    , "fetchBookById", "fetchCustomers","posPurchase", "setCurrentPage"]),
    closeDialog() {
      this.showDialog = false;
    },
    closeNoWinningsDialog() {
      this.showNoWinningsDialog = false;
    },
    itemCount(product) {
      
      if (product.productTemplateDto.packaged) {
        return product.count;
      }
      else {
        let count = 0;
        let matchedBooks = this.booksNotInPackage.filter((book) => product.productTemplateDto.id == book.productTemplateDto.id);
        let matchedProducts = this.products.filter((p) => (p.id == product.id && p.doublePrice == product.doublePrice));
        if (matchedBooks != null) {
          count = count + matchedBooks.length;
        }
        if (matchedProducts.length > 0 ) {
          count = count + matchedProducts[0].quantity;
        }
        return count;
      }
    },
    onDecode(result) {
      console.log(result)
      if (!this.checkCustomers(result) && !this.items.includes(result)) {
        this.fetchBookById(result);
      }
      
    },
    checkCustomers(scannedID) {
      let found = false;
      let matchedRows = this.getCustomerList.filter((customer) => scannedID == customer.barCodeId);
      if (matchedRows.length > 0) {
        this.selectedCustomer = matchedRows[0];
        found = true;
      }
      matchedRows = this.getCustomerList.filter((customer) => scannedID == customer.id);
      if (matchedRows.length > 0) {
        this.selectedCustomer = matchedRows[0];
        found = true;
      }
      
      return found;
    },
    cardClickEvent(product) {
        console.log("productId = " + product.doublePrice);
        document.activeElement.blur();
        
        product.doublePrice ? this.items.push(product.id+":double") : this.items.push(product.id);
        let matchedRows = this.products.filter((p) => (p.id == product.id && p.doublePrice == product.doublePrice));
        console.log("matchedRows = " + matchedRows[0]);
        if (matchedRows[0] != null) {
          matchedRows[0].quantity++;
        }
        else {
          this.products.push({id : product.id, quantity: 1, doublePrice: product.doublePrice});
        }
        
        console.log("products = " + this.products[0].quantity);
    },
    keyPadEvent(keyCode) {
      if ("DEL" == keyCode) {
        this.amountTendered = this.amountTendered.slice(0, -1);
      }
      else if ("." == keyCode) {
        const result = this.amountTendered.split(/[.]/);
        if (result.length == 1) {
          this.amountTendered = this.amountTendered + keyCode;
        }
      }
      else {
        const result = this.amountTendered.split(/[.]/);
        if (result.length == 2) {
          if (result[1].length < 2) {
            this.amountTendered = this.amountTendered + keyCode;
          }
        }
        else {
          this.amountTendered = this.amountTendered + keyCode;
        }
        
      }
      
    },
    totalDue() {
      let total = 0;
      for (let i = 0; i < this.booksNotInPackage.length; i++) {
        let book = this.books[i];
        let matchedRows = this.getProductsForSale.filter((product) => product.productTemplateDto.id == book.productTemplateDto.id);
        let price =  matchedRows[0].doublePrice ? matchedRows[0].doublePrice : matchedRows[0].price;
        total = total + price; 
      }

      let packages = this.getProductsForSale.filter((product) => product.productTemplateDto.packaged);
      for (let i = 0; i < packages.length; i++) {
          let packageProduct = packages[i];
          let count = packageProduct.count == null ? 0 : packageProduct.count;
          total = total + count*packageProduct.price;
      }
      for (let i = 0; i < this.products.length; i++) {
        let product = this.products[i];
        let matchedRows = this.getProductsForSale.filter((p) => (p.id == product.id && p.doublePrice == product.doublePrice));
        let price =  matchedRows[0].doublePrice ? matchedRows[0].doublePrice : matchedRows[0].price;
        total = total + product.quantity*price;
      }

      return total;
    },
    proceedToSale(){
      if (this.totalDue() == 0) {
        this.buyItems();
      }
      else {
        this.proceed = true;
      }
      
    },
    clear() {
      this.amountTendered = "";
      this.items = [];
      this.books = [];
      this.products = [];
      this.booksInPackage = [];
      this.booksNotInPackage = [];
      this.selectedCustomer = null;
      this.proceed = false;
      let packages = this.getProductsForSale.filter((product) => product.productTemplateDto.packaged);
      for (let i = 0; i < packages.length; i++) {
          let packageProduct = packages[i];
          packageProduct.count = 0;
      }
    },
    currencyFormat(value) {
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
      });

      return formatter.format(value); 
    },
    buyItems() {
      let productOrderModel = {
          email : this.selectedCustomer? this.selectedCustomer.email : null,
          purchaseConfirmationId: Math.floor(Math.random() * 100000000),
          productsOrdered: [],
      };
      for (let i = 0; i < this.books.length; i++) {
        let book = this.books[i];
        let matchedRows = this.getProductsForSale.filter((product) => product.productTemplateDto.id == book.productTemplateDto.id);
        let purchaseProductModel = {
          bookId: book.id,
          productId: matchedRows[0].id,
        }
        productOrderModel.productsOrdered.push(purchaseProductModel);
      }
      for (let i = 0; i < this.products.length; i++) {
        let product = this.products[i];
        console.log("product = " + product);
        let purchaseProductModel = {
          productId: product.id,
          quantity: product.quantity,
          isDouble : product.doublePrice ? true : false
        }
        productOrderModel.productsOrdered.push(purchaseProductModel);
      }
      this.posPurchase(productOrderModel);
      this.proceed = false;
      this.clear();
    }
  },
  mounted() {
    let scheduleId = this.$route.params.scheduleId;
    let schedules = this.getScheduleList.filter((s) => s.id == scheduleId);
    this.selectedSchedule = schedules[0];
    this.setCurrentPage('Sales')
  },
  watch: {
    selectedSchedule(schedule) {
      if (schedule) {
        this.fetchProductsForSchedule(schedule.id);
        document.activeElement.blur();
      }
    }, 
    getProductOrderPurchased(productOrderPurchased) {
      PrintJS(this.DOWNLOAD_URL + productOrderPurchased.purchaseConfirmationId);
    },
    getSelectedBook(newBook) {
      console.log(newBook);
      if (newBook.winnerDtos.length > 0) {
        this.showDialog = true;
        //book already played
        return;
      }
      if (newBook.archived) {
        this.showNoWinningsDialog = true;
        return;
      }
      if (!this.items.includes(newBook.id)) {
        this.items.push(newBook.id);
        this.books.push(newBook);
        this.booksNotInPackage.push(newBook);

        let packages = this.getProductsForSale.filter((product) => product.productTemplateDto.packaged);
        for (let i = 0; i < packages.length; i++) {
          let packageProduct = packages[i];
          let packageCompleted = false;
          let booksToRemove = [];
          for (let j = 0; j < packageProduct.productTemplateDto.children.length; j++) {
            let child = packageProduct.productTemplateDto.children[j];
            let matchedRows = [];
            matchedRows = this.booksNotInPackage.filter((book) => child.id == book.productTemplateDto.id);
            if (matchedRows.length == child.quantity) {
              booksToRemove.push(...matchedRows);
              console.log(booksToRemove);
              packageCompleted = true;
            }
            else {
              packageCompleted = false;
            }
          }
          if (packageCompleted) {
            if (packageProduct.count == null) {
              packageProduct.count = 0;
            }
            packageProduct.count++;
            this.booksNotInPackage = this.booksNotInPackage.filter((book) => !booksToRemove.includes(book));
            this.booksInPackage.push(...booksToRemove);
          }
        }
      }
      
    }
  },
};
</script>
