<template>
    <v-container>
        <v-card class="mt-5">
            <div class="text-right text-h5 font-weight-medium pa-3">
                Create Package
            </div>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="ml-12 mr-12 mt-8"
            >
            <v-row>
                <v-col cols="1" />
                <v-col cols="4">
                    <v-text-field
                        v-model="packageTemplate.title"
                        :counter="30"
                        :rules="nameRules"
                        id="titleTxt"
                        label="Title"
                    ></v-text-field>
                </v-col>
                <v-col cols="1" />
                <v-col cols="4">
                    <v-file-input
                        label="Logo"
                        v-model="logo"
                        :rules="imageRules"
                        id="logoFileInput"
                        required
                        accept="image/png, image/jpeg, image/bmp"
                        show-size
                        clearable
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" />
                <v-col cols="4">
                    <v-select
                        v-model="children"
                        :items="selectableTemplates"
                        item-value="id"
                        :rules="templateRules"
                        label="Templates To Include"
                        clearable
                        id="templateToIncludeSelect"
                        multiple
                        max
                    >
                        <template v-slot:selection="{ item }">
                            <v-chip text-color="white" :color="determineChipColour(item)">{{
                                item.title
                            }}</v-chip>
                        </template>

                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.title }}</span>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="item.flyer"
                          text-color="white"
                          color="success"
                          small
                          class="ml-2"
                          >Flyer</v-chip
                        >
                        <span v-else>{{ item.pages }} Pages</span>
                        <img
                          contain
                          class="logo"
                          :src="'data:image/jpeg;base64,' + item.logo"
                        />
                      </v-row>

                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

                    </v-select>
                </v-col>


            </v-row>

            <v-row class="mt-8">
                <v-col cols="9">
                    <v-data-table
                    :headers="headers"
                    :items="playableGames"
                    item-key="tableId"
                    :show-select="false"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    class="page__table"
                    >
                        <template v-slot:body="props">
                            
                            <tr v-for="(item, index) in props.items" :key="index">
                                <td>{{ item.title }}</td>
                                <td>
                              
                                    <v-text-field
                                    :rules="quantityRules"
                                    v-model="item.quantity"
                                    label="Quantity"
                                    type="number"
                                    ></v-text-field>

                                
                                </td>
                            </tr>
                            
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <v-row class="mt-16">
                <v-col class="text-right pt-3">
                    <v-btn class="mr-4" @click="reset"> Clear </v-btn>
                    <v-btn color="primary" class="mr-4" @click="submit"> Save </v-btn>
                </v-col>
            </v-row>

            </v-form>
        </v-card>
    </v-container>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "PackageAdd",   
    created() {
    },
    data: () => ({
        headers: [
             {
                text: "Description",
                value: "description",
                sortable: false,
                width: "80%",
            },
            { text: "Quantity", sortable: false, width: "20%" },
        ],
        logo: null,
        children: [],
        imageRules: [
            v => !!v || 'Logo is required',
            value => !value || value.size < 128000 || 'Logo size should be less than 128 kb'
        ],
        templateRules: [
            v => !!v || 'Template selection is required',
            (v) =>  v.length >0 || "Template selection is required"
        ],
        nameRules: [
            (v) => !!v || "Title is required",
            (v) => (v && v.length <= 30) || "Title must be less than 30 characters",
            v => /^[\w\- ]+$/i.test(v) || 'Invalid Title',
        ],
        quantityRules: [
            v => !!v || 'Quantity is required',
        ],
        quantities: [1,2,3,4,5,6,7,8,9,10],
    }),
    props : {
        packageTemplate: {
            type: Object,
            required: false,
            default: function () {
                return { 
                    title: "",
                    children: [],
                    packaged: true,
                    type: "BINGO"
                    }
            }
        }
    },
    methods: {
        ...mapActions(["saveTemplate", "fetchColours"]),
        submit() {
            if(this.$refs.form.validate()){
                this.packageTemplate.children = [];
                for (let i = 0; i < this.children.length; i++) {
                    for (let j = 0; j < this.playableGames.length; j++) {
                        if (this.children[i] == this.playableGames[j].id) {
                            this.packageTemplate.children.push({
                            id: this.children[i],
                            quantity: this.playableGames[j].quantity
                    });
                        }
                    }
                }
                this.saveTemplate({ template: this.packageTemplate, logo: this.logo });
                this.reset();
                this.$emit("templateSaved");
            }
        },
        reset() {
            this.$refs.form.reset();
            
        },
        determineChipColour(item) {
            if (item.flyer) {
                return item.gameColours[0].attributeValue;
            } else {
                if (item.pages == 6) {
                    return "red";
                } else if (item.pages == 3) {
                    return "green";
                }        
                else {
                    return "purple";
                }
            }
        },
    },
    computed: {
        ...mapGetters(["getTemplateList"]),
        playableGames: {
            get: function () {
                let activeTemplates = this.getTemplateList.filter((template) =>
                    this.children.includes(template.id)
                );
                let playableGames = [].concat(activeTemplates);
                return playableGames;
            }
        },
        selectableTemplates: {
            get: function () {
                let activeTemplates = this.getTemplateList.filter((template) =>
                    !template.packaged
                );
                let selectableTemplates = [].concat(activeTemplates);
                return selectableTemplates;
            }
        }
    }

}


</script>

<style scoped>
.logo {
  max-height: 24px;
}
.square {
  width: 2vw;
  height: 2vw;
  min-width: 30px;
  min-height: 30px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 2px;
}
</style>