<template>
  <v-dialog transition="dialog-top-transition" width="90%" v-model="showDialog">
    <v-card>
      <v-toolbar color="#FFC425" dark class="text-h5">Check Winner</v-toolbar>
      <div class="pt-4 ma-2">
        <v-row>
          <v-col cols="2" style="overflow: auto" class="pr-0">
            <div id="list-demo  mt-4">
              <transition-group name="list" class="number-grid">
                <div
                  v-for="number in numbers"
                  :key="number"
                  class="list-item rounded-circle ml-2 mt-1 white--text"
                >
                  {{ number }}
                </div>
              </transition-group>
            </div>
          </v-col>
          <!-- TODO - Fix v-for to use objects not hard coded numbers -->
          <v-col :cols="10" class="pl-0">
            <v-row>
              <v-col class="text-h5 pt-0"> Current Number: {{ getCurrentNumber }} </v-col>
            </v-row>
            <v-row v-for="n in 3" :key="n" class="ml-0 mr-0 pa-0">
              <v-col
                v-for="m in 9"
                :key="m"
                class="text-center solid-border pa-0 white--text"
                :style="[
                  { background: backgroundColour(getGridItem(n, m)) },
                  { 'font-size': fontSize },
                ]"
              >
                {{ getGridItem(n, m) }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-text-field
              dense
              flat
              solo
              class="ml-2 text-h5"
              readonly
              value="Winning Panel"
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="pl-0">
            <v-text-field
              v-if="playerView"
              dense
              flat
              solo
              outlined
              class="text-h6"
              readonly
              :value="verifiedPanel"
            ></v-text-field>
            <v-text-field
              v-else
              dense
              flat
              solo
              outlined  
              label="Panel Number"
              v-model="panelId"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              v-if="!playerView"
              @click="checkPanel(panelId)"
              class="ml-2 mr-2 accent"
              >Check
            </v-btn>
          </v-col>
          <v-spacer />
        </v-row>
      </div>
      <v-card-actions class="justify-end">
        <v-btn text v-if="!playerView" :disabled=!enableVerifyButton @click="winnerVerified" :class="getGameWon ? 'primary' : 'error'"
          >{{ this.getGameWon ? "Verified" : "Unverifiable" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "VerificationDialog",
  data: () => ({
    numbers: [],
    panelId: null,
  }),
  props: {
    playerView: {
      type: Boolean,
      required: false,
      default: false,
    },
    verifiedPanel: {
      type: Number,
      required: false,
      default: null,
    },
    fontSize: {
      type: String,
      reqiured: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "getShowVerificationBoard",
      "getDrawnNumbers",
      "getCheckedPanel",
      "getCurrentNumber",
      "getActiveGame",
      "getGameWon"
    ]),
    showDialog: {
      get() {
        return this.getShowVerificationBoard;
      },
      set(show) {
        this.setShowVerificationBoard(show);
      },
    },
    enableVerifyButton(){
      return this.panelId && this.getGameWon
    },
    activePanel() {
      let rows = [];
      if (!this.getCheckedPanel.perm) {
        return [];
      }
      for (let i = 0; i < 3; i++) {
        let row = [];
        for (let j = 0; j < 18; j = j + 2) {
          let loopIndex = j + i * 18;
          row.push(
            this.getCheckedPanel.perm.substring(loopIndex, loopIndex + 2)
          );
        }
        rows.push(row);
      }
      return rows;
    },
  },
  methods: {
    ...mapActions([
      "setShowVerificationBoard",
      "fetchPanel",
      "clearCheckedPanel",
      "setWinnerVerified",
    ]),
    showNumbers() {
      for (let i = 0; i < this.getDrawnNumbers.length; i++) {
        this.numbers.unshift(this.getDrawnNumbers[i]);
      }
    },
    backgroundColour(number) {
      if (this.getCurrentNumber && this.getCurrentNumber == number) {
        return "#3DE29F";
      } else if (this.getDrawnNumbers.includes(number * 1)) {
        return "#EA2218";
      } else {
        return "#0067B1";
      }
    },
    checkPanel(panelId) {
      if (this.numbers.length == 0) {
        this.showNumbers();
      }
      this.fetchPanel(panelId);
    },
    getGridItem(n, m) {
      if (!this.activePanel.length > 0) {
        return null;
      } else if (this.activePanel[n - 1][m - 1] == "00") {
        return "";
      }
      return this.activePanel[n - 1][m - 1];
    },
    winnerVerified() {
      this.setWinnerVerified(this.panelId);
      this.showDialog = false;
    },
  },
  watch: {
    showDialog(show) {
      this.clearCheckedPanel();
      if (show == false) {
        this.panelId = null;
        this.numbers = [];
      } else {
        if (this.playerView && this.verifiedPanel) {
          const checkFunc = this.checkPanel;
          const panelId = this.verifiedPanel;
          setTimeout(function () {
            checkFunc(panelId);
          }, 300);
        }
      }
    },
  },
};
</script>

<style scoped>
.number-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.list-item {
  background-color: #EA2218;
  text-align: center;
  height: 30px;
  width: 30px;
  font-size: 20px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1.5s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-100px);
}
.list-move {
  transition: transform 0.2s;
}
.solid-border {
  border-style: solid;
  border-color: #FFC425;
  min-height: 50px;
}
</style>