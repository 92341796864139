import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js'

export default {

    save(tenant){
        return service.post(ENDPOINTS.TENANTS.SAVE, tenant);
    },
    fetchAll(){
        return service.get(ENDPOINTS.TENANTS.FETCH_ALL);
    },
    
}