<template>
  <v-container fluid>
    <v-row>
      <v-col align="center">
        <div class="advertMessage">{{ advertisingMessage }}</div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col align="center">
        <v-sheet elevation="1" height="100%" class="lastNumber">
           <v-container fluid>
      <v-row dense>
        <v-col
          v-for="card in getDrawnNumbers"
          :key="card"
          :cols="3"
        >
          <v-card v-if="getBallOrBonusBallNumber(card)">
            <div class="bigNumber">
            {{ card }}
          </div>
          </v-card>
          <v-card v-if="!getBallOrBonusBallNumber(card)">
            <div class="bigBonusNumber">
            {{ card }}
          </div>
          </v-card>
           </v-col>
      </v-row>
    </v-container>
  
          <div class="lastNumberLabel"><i>Numbers Called</i></div>
        </v-sheet>
      </v-col>
    </v-row>
    
    <v-overlay :value="getSettingUpGame">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { socketURL } from "@/api/clients/bingoManager-core/baseClient.js";
import Utils from "@/utils";

export default {
  name: "LottoPlayer",
  components: {
    
  },
  data() {
    return {
      currentGame: null,
      currentNumber: "0",
      connected: false,
      verifiedPanel: null,
      advertisingMessage: "",
      activePattern: 0,
      gameOrder: 1,
    };
  },
  methods: {
    ...mapActions([
      "startSchedule",
      "addCalledNumber",
      "fetchStatus",
      "setShowVerificationBoard",
      "updateNextGameForPlayer",
      "recalledNumber",
      "fetchStatus"
    ]),
    textColour() {
      return Utils.getContrast(this.getActiveGame.colour.attributeValue);
    },
    connect() {
      this.socket = new SockJS(socketURL + "play");
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect(
        {},
        () => {
          this.connected = true;
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/call", (tick) => {
            this.parseCallMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/recall", (tick) => {
            this.parseRecallMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/verified", (tick) => {
            this.parseVerifiedMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/nextPattern", (tick) => {
            this.parseNextPatternMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/nextGame", (tick) => {
            this.parseNextGameMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + localStorage.tenantId + "/advertUpdate", (tick) => {
            this.parseAdMessage(JSON.parse(tick.body));
          });
        },
        (error) => {
          console.log(error);
          this.connected = false;
        }
      );
    },
    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      this.connected = false;
    },
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    },
    parseCallMessage(message) {
      this.currentNumber = message.drawnNumber;
      this.addCalledNumber(this.currentNumber);
      this.setShowVerificationBoard(false);
    },
    getBallOrBonusBallNumber(number) {
      let index = this.getDrawnNumbers.indexOf(number);
      return index < this.getCurrentGameProductTemplate.drawSize;
    },
    parseRecallMessage(message) {
      this.currentNumber = message.drawnNumber;
      this.recalledNumber(this.currentNumber);
      this.setShowVerificationBoard(false);
    },
    parseVerifiedMessage(message) {
      this.verifiedPanel = message.panelId;
      this.setShowVerificationBoard(true);
    },
    parseNextPatternMessage(message) {
      this.setShowVerificationBoard(false);
      if (message.newPattern == "ONE_LINE") {
        this.activePattern=0;
      } else if (message.newPattern == "TWO_LINE") {
        this.activePattern=1;
      } else if (message.newPattern == "FULL_HOUSE") {
        this.activePattern=2;
      }
    },
    parseNextGameMessage(message) {
      this.currentNumber = "0";
      this.updateNextGameForPlayer(message);
      this.activePattern = 0;
      this.gameOrder++;
    },
    parseAdMessage(message) {
      this.advertisingMessage = message.adMessage;
    },

  },
  computed: {
    ...mapGetters([
      "getOrdinal",
      "getActiveGame",
      "getSettingUpGame",
      "getAdMessage",
      "getTenantId",
      "getScheduleList",
      "getDrawnNumbers",
      "getSelectedSchedule",
      "getCurrentGameProductTemplate"
    ]),
    selectedSchedule() {
      return this.getScheduleList.find(
        (schedule) => schedule.id == this.$route.params.scheduleId
      );
    },
    adMessage() {
      return this.advertisingMessage
        ? this.advertisingMessage
        : this.getAdMessage;
    },
  },
  mounted() {
    let scheduleId = this.$route.params.scheduleId;
    this.fetchStatus(scheduleId);
    this.connect();
  },
};
</script>

<style scoped>
.lastNumber {
  background-color: #ffc425;
  height: 100%;
}
.bigNumber {
  -webkit-text-stroke: 6px black;
  font-size: 100px;
  color: #ea2218;
  font-weight: 900;
  line-height: normal;
}
.bigBonusNumber {
  -webkit-text-stroke: 6px black;
  font-size: 100px;
  color: #2d18ea;
  font-weight: 900;
  line-height: normal;
}
.lastNumberLabel {
  margin: 5px;
  background-color: white;
  font-weight: 800;
  font-size: 35px;
}
.gameDetails {
  border-color: #ffc425;
  border-width: 2px;
  border-style: solid;
}
.logo {
  height: 80%;
}
.gameDetail {
  background-color: #ffc425;
  font-weight: 600;
  font-size: 25px;
}
.gameDetailValue {
  background-color: white;
  border-style: solid;
  border-width: 1px;
  width: 30%;
  display: inline-block;
}
.advertMessage {
  width: 100%;
  font-weight: 600;
  font-size: 35px;
  border-style: solid;
  border-width: 2px;
  padding: 3px;
  min-height: 60px;
  text-align: center;
}
.numbersCalled {
  display: inline-block;
  width: 50%;
  font-size: 20px;
}
.ordinalNumber {
  display: inline-block;
  width: 50%;
  background-color: #ffc425;
  font-size: 35px;
  text-align: center;
}
.imageFile {
  margin-top: 2px;
  object-fit: contain;
  height: inherit;
  max-height: 10vw;
}
</style>