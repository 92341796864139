var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getTemplateList,"sort-by":"calories","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-toolbar-title',[_vm._v("Templates")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","id":"templateSearchBtn","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"card"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit Template")])]),_c('v-card-text',[_c('template-add')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to archive this template?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1)]},proxy:true},{key:"item.packaged",fn:function(ref){
var item = ref.item;
return [(item.packaged)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.flyer",fn:function(ref){
var item = ref.item;
return [(item.flyer)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.allowDoubles",fn:function(ref){
var item = ref.item;
return [(item.allowDoubles)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [(item.logo)?_c('img',{staticClass:"logo",attrs:{"contain":"","src":'data:image/jpeg;base64,' + item.logo}}):_vm._e()]}},{key:"item.gameColours",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fluid-container"},_vm._l((item.gameColours),function(colour){return _c('div',{key:colour.id,staticClass:"square mr-1",style:({ background: colour.attributeValue })})}),0),_c('div',{staticClass:"fluid-container"},_vm._l((item.children),function(child){return _c('v-chip',{key:child.id,staticClass:"ml-2",attrs:{"text-color":"white","color":"success","small":""}},[_vm._v(_vm._s(child.title)+" ("+_vm._s(child.quantity)+")")])}),1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }