const getNotifiicationsDefaultState = () => {
    return {
        snackbarQueue: [],
        dialogQueue: null
    }
}

const state = getNotifiicationsDefaultState();

const getters = {
    getSnackbarQueue: state => state.snackbarQueue,
    getDialogQueue: state => state.dialogQueue
};

const actions = {
    displaySnackbar({ commit }, snackbarToDisplay) {
        let updatedQueue = [];
        if (state.snackbarQueue) {
            updatedQueue = updatedQueue.concat(state.snackbarQueue);
        }
        updatedQueue.push(snackbarToDisplay);
        commit('updateSnackbarQueue', updatedQueue);
    },
    removeFromSnackbarQueue({ commit }, snackbarToRemove) {
        let updatedQueue = [].concat(state.snackbarQueue);
        updatedQueue.splice(updatedQueue.indexOf(snackbarToRemove), 1);
        commit('updateSnackbarQueue', updatedQueue);
    },
    displayDialog({ commit }, dialogToDisplay) {
        commit('updateDialogQueue', dialogToDisplay);
    },
    clearDialogQueue({ commit }) {
        commit('updateDialogQueue', null);
    }
}

const mutations = {
    updateSnackbarQueue: (state, value) => {
        state.snackbarQueue = value;
    },
    updateDialogQueue: (state, value) => {
      state.dialogQueue = value;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
