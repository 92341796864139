<template>
  <v-data-table
    :headers="headers"
    :items="getBooks"
    :search="search"
    class="elevation-3"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Book Sales</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn small icon class="pt-1" @click="updateBookBreakdown()">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
          <vue-json-to-csv
              :json-data   = getBooks>
              <button small icon class="pt-1">
                <v-icon>mdi-arrow-collapse-down</v-icon>
              </button>
          </vue-json-to-csv>
          <v-spacer></v-spacer>
            <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="updateTableOnDateChange(dates)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-col cols="12" sm="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field
          ></v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:[`item.date`]="{ item }">
      {{ new Date(item.date).toDateString() }}
    </template>
    <template v-slot:[`item.price`]="{ item }">
      {{
        (
          item.price
        ).toLocaleString("en-IE", {
          style: "currency",
          currency: "EUR",
        })
      }}
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ReportingBooksTable",
  computed: {
    ...mapGetters(["getBooks"]),
    dateRangeText () {
        return this.dates.join(' ~ ')
      },
  },
  data: () => ({
    search: "",
    dates:[(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)).toISOString().substr(0, 10),
    (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)).toISOString().substr(0, 10)],
    menu: false,
    headers: [
      { text: "Date", value: "date"},
      {
        text: "Book Title",
        value: "title",
      },
      { text: "Flyer", value: "flyer" },
      { text: "Double", value: "double_book" },
      { text: "Number of Pages", value: "number_of_pages", align: "center" },
      { text: "€", value: "price", align: "center" },
      { text: "Player", value: "display_name" },
      { text: "Email", value: "email" },
    ],
  }),

  created() {
    this.fetchBookBreakdown(this.dates);
  },
  methods: {
    ...mapActions(["fetchBookBreakdown"]),
    updateBookBreakdown() {
      this.fetchBookBreakdown(this.dates);
    },
    selectTemplate(template) {
      this.setSelectedTemplate(template);
      this.$emit("templateSelected");
    },
    updateTableOnDateChange(dates) {
      this.updateBookBreakdown();
      this.$refs.menu.save(dates)
    }
  },
};
</script>

<style scoped>
.logo {
  max-height: 25px;
}

.square {
  width: 1.25vw;
  height: 1.25vw;
  min-width: 10px;
  min-height: 10px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 0.5px;
}

.fluid-container {
  display: flex;
}
</style>