import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js';

export default {
    fetchAll(){
        return service.get(ENDPOINTS.CUSTOMERS.FETCH_ALL);
    },
    register(customer){
        return service.post(ENDPOINTS.CUSTOMERS.REGISTER, customer);
    },
    update(customer){
        return service.post(ENDPOINTS.CUSTOMERS.UPDATE, customer);
    },
    delete(customer){
        return service.post(ENDPOINTS.CUSTOMERS.DELETE, customer);
    },
    resetPassword(customer){
        return service.post(ENDPOINTS.CUSTOMERS.RESET_PASSWORD, customer);
    }
}