<template>
  <v-carousel v-model="model" light hide-delimiters :show-arrows="false" height="auto">
    <v-carousel-item>
      <printed-books-table @bookSelected="showRegisterBook"/>
    </v-carousel-item>
    <v-carousel-item> 
        <register-book @returnToBooks="showBooks" />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import PrintedBooksTable from './PrintedBooksTable.vue';
import RegisterBook from './RegisterBook.vue';

export default {
  components: {  PrintedBooksTable, RegisterBook },
  name: "RegisterBooksHome",
  data() {
    return {
      model: 0,
    };
  },
  methods: {
      showRegisterBook(){
          this.model = 1;
      },
      showBooks(){
          this.model = 0;
      }
  }
};
</script>