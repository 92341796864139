<template>
  <v-card>
    <v-form ref="form" v-model="valid" lazy-validation class="ml-12 mr-12">
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field
            label="First Name"
            required
            id="firstNameTxt"
            v-model="customer.firstName"
            :rules="firstNameRules"
          ></v-text-field>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field
            label="Last Name"
            required
            id="lastNameTxt"
            :rules="lastNameRules"
            v-model="customer.lastName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field
            label="Display Name"
            required
            id="displayNameTxt"
            :rules="displayNameRules"
            v-model="customer.displayName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field label="Phone" required v-model="customer.phone" id="phoneTxt" :rules="phoneRules"></v-text-field>
        </v-col>
        <v-col cols="1" />
        <v-col cols="3">
          <v-text-field label="Email" required v-model="customer.email" :disabled="noEmail" id="emailTxt" :rules="emailRules"  v-validate="'required|email'"></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-checkbox label="No Email" v-model="noEmail" id="noEmailChk" @click="emailClick"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1" />
        <v-col cols="4">
          <v-text-field label="Bar Code ID" required v-model="customer.barCodeId" id="barCodeId" ></v-text-field>
        </v-col>
        <v-col cols="1" />
        <v-col cols="4">  
        </v-col>
      </v-row>
      <v-row class="mt-16 mb-2 ml-2 mr-2">
        <v-col class="text-right pt-3">
          <v-btn class="mr-4" id="clearBtn" @click="clear"> Clear </v-btn>
          <v-btn color="primary" class="mr-4" @click="save" id="saveBtn"> Save </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CustomerAdd",
  data: () => ({
    customer: {
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      displayName: null,
      barCodeId: null,
    },
    role: null,
    valid: false,
    noEmail: false,
    emailRules: [ 
      v => !!v || 'An email is required',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Invalid Email address' 
      ],
    firstNameRules: [
      v => !!v || 'First Name is required',
      v => /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(v) || 'Invalid First Name',
    ],
    lastNameRules: [
      v => !!v || 'Last Name is required',
      v => /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(v) || 'Invalid Last Name',
    ],
    displayNameRules: [
      v => !!v || 'Display Name is required',
      v => /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(v) || 'Invalid Display Name',
    ],
    phoneRules: [
      v => !!v || 'A phone numer is required',
      v => /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(v) || 'Invalid Phone Number',
    ],
  }),
  methods: {
    ...mapActions(["registerCustomer"]),
    save(){
      if (this.$refs.form.validate()) {
          this.registerCustomer(this.customer).then(response => {
            this.$emit('customerSaved', response);
            this.clear();
          });
      }
    },
    clear() {
      this.customer.firstName = null;
      this.customer.lastName = null;
      this.customer.email = null;
      this.customer.phone = null;
      this.customer.displayName = null;
      this.customer.barCodeId = null;
      this.noEmail = false;
    },
    emailClick() {
      if(this.noEmail){
        this.customer.email = new Date().getTime() + "@noemail.com"; 
      }
      else {
        this.customer.email = null;
      }
    }
  },
};
</script>