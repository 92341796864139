<template>
  <v-card class="pa-2">
    <v-tabs  class="tabs" centered>
      <v-tab>
        <v-icon left> mdi-cash-multiple </v-icon>
        <div v-if="$vuetify.breakpoint.smAndUp">Product Sales</div>
      </v-tab>
      <v-tab>
        <v-icon left> mdi-calendar-month-outline </v-icon>
        <div v-if="$vuetify.breakpoint.smAndUp">Schedule Sales</div>
      </v-tab>
      <v-tab>
        <v-icon left> mdi-chart-box-outline </v-icon>
        <div v-if="$vuetify.breakpoint.smAndUp">Game Winners</div>
      </v-tab>
      <v-tab>
        <v-icon left> mdi-chart-box-outline </v-icon>
        <div v-if="$vuetify.breakpoint.smAndUp">Book Sales</div>
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <reporting-product-home />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <reporting-schedules-home />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <reporting-winners-home />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <reporting-books-home />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card> 
</template>

<script>
import ReportingSchedulesHome from '@/components/reporting/ReportingSchedulesHome.vue';
import ReportingBooksHome from '@/components/reporting/ReportingBooksHome.vue'; 
import ReportingProductHome from '@/components/reporting/ReportingProductHome.vue';
import ReportingWinnersHome from '../../components/reporting/ReportingWinnersHome.vue';

export default {
  name: "Reporting",
  components: {
    ReportingSchedulesHome,
    ReportingBooksHome,
    ReportingProductHome,
    ReportingWinnersHome
  },
};
</script>

<style
    ReportingProductsHome="scss" scoped>
div.tabs [role="tab"] {
  justify-content: flex-start;
}
</style>