import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js';

export default {
    callNumber(scheduleId) {
        return service.get(ENDPOINTS.CALLER.CALL_NUMBER, {
            params: {
                scheduleId: scheduleId
            }
        });
    },
    recallNumber(recallNumber, scheduleId) {
        return service.get(ENDPOINTS.CALLER.RECALL_NUMBER, {
            params: {
                recallNumber: recallNumber,
                scheduleId: scheduleId
            }
        });
    },
    nextPattern(nextPattern, scheduleId) {
        return service.get(ENDPOINTS.CALLER.NEXT_PATTERN, {
            params: {
                nextPattern: nextPattern,
                scheduleId: scheduleId
            }
        });
    },
    fetchPanel(panelId) {
        return service.get(ENDPOINTS.PANEL.FETCH_BY_ID, {
            params: {
                id: panelId
            }
        });
    },
    verifyWinner(winner) {
        return service.post(ENDPOINTS.CALLER.VERIFY_WINNER, winner);
    },
    markMissedWinner(missedWinner) {
        return service.post(ENDPOINTS.CALLER.MISSED_WINNER, missedWinner);
    }
}