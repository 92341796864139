import Vue from 'vue';
import Vuex from 'vuex';
import templateDesigner from '@/store/modules/templateDesigner.js';
import scheduler from '@/store/modules/scheduler.js';
import products from '@/store/modules/products.js';
import notifications from '@/store/modules/notifications.js';
import caller from '@/store/modules/caller.js';
import users from '@/store/modules/users.js';
import auth from '@/store/modules/auth.js';
import systemAdmin from '@/store/modules/systemAdmin.js';
import customer from '@/store/modules/customer.js';
import printBooks from '@/store/modules/printBooks.js';
import reporting from '@/store/modules/reporting.js';
import books from '@/store/modules/books.js';
import tenants from '@/store/modules/tenants.js';
import navigation from './modules/navigation';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    templateDesigner,
    scheduler,
    products,
    notifications,
    caller,
    users,
    tenants,
    auth,
    systemAdmin,
    customer,
    printBooks,
    reporting,
    books,
    navigation
  }
})
