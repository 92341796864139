import Tenants from '@/api/clients/bingoManager-core/tenants.js';
import CONST from '@/const/index.js'

const getTenantsDefaultState = () => {
    return {
        tenants: []
    }
}
const state = getTenantsDefaultState();

const getters = {
    getTenantList: state => state.tenants
}

const actions = {
    async saveTenant({ commit, dispatch }, tenant) {
        try {
            await Tenants.save(tenant);
            commit('addNewTenant', tenant);
            this._vm.$snackbar({
                message: ("Tenant Saved"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async updateTenant({ commit, dispatch }, tenant) {
        try {
            await Tenants.save(tenant);
            commit('updateTenant', tenant);
            this._vm.$snackbar({
                message: ("Tenant Updated"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async disableTenant({ commit, dispatch }, tenant) {
        try {
            //await Tenants.disable(tenant);
            commit('disableTenant', tenant);
            this._vm.$snackbar({
                message: ("Tenant Disabled"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async fetchTenants({ commit, dispatch }) {
        try {
            const result = await Tenants.fetchAll();
            commit('addTenants', result.data)
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    }
}

const mutations = {
    addNewTenant(state, tenant) {
        state.tenants.push(tenant);
    },
    updateTenant(state, tenant) {
        var foundIndex = state.tenants.findIndex(x => x.id == tenant.id);
        Object.assign(state.tenants[foundIndex], tenant);
    },
    disableTenant(state, tenant) {
        var foundIndex = state.tenants.findIndex(x => x.id == tenant.id);
        state.tenants.splice(foundIndex,1)
    },
    addTenants(state, templates) {
        state.tenants = templates
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}