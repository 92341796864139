import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js'

export default {

    save(template, logo){
        const formData = new FormData();
        console.log(template);
        formData.append('template', JSON.stringify(template));
        formData.append('logo', logo);
        return service.post(ENDPOINTS.TEMPLATE_DESIGNER.SAVE, formData);
    },
    fetchAll(){
        return service.get(ENDPOINTS.TEMPLATE_DESIGNER.FETCH_ALL);
    },
    archive(templateId) {
        const formData = new FormData();
        formData.append('templateId', templateId);
        return service.post(ENDPOINTS.TEMPLATE_DESIGNER.ARCHIVE, formData);
    },
    
}