<template>
  <v-container fluid>
    <v-row justify="center" class="mt-4">
      <v-col cols="2" align="center">
        <v-img
          height="80"
          contain
          :src="'data:image/jpeg;base64,' + getActiveGame.logo"
        />
        <v-row class="mt-0">
          <v-spacer />
          <v-col>
              <v-btn class="primary" rounded block @click="openPlayerView"
              >Launch Player Board</v-btn
            >
          </v-col>
          <v-spacer />
        </v-row>
      </v-col>
      <v-col cols="7" class="text-body-1" justify="center">
         <v-row>
          <v-col>
            <div>Currently playing Lottery </div>
          </v-col>
        </v-row>
    
      <v-row>
        <v-col>
        </v-col>
        <v-col>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          
        </v-col>
        <v-col>
            </v-col>
        <v-col>

            </v-col>
            <v-col>

            </v-col>
      </v-row>
        
      </v-col>
      
    </v-row>
    <v-row justify="center">
      <v-spacer />
      <v-col cols="2" align="center">
        <v-sheet rounded elevation="2" class="mb-2">
          <div class="pt-2 text-h6">Live Players</div>
          <div class="pt-2 pb-1 infoBoxes">{{ livePlayers }}</div>
        </v-sheet>
      </v-col>
      <v-col align="center" cols="5">
        <v-sheet rounded elevation="2" class="mb-2">
          <div class="pt-2 text-h6">Numbers Called</div>
          <div class="pt-2 pb-1 infoBoxes">
            {{ formattedDrawnNumbers }}
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="2" align="center">
        <v-sheet rounded elevation="2" class="mb-2 noselect">
          <div class="pt-3 text-h6">Ordinal</div>
          <div class="pt-5 pb-5 text-h4 primary--text infoBoxes">{{ getOrdinal }}</div>
        </v-sheet>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-spacer />
      <v-col cols="7" align="center">
        <v-text-field outlined solo label="Ad Message" v-model="adMessage" class="mt-3"/>
      </v-col>
      <v-col  cols="2" align="center">
        <v-sheet rounded elevation="2" class="mb-2 noselect">
          <div class="pt-3 text-h6">Last Number</div>
          <div class="pt-5 pb-5 text-h3 primary--text">
            {{ getCurrentNumber }}
          </div>
        </v-sheet>
        <v-btn
            :class="buttonColour"
            class="pa-15 text-h6"
            :loading="getCallingNumber"
            :disabled="disableCallButton"
            fab
            x-large
            @click="callClick"
            >Call</v-btn>
      </v-col>
      <v-spacer />
    </v-row>
    <v-overlay :value="getSettingUpGame">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { socketURL } from '@/api/clients/bingoManager-core/baseClient.js';
export default {
  name: "Caller",
  components: {
    
  },
  data() {
    return {
      adMessage: "",
      patternSelected: "",
      gameSelected: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentNumber",
      "getOrdinal",
      "getDrawnNumbersString",
      "getDrawnNumbers",
      "getActivePages",
      "getDistanceFromWinning",
      "getGameWon",
      "getWinners",
      "getVerifiedWinners",
      "getActiveGame",
      "getCallingNumber",
      "getCheckingWinner",
      "getSettingUpGame",
      "getWinnerVerified",
      "getCurrentPattern",
      "getMissedWinners",
      "getTenantId",
      "getSelectedSchedule",
      "getScheduleList",
      "getCurrentGameProductTemplate"
    ]),
    selectedSchedule() {
      return this.getScheduleList.find(
        (schedule) => schedule.id == this.getActiveGame.scheduleId
      );
    },
    formattedDrawnNumbers() {

      let formattedDrawnNumbersString = "";
      let index = 0;
      for (const number of this.getDrawnNumbers) {
        if (index > 0) {
          formattedDrawnNumbersString += ", "
        }
        if (index < this.getCurrentGameProductTemplate.drawSize) {
          formattedDrawnNumbersString += number
        }
        else {
          formattedDrawnNumbersString += "Bonus Ball: " + number;
        }
        index++;
      }
      return formattedDrawnNumbersString;
    },
    livePlayers() {
      let numberOfPages = this.getActiveGame.numberOfActivePlayers ? this.getActiveGame.numberOfActivePlayers : this.getActivePages;
      if(numberOfPages == 0){
        return '';
      }
      return numberOfPages + " Active Pages";
    },
    disableCallButton() {
      return (this.getOrdinal == this.getCurrentGameProductTemplate.drawSize + this.getCurrentGameProductTemplate.bonusBallSize);
    },
    buttonColour: function () {
      let drawSize = 0;
      if (this.getCurrentGameProductTemplate != null) {
        drawSize = this.getCurrentGameProductTemplate.drawSize;
      }
      else if (this.getActiveGame.productTemplateDto != null) {
         drawSize = this.getActiveGame.productTemplateDto.drawSize;
      }
      if (this.getOrdinal < drawSize) {
        return { primary: true };
      } else {
        return { warning: true };
      }
    },
  },
  methods: {
    ...mapActions([
      "callNumber",
      "nextPattern",
      "setShowVerificationBoard",
      "setCallingNumber",
      "nextGame",
      "setCurrentPattern",
      "setCheckingWinner",
      "setWinnerMissed",
      "recallNumber"
    ]),
    callClick() {
      this.call();
    },
    call() {
      this.setCallingNumber(true);
      this.callNumber(this.getActiveGame.scheduleId);
    },
    openPlayerView() {
      let route = this.$router.resolve({ name: "View Lotto Game", params: { scheduleId: this.getActiveGame.scheduleId } });
      window.open(route.href, "_blank");
    },
    connectToStomp() {
      this.socket = new SockJS(socketURL + "play");
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect({}, () => {
        this.connected = true;
      });
    },
    updateAdMessage(message) {
      if (this.stompClient && this.stompClient.connected) {
        const msg = { adMessage: message, tenantId: localStorage.tenantId };
        console.log(JSON.stringify(msg));
        this.stompClient.send("/app/advert", JSON.stringify(msg), {});
      }
    },
  },
  mounted() {
    this.connectToStomp();
  },
  watch: {
    adMessage(message) {
      this.updateAdMessage(message);
    },
  },
};
</script>

<style scoped>
.borders {
  border-style: solid;
  border: #0067B1;
}

.infoBoxes {
  min-height: 120px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>