import Books from '@/api/clients/bingoManager-core/books.js';
import CONST from '@/const/index.js'

const getBooksDefaultState = () => {
    return {
        selectedSchedule: null,
        selectedGame: [],
        selectedBook: null,
        books: [],
        selectedBooks: []
    }
}
const state = getBooksDefaultState();

const getters = {
    getSelectedBookSchedule: state => state.selectedSchedule,
    getBooksForSchedule: state => state.books,
    getSelectedBook: state => state.selectedBook
}

const actions = {
    async fetchBooksForSchedule({ commit }, scheduleId) {
        try {
            let response = await Books.fetchBooksForScheduleId(scheduleId);
            commit('setBooks', response.data);
        } catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async fetchBookById({ commit }, bookId){
        try {
            let response = await Books.fetchBookById(bookId);
            commit('setSelectedBook', response.data)
        } catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async fetchBooksById({ commit }, bookIds){
        try {
            let response = await Books.fetchBooksById(bookIds);
            commit('setSelectedBooks', response.data)
        } catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async reprintBook({ dispatch }, bookId){
        try {
            Books.reprintBook(bookId);
            this._vm.$snackbar({
                message: ("Books printed"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    setSelectedSchedule({ commit }, schedule){
        commit('updateSelectedSchedule', schedule)
    },
    setSelectedBook({ commit }, book){
        commit('setSelectedBook', book)
    }
}

const mutations = {
    updateSelectedSchedule(state, schedule) {
        state.selectedSchedule = schedule
    },
    setBooks(state, books) {
        state.books = books;
    },
    setSelectedBook(state, book) {
        state.selectedBook = book;
    },
    setSelectedBooks(state, books) {
        state.selectedBooks = books;
    },
    resetBooks (state) {
        // acquire initial state
        const s = getBooksDefaultState()
        Object.keys(s).forEach(key => {
          state[key] = s[key]
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}