<template>

 <v-container fluid>
      <v-row dense>
        <v-col
          v-for="card in getScheduleList"
          :key="card.title"
          :cols="3"
        >
          <v-card class="card" dark>

            <v-list-item three-line>
              <v-list-item-content>
                  <div class="text-overline mb-4">
                    {{formatCardHeadline(card)}}
                  </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{card.title}}
                </v-list-item-title>
                <v-list-item-subtitle>{{formatCardText(card)}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to cancel this schedule?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="cancelConfirm"
                  >Confirm</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>


            <v-card-actions>

              

              <v-btn plain @click="cancel(card)">
                Cancel
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="edit(card)">
                Edit
              </v-btn>

              <v-btn icon @click="sell(card)">
                Sell
              </v-btn>

              <v-btn icon @click="call(card)">
                Call
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

</template>

<script>

import {  mapActions, mapGetters } from "vuex";
export default {
  name: "Schedules",
  mounted(){
    this.setCurrentPage("Schedules")
  },
  components: {

  },
  computed: {
    ...mapGetters(["getScheduleList"]),
  },
  methods: {
    ...mapActions(["fetchUpcoming", "fetchStatus", "setCurrentPage", "cancelSchedule"]),
    formatCardText(card) {
      let text = "";
      for (const template of card.selectedTemplates) {
        if (text != "") {
          text = text + ", ";
        }
        text = text + template;
      }

      return text
    },
    formatCardHeadline(card) {
      return card.date
    },
    getImageSrc(card) {
      return "data:image/jpeg;base64," + card.gameOrder[0].logo;
    },
    edit(card) {
      console.log(card)
      let path = "products" + "/" + this.getSchedule(card).type + "/" + card.id;
      this.$router.push({ path: path });
    },
    cancel(card) {
      console.log(card)
      //let path = "products" + "/" + this.getSchedule(card).type + "/" + card.id;
      this.selectedItem = card;
      this.dialog = true;
    },
    cancelConfirm() {
      this.cancelSchedule(this.selectedItem.id);
      this.dialog = false;
    },
    getSchedule(card) {
      return this.getScheduleList.find(
        (schedule) => schedule.id == card.id
      );
    },
    sell(card) {
      console.log(card)
      let path = "sales" + "/" + card.id;
      this.$router.push({ path: path });
    },
    call(card) {
      this.fetchStatus(card.id);
      this.$router.push({ path: `/start/${card.id}` });
    },
  },
  created() {
    this.fetchUpcoming();
  },
  data: () => ({
      cards: [
        { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 3 },
        { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 3 },
        { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 3 },
      ],
       dialog: false,
       selectedItem: null,
    }),
};
</script>