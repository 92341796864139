import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js'

export default {

    save(user){
        return service.post(ENDPOINTS.USERS.SAVE, user);
    },
    update(user){
        return service.post(ENDPOINTS.USERS.UPDATE, user);
    },
    delete(user){
        return service.post(ENDPOINTS.USERS.DELETE, user);
    },
    fetchAll(){
        return service.get(ENDPOINTS.USERS.FETCH_ALL);
    }
    
}