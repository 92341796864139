<template>
  <div class="text-center">
    <v-dialog :value="value" width="500">
      <v-card>
        <v-card-title class="headline primary white--text">
          {{ value.title }}
        </v-card-title>

        <v-card-text class="mt-2">
          {{ value.message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dismiss(dismiss.action)"
          >
            {{ value.dismiss ? value.dismiss.text : "Cancel" }}
          </v-btn>
          <v-btn
            :class="`${value.mode}`"
            @click="performCallback(value.action)"
          >
            {{ value.action.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "BmDialog",
  props: {
    value: Object,
  },
  methods: {
    ...mapActions(["clearDialogQueue"]),
    performCallback(action) {
      if (action && action.callback) {
        action.callback();
      }
      this.dismiss();
    },
    dismiss(preDismissAction) {
      if (preDismissAction && preDismissAction.callback) {
        preDismissAction.callback();
      }
      this.clearDialogQueue();
    },
  },
};
</script>