<template>
  <div class="base">
    <!--<img class="base" src="../../assets/usa2.jpg"/> -->
    <table class="numberBoard" :style="{ 'font-size': fontSize }">
      <tr>
        <td v-for="n in 15" :key="n" :id="n" :style="[{ background: backgroundColour(n)}]">{{ n }}</td>
      </tr>
      <tr>
        <td v-for="n in 15" :key="n+15" :id="n+15" :style="[{ background: backgroundColour(n+15)}]">{{ n+15 }}</td>
      </tr>
      <tr>
        <td v-for="n in 15" :key="n+30" :id="n+30" :style="[{ background: backgroundColour(n+30)}]">{{ n+30 }}</td>
      </tr>
      <tr>
        <td v-for="n in 15" :key="n+45" :id="n+45" :style="[{ background: backgroundColour(n+45)}]">{{ n+45 }}</td>
      </tr>
       <tr>
        <td v-for="n in 15" :key="n+60" :id="n+60" :style="[{ background: backgroundColour(n+60)}]">{{ n+60 }}</td>
      </tr>
      <tr>
        <td v-for="n in 15" :key="n+75" :id="n+75" :style="[{ background: backgroundColour(n+75)}]">{{ n+75 }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NinetyNumberBoard",
  props: {
      fontSize: {
          default: "55x"
      }
  },
  methods: {
      
  },
  computed: {
      ...mapGetters(["getDrawnNumbers", "getCurrentNumber"]),
      backgroundColour(){
          return number => this.getCurrentNumber == number ? '#3DE29F' : this.getDrawnNumbers.indexOf(number) > -1 ? '#EA2218' : null
      } 
  }
};
</script>

<style scoped>
.base {
  width: 100%;
  height: 100%;
  background-color: #FFC425;
  padding: 1%;
}

.numberBoard {
    width: 100%;
    background-color: #0067B1;
    color: white;
    border-collapse: collapse;
    text-align: center;
    font-weight: bold;
}

td {
  border: 6px solid black;
}

</style>