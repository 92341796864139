<template>
  <v-container>
    <v-card class="mt-5">
      <div class="text-right text-h5 font-weight-medium pa-3">
        Create Template
      </div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="ml-12 mr-12 mt-8"
      >
        <v-row>
          <v-col cols="1" />
          <v-col cols="4">
            <v-text-field
              v-model="template.title"
              :counter="30"
              :rules="nameRules"
              id="titleTxt"
              label="Title"
            ></v-text-field>

            <v-text-field
              v-model="template.perm"
              v-if="selectedGameType == 'BINGO' || selectedGameType == 'FIXED_ODDS_BINGO'"
              :counter="10"
              label="Perm"
              readonly
              disabled
              placeholder="RockBingo"
            ></v-text-field>

            <v-file-input
              v-if="selectedGameType == 'BINGO' || selectedGameType == 'LOTTO' || selectedGameType == 'FIXED_ODDS_BINGO'"
              label="Logo"
              v-model="logo"
              :rules="imageRules"
              id="logoFileInput"
              required
              accept="image/png, image/jpeg, image/bmp"
              show-size
              clearable
            ></v-file-input>

            <v-text-field
             v-if="selectedGameType == 'LOTTO'"
              required
              label="Pool Size"
              :rules="poolSizeRules"
              v-model="template.poolSize"
              type="number"
               max="100"
            ></v-text-field>

            <v-text-field
             v-if="selectedGameType == 'LOTTO'"
              required
              v-model="template.maxNumberLines"
              :rules="maxLineRules"
              label="Max Number of Lines"
              type="number"
               max="10"
            ></v-text-field>

          </v-col>
          <v-col cols="1" />
          <v-col cols="4">
            <v-select
              label="Type of Game"
              :items="gameTypes"
              required
              v-model="selectedGameType"
              @change="updatePagesForFlyer(selectedGameType == 'FIXED_ODDS_BINGO')"
            ></v-select>

            <v-text-field
              v-if="selectedGameType == 'LOTTO'"
              required
              v-model="template.drawSize"
              :rules="drawSizeRules"
              label="Draw Size"
              type="number"
              max="6"
            ></v-text-field>

            <v-text-field
              v-if="selectedGameType == 'LOTTO'"
              required
              v-model="template.bonusBallSize"
              label="Bonus Ball Size"
              type="number"
              min="0"
              max="2"
            ></v-text-field>

            <v-text-field
              v-model="template.pages"
              v-if="selectedGameType == 'BINGO'"
              :rules="pageRules"
              type="number"
              min="1"
              max="12"
              oninput="validity.valid||(value='');"
              label="Number of Pages"
              id="numberOfPagesTxt"
              :readonly="template.flyer"
              :disabled="template.flyer"
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-model="template.allowDoubles"
                  v-if="selectedGameType == 'BINGO'"
                  label="Double Book"
                  required
                  id="doubleBookChk"
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  v-model="template.flyer"
                  v-if="selectedGameType == 'BINGO'"
                  label="Flyer"
                  required
                  id="flyerChk"
                  @change="updatePagesForFlyer(template.flyer)"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-10" v-if="selectedGameType == 'BINGO' || selectedGameType == 'FIXED_ODDS_BINGO'  || selectedGameType == 'LOGO'">
          <v-col cols="1" />
          <v-col cols="9">
            <v-data-table
              :headers="headers"
              :items="colourChoices"
              item-key="id"
              :show-select="false"
              :disable-pagination="true"
              :hide-default-footer="true"
              class="page__table"
            >
              <template v-slot:body="props">
                <draggable :list="props.items" tag="tbody" @end="endDrag">
                  <tr v-for="(item, index) in props.items" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <v-menu
                        v-model="colourPicker"
                        :close-on-content-click="true"
                        :nudge-width="200"
                        offset-x
                        :id="index"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-on="on"
                            class="square"
                            v-bind="attrs"
                            @click="showColours(index)"
                            :style="{ background: item.attributeValue }"
                        /></template>
                        <v-list>
                          <v-list-item
                            v-for="(colour, i) in getSystemColours"
                            :key="i"
                            @click="colourChange(colour, index)"
                          >
                            <template v-slot:default>
                              <v-list-item-action>
                                <div
                                  class="square"
                                  :style="{
                                    background: colour.attributeValue,
                                  }"
                                />
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title>{{
                                  colour.attributeName
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                    <td>{{ item.attributeName }}</td>
                    <td><v-icon>mdi-reorder-horizontal</v-icon></td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row class="mt-16">
          <v-col class="text-right pt-3">
            <v-btn class="mr-4" @click="reset"> Clear </v-btn>
            <v-btn color="primary" class="mr-4" @click="submit"> Save </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TemplateDesigner",
  components: {
    Draggable,
  },
  created() {
    this.fetchColours();
  },
  computed: {
    ...mapGetters(["getSystemColours"]),
    colourChoices() {
      return this.template.flyer
        ? this.getSystemColours.slice(0, 1)
        : this.getSystemColours.slice(0, this.template.pages);
    },
  },

  data: () => ({
    valid: true,
    template: {
      title: "",
      perm: "RockBingo",
      pages: "0",
      panels: "6",
      type: null,
      maxNumberLines: null,
      drawSize: null,
      poolSize: null,
      bonusBallSize: null,
      allowDoubles: false,
      flyer: false,
      gameColours: [],
    },
    gameTypes: ['BINGO', 'LOTTO', "FIXED_ODDS_BINGO"],
    selectedGameType: null,
    colourPicker: false,
    updateIndex: null,
    logo: null,
    drawSizeRules: [
      (v) => !!v || "Draw Size is required",
      (v) => (v && v < 8) || "Draw Size must be less than 8",
    ],
    maxLineRules: [
      (v) => !!v || "Max Number of Lines is required"
    ],
    poolSizeRules: [
      (v) => !!v || "Pool Size is required",
      (v) => (v && v < 100) || "Pool Size must be less than 100",
    ],
    nameRules: [
      (v) => !!v || "Title is required",
      (v) => (v && v.length <= 30) || "Title must be less than 30 characters",
      v => /^[\w\- ]+$/i.test(v) || 'Invalid Title',
    ],
    pageRules: [
      (v) => !!v || "Number of pages is required, or else choose flyer",
    ],
    imageRules: [
      v => !!v || 'Logo is required',
      value => !value || value.size < 75000 || 'Logo size should be less than 75 kb'
    ],
    headers: [
      { text: "Order", sortable: false, width: "10%" },
      { text: "Colour", value: "hex", sortable: false, width: "10%" },
      { text: "Name", value: "name", sortable: false, width: "70%" },
      { text: "Reorder", sortable: false, width: "10%" },
    ],
  }),

  methods: {
    ...mapActions(["saveTemplate", "fetchColours"]),
    submit() {
      if(this.$refs.form.validate()){
        this.template.gameColours = this.colourChoices;
        this.template.type = this.selectedGameType;
        this.saveTemplate({ template: this.template, logo: this.logo });
        this.reset();
        this.$emit("templateSaved");
      }
    },
    colourChange(colour) {
      const currentColourPosition = this.getSystemColours.indexOf(colour);
      const selectedColour = this.getSystemColours.splice(currentColourPosition, 1)[0];
      const swappedColour = this.getSystemColours.splice(this.updateIndex, 1)[0];
      this.getSystemColours.splice(this.updateIndex, 0, selectedColour);
      this.getSystemColours.splice(currentColourPosition, 0, swappedColour);
    },
    showColours(index){
      this.updateIndex = index;
    },
    reset() {  
      this.$refs.form.reset();
      this.template.perm = "RockBingo",
      this.template.panels = "6",
      this.template.gameColours= [],
      this.template.allowDoubles = false,
      this.template.flyer = false
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    updatePagesForFlyer(isFlyer) {
      if (isFlyer) {
        this.template.pages = "1";
      }
    },
    endDrag(event) {
      const movedItem = this.getSystemColours.splice(event.oldIndex, 1)[0];
      this.getSystemColours.splice(event.newIndex, 0, movedItem);
    },
  },
};
</script>

<style scoped>
.square {
  width: 2vw;
  height: 2vw;
  min-width: 30px;
  min-height: 30px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 2px;
}
</style>


