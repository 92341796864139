var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-2 mb-5 card"},[_c('div',{staticClass:"text-right text-h5 font-weight-medium pt-3 pr-3"},[_vm._v(" Register Books ")]),_c('span',[_c('v-btn',{staticClass:"accent ml-6",attrs:{"small":"","rounded":""},on:{"click":_vm.returnToBooks}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-left")]),_vm._v("Books")],1)],1),_c('v-autocomplete',{staticClass:"ma-5",attrs:{"clearable":"","persistent-hint":true,"solo":"","hide-details":"","label":'Select a schedule',"items":_vm.getScheduleList,"item-value":function (obj) { return obj; },"item-text":function (obj) { return obj.title + ' - ' + obj.date + ' , ' + obj.sessionType; }},model:{value:(_vm.selectedSchedule),callback:function ($$v) {_vm.selectedSchedule=$$v},expression:"selectedSchedule"}}),(_vm.selectedSchedule)?_c('div',[_c('v-card',{staticClass:"pa-2"},[_c('v-row',[_c('v-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(!_vm.addCustomer),expression:"!addCustomer"}],staticClass:"ml-6 mr-6 mt-6 mb-2",attrs:{"hide-details":"","return-object":false,"items":_vm.getCustomerList,"clearable":"","persistent-hint":true,"solo":"","item-text":function (obj) { return obj.displayName +
              ', ' +
              obj.firstName +
              ' ' +
              obj.lastName +
              ', ' +
              obj.email +
              ', ' +
              obj.barCodeId; },"item-value":function (obj) { return obj; },"label":'Find a customer'},model:{value:(_vm.selectedCustomer),callback:function ($$v) {_vm.selectedCustomer=$$v},expression:"selectedCustomer"}})],1),_c('v-row',{staticClass:"mb-2",attrs:{"align":"end"}},[_c('v-spacer'),(_vm.addCustomer)?_c('v-col',{attrs:{"align":"end","cols":"2","justify":"end"}},[_c('v-btn',{staticClass:"mt-5",on:{"click":function($event){_vm.addCustomer = false}}},[_vm._v("Cancel")])],1):_vm._e(),(!_vm.selectedCustomer && !_vm.addCustomer)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"caption pa-0",attrs:{"readonly":"","solo":"","flat":"","dense":"","hide-details":"true","value":"Cannot find customer?"}}),_c('v-btn',{staticClass:"accent",on:{"click":_vm.showAddCustomer}},[_vm._v(" Add New Customer ")])],1):_vm._e()],1),(_vm.addCustomer)?_c('v-row',[_c('v-col',[_c('customer-add',{on:{"customerSaved":_vm.setCustomer}})],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"text-right pt-3"},[(_vm.showRegisterButton)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":_vm.register}},[_vm._v(" Register ("+_vm._s(_vm.getSelectedBooks.length)+")")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }