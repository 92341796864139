<template>
  <div class="home">
    <v-container>
      <v-col>
        <v-row align="center" justify="center">
          <v-card elevation="8" class="mt-10 card" dark>
            <v-img
              alt="Bingo Manager Logo"
              class="shrink mt-2"
              contain
              height="140"
              src="@/assets/BM_Logo_Full_Size.png"
              transition="scale-transition"
            />
            <v-form
              @submit.prevent="login(userDetails)"
              v-show="!showForgotPassword && !getRequiresPasswordReset"
            >
              <div>
                <v-col>
                  <v-row justify="center">
                    <v-text-field
                      label="Username"
                      outlined
                      class="ml-3 mr-3"
                      id="usernameBtn"
                      v-model="userDetails.userName"
                    ></v-text-field>
                  </v-row>
                  <v-row justify="center">
                    <v-text-field
                      type="password"
                      label="Password"
                      outlined
                      class="ml-3 mr-3"
                      id="passwordBtn"
                      v-model="userDetails.password"
                    ></v-text-field>
                  </v-row>
                  <v-row justify="center" class="mb-3">
                    <v-btn
                      class="primary main_button mr-3"
                      @submit.prevent="login(userDetails)"
                      type="submit"
                      id="loginBtn"
                      >Login
                    </v-btn>
                  </v-row>
                  <v-row justify="center" class="mb-3">
                    <v-btn
                      class="mr-3 accent black--text"
                      plain
                      small
                      @click="showForgotPassword = true"
                      id="forgotPasswordBtn"
                      >Forgot Password
                    </v-btn>
                  </v-row>
                </v-col>
              </div>
            </v-form>
            <v-form
              @submit.prevent="resetPassword()"
              v-show="!showForgotPassword && getRequiresPasswordReset"
            >
              <div>
                <v-card-title class="justify-center"
                  >Reset Password</v-card-title
                >
                <v-col>
                  <v-row justify="center">
                    <v-text-field
                      type="password"
                      label="New Password"
                      outlined
                      class="ml-3 mr-3"
                      rounded
                      v-model="newPassword"
                    ></v-text-field>
                  </v-row>
                  <v-row justify="center">
                    <v-text-field
                      type="password"
                      label="Re-enter Password"
                      outlined
                      class="ml-3 mr-3"
                      rounded
                      v-model="passwordRetype"
                    ></v-text-field>
                  </v-row>
                  <v-row justify="center" class="mb-3">
                    <v-btn
                      class="primary mr-3"
                      rounded
                      @submit.prevent="resetPassword()"
                      type="submit"
                      >Save Password
                    </v-btn>
                  </v-row>
                </v-col>
              </div>
            </v-form>
            <v-form
              @submit.prevent="forgotPassword(userDetails.userName)"
              v-show="showForgotPassword"
            >
              <div>
                <v-card-title class="justify-center"
                  >Forgot Password</v-card-title
                >
                <v-col>
                  <v-row justify="center">
                    <v-text-field
                      label="Username"
                      outlined
                      class="ml-3 mr-3"
                      rounded
                      v-model="userDetails.userName"
                    ></v-text-field>
                  </v-row>
                  <v-row justify="center" class="mb-3">
                    <v-btn
                      class="primary mr-3"
                      rounded
                      type="submit"
                      @submit.prevent="forgotPassword(userDetails.userName)"
                      >Reset Password
                    </v-btn>
                  </v-row>
                  <v-row justify="center" class="mb-3">
                    <v-btn
                      class="mr-3"
                      plain
                      small
                      @click="showForgotPassword = false"
                      >Back
                    </v-btn>
                  </v-row>
                </v-col>
              </div>
            </v-form>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CONST from '@/const';

export default {
  name: "Login",
  data: () => ({
    userDetails: {
      userName: null,
      password: null,
    },
    showForgotPassword: false,
    newPassword: null,
    passwordRetype: null,
  }),
  computed: {
    ...mapGetters(["getToken", "getRequiresPasswordReset"]),
  },
  methods: {
    ...mapActions(["login", "forgotPassword", "changePassword"]),
    resetPassword() {
        //TODO - Replace with proper form validation
      if (this.newPassword == this.passwordRetype) {
          this.changePassword({ userId: JSON.parse(this.getToken).id, newPassword: this.newPassword })
      } else {
        this.$snackbar({
          message: "Passwords do not match",
          mode: CONST.THEME.COLOURS.ERROR,
        });
      }
    },
  },
  watch: {
    getToken() {
        if(!this.getRequiresPasswordReset){
            this.$router.push("/");
        }
    },
  },
};
</script>