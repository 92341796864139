<template>
  <v-card class="pa-2">
    <v-tabs v-model="selectedTab" centered class="pl-4 pr-4">
      <v-tab>
        <v-icon id="templatesBtn">mdi-text-box-multiple </v-icon>
        Templates
      </v-tab>
      <v-tab>
        <v-icon id="addTemplateBtn">mdi-text-box-plus</v-icon>
        Add Template
      </v-tab>
      <v-tab>
        <v-icon id="addPackageTemplateBtn">mdi-text-box-plus</v-icon>
        Add Package Template
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <template-list />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <template-add @templateSaved="selectedTab = 0"/>
      </v-tab-item>
      <v-tab-item>
        <package-add/>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import TemplateList from "@/components/templates/TemplateList.vue";
import TemplateAdd from "@/components/templates/TemplateAdd.vue";
import PackageAdd from "@/components/templates/PackageAdd.vue";
export default {
  name: "Templates",
  components: {
    TemplateList,
    TemplateAdd,
    PackageAdd,
  },
   data: () => ({
     selectedTab: 0
   })
};
</script>