<template>
  <v-data-table
    :headers="headers"
    :items="getDownloadableBooks"
    sort-desc="dateRequested"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Books</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn small icon class="pt-1" @click="fetchDownloadabledBooks()">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-col cols="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field
          ></v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:[`item.flyer`]="{ item }">
      <v-icon v-if="item.flyer">mdi-check</v-icon>
    </template>
    <template v-slot:[`item.allowDoubles`]="{ item }">
      <v-icon v-if="item.allowDoubles">mdi-check</v-icon>
    </template>
    <template v-slot:[`item.logo`]="{ item }">
      <img contain class="logo" :src="'data:image/jpeg;base64,' + item.logo" />
    </template>
    <template v-slot:[`item.gameColours`]="{ item }">
      <div class="fluid-container">
        <div
          v-for="colour in item.gameColours"
          :key="colour.id"
          :style="{ background: colour.attributeValue }"
          class="square mr-1"
        />
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
        <v-btn v-if="item.status == 'REQUEST_FINISHED'" class="success" small rounded :href="DOWNLOAD_URL + item.id" download>Download</v-btn>
        <v-btn v-if="item.status == 'REQUEST_PENDING'" class="warning" :disabled=true small rounded >Pending</v-btn>
        <v-btn v-if="item.status == 'REQUEST_FAILED'" class="error" :disabled=true small rounded >Failed</v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
export default {
  name: "DownloadBooksTable",
  computed: {
    ...mapGetters(["getDownloadableBooks"]),
    DOWNLOAD_URL: () => process.env.VUE_APP_BASE_URL + ENDPOINTS.BOOKS.DOWNLOAD_BOOKS
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "Title",
        align: "start",
        value: "productTemplateTitle",
        width: "25%",
      },
      { text: "Date Requested", value: "dateRequested" },
      { text: "Requested By", value: "requestedBy" },
      {
        text: "Count",
        align: "start",
        value: "bookCount",
      },
      { text: "Actions", value: "actions", sortable: false },
    ]
  }),

  created() {
    this.fetchDownloadabledBooks();
  },
  methods: {
    ...mapActions(["fetchDownloadabledBooks", "setSelectedTemplate"]),
  },
};
</script>

<style scoped>
.logo {
  max-height: 25px;
}

.square {
  width: 1.25vw;
  height: 1.25vw;
  min-width: 10px;
  min-height: 10px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 0.5px;
}

.fluid-container {
  display: flex;
}
</style>