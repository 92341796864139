<template>
  <v-data-table
    :headers="headers"
    :items="getPreprintedBooks"
    sort-by="dateCreated"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Printed Books</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn small icon class="pt-1" @click="fetchPreprintedBooks()">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
          <v-checkbox
              small icon class="pt-1"
              v-model="toggle_on"
              label="Scan QR code"
              @change="check($event)"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-col cols="2" class="pt-0 pb-0">
            <v-btn
              class="primary"
              @click="registerBooks"
              :disabled="getSelectedBooks.length == 0"
              >Register ({{ getSelectedBooks.length }})</v-btn
            >
          </v-col>
          <v-col cols="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field
          ></v-col>
        </v-row>
      </v-toolbar>
      <v-row v-if="toggle_on" align="center">
      <v-col v-if="toggle_on" cols="10" class="pt-0 pb-0 d-flex justify-center align-center" align="center" justify="center" >
            <StreamBarcodeReader v-if="toggle_on" class="pt-0 pb-0" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
          </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.allowDoubles`]="{ item }">
      <!-- <v-icon v-if="item.allowDoubles">mdi-check</v-icon> -->
      <span v-if="item.allowDoubles">Double</span>
      <span v-else>Single</span>
    </template>
    <template v-slot:[`item.gameColours`]="{ item }">
      <div class="fluid-container">
        <div
          v-for="colour in item.gameColours"
          :key="colour.id"
          :style="{ background: colour.attributeValue }"
          class="square mr-1"
        />
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        :class="getSelectedBooks.indexOf(item.id) > -1 ? 'error' : 'success'"
        fab
        x-small
        dark
        @click="toggleBook(item.id)"
        class="elevation-2"
      >
        <v-icon>{{
          getSelectedBooks.indexOf(item.id) > -1 ? "mdi-minus" : "mdi-plus"
        }}</v-icon>
      </v-btn>
      <!-- <v-btn class="info" small rounded @click="registerBook(item.id)"
        >Register</v-btn
      > -->
    </template>
  </v-data-table>
  
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { StreamBarcodeReader } from "vue-barcode-reader";
import {EventBus} from "@/main";

export default {
  components: { StreamBarcodeReader },
  name: "PrintedBooksTable",
  computed: {
    ...mapGetters(["getPreprintedBooks", "getSelectedBooks"]),
  },
  data: () => ({
    search: "",
    toggle_on: false,
    counter: 3000000,
    headers: [
      {
        text: "Book ID",
        align: "start",
        value: "id",
        width: "20%",
      },
      {
        text: "Title",
        align: "start",
        value: "title",
      },
      {
        text: "Pages",
        align: "start",
        value: "pages",
      },
      //{ text: "Book Type", value: "allowDoubles" },
      { text: "Colours", value: "gameColours", width: "15%" },
      {text: "Date Created", value: "dateCreated", align: ' d-none'},
      { text: "", value: "actions", sortable: false },
      
    ],
  }),

  created() {
    this.fetchPreprintedBooks();
    EventBus.$on("BARCODE_SCANNED", data => {
      this.onDecode(data);
    });
  },

  methods: {
    ...mapActions(["fetchPreprintedBooks", "setSelectedBooks", "addSelectedBook", "removeSelectedBook", "addPreprintedBook"]),
    registerBooks() {
      this.$emit("bookSelected");
    },
    bookSelected(book) {
     this.addSelectedBook(book);
    },
    bookRemoved(book) {
      this.removeSelectedBook(book);
    },
    check(event) {
      console.log(event)
      //this.codeReader.reset();
    },
    onDecode(result) {
      //this.removeSelectedBook(result);
      var matchedRows = []
      matchedRows = this.getPreprintedBooks.filter((book) => result == book.id);
      if (matchedRows.length == 0) {
        this.addPreprintedBook({id : result, title: "Scanned Book", dateCreated: parseInt('0000' + this.counter--,8)});
      }
     // else if (!this.getSelectedBooks.includes(result)) {
      //  else {
      //   this.addSelectedBook(result);
      //   this.addPreprintedBook({id : result, title: "Scanned Book", dateCreated: parseInt('0000' + this.counter--,8)});
      // }
      this.toggleBook(result);
    },
    onLoaded(result) {
      console.log(result);
    },
    onError(result) {
      console.log(result);
    },
    toggleBook(book){
      console.log("start selected books:" + this.getSelectedBooks);
      this.getSelectedBooks.indexOf(book) > -1
        ? this.bookRemoved(book)
        : this.bookSelected(book);
        console.log("end selected books:" + this.getSelectedBooks);
        document.activeElement.blur();
    }
  },
};

</script>

<style scoped>
.logo {
  max-height: 25px;
}

.square {
  width: 1.25vw;
  height: 1.25vw;
  min-width: 10px;
  min-height: 10px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 0.5px;
}

.fluid-container {
  display: flex;
}
</style>