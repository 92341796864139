<template>
  <v-card class="pa-2">
    <v-tabs vertical class="tabs">
      <v-tab>
        <v-icon left> mdi-barcode-scan </v-icon>
        Books In Play
      </v-tab>
      <v-tab>
        <v-icon left> mdi-book-open-variant </v-icon>
        Reprint Books
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <books-home />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <books-reprint />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import BooksHome from "@/components/books/BooksTable.vue";
import BooksReprint from "@/components/books/BooksReprint.vue";

export default {
  name: "Books",
  components: {
    BooksHome,
    BooksReprint
  },
};
</script>

<style
    RegisterBooksHomeang="scss" scoped>
div.tabs [role="tab"] {
  justify-content: flex-start;
}
</style> 