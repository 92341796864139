<template>
  <v-container>
    <v-card class="mt-5 mb-2">
      <div class="text-right text-h5 font-weight-medium pt-3 pr-3">
        Reprint Book
      </div>
      <v-row>
        <v-col cols="3" class="mb-8 mt-2 ml-2">
          <v-form ref="form" lazy-validation class="ml-12 mr-12">
          <v-text-field
            v-model="bookId"
            label="Book ID"
            id="bookIdText"
            outlined
            single-line
            name="bookIdText"
            :rules="bookIdRules"
          ></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="1">
          <v-btn class="primary mt-4" @click="search">Search</v-btn>
        </v-col>
      </v-row>
      <v-form ref="form_books" lazy-validation class="ml-12 mr-12" v-if="getSelectedBook">
        <v-row>
          <v-col cols="1" />
          <v-col cols="4">
            <v-img
              height="80"
              contain
              class="mb-5"
              :src="'data:image/jpeg;base64,' + getSelectedBook.productTemplateDto.logo"
            />
            <v-text-field
              value="90 Number Game"
              readonly
              class="read_only_cursor"
              label="Game Type"
              id="gameTypeTxt"
              required
            ></v-text-field>

            <v-text-field
              v-model="getSelectedBook.productTemplateDto.perm"
              label="Perm"
              class="read_only_cursor"
              readonly
              id="permTxt"
              placeholder="RockBingo"
            ></v-text-field>
          </v-col>
          <v-col cols="1" />
          <v-col cols="4">
            <v-text-field
              v-model="getSelectedBook.productTemplateDto.title"
              label="Title"
              class="read_only_cursor"
              id="titleTxt"
              readonly
            ></v-text-field>

            <v-text-field
              v-model="getSelectedBook.productTemplateDto.pages"
              type="number"
              min="1"
              max="12"
              class="read_only_cursor"
              oninput="validity.valid||(value='');"
              label="Number of Pages"
              id="numberOfPagesTxt"
              readonly
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-model="getSelectedBook.productTemplateDto.allowDoubles"
                  label="Double Book"
                  class="read_only_cursor"
                  required
                  readonly
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  v-model="getSelectedBook.productTemplateDto.flyer"
                  label="Flyer"
                  class="read_only_cursor"
                  required
                  readonly
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col cols="1" />
          <v-col cols="5">
            <v-data-table
              :headers="headers"
              :items="getSelectedBook.productTemplateDto.gameColours"
              item-key="id"
              :show-select="false"
              :disable-pagination="true"
              :hide-default-footer="true"
              class="page__table"
            >
              <template v-slot:[`item.attributeValue`]="{ item }">
                <div
                  :style="{ background: item.attributeValue }"
                  class="square mr-1 inline-block"
                />
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="2">
            <v-text-field
              readonly
              flat
              solo
              id="customerNameTxt"
              value="Customer name:"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              flat
              solo
              id="customerPlayerNameTxt"
              :value="getSelectedBook.player.displayName"
            />
          </v-col>
        </v-row>

        <v-row class="mt-16">
          <v-col class="text-right pt-3">
            <v-btn
              color="primary"
              class="mr-4"
              @click="reprintBook(getSelectedBook.id)"
            >
              Reprint
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BooksReprint",
  computed: {
    ...mapGetters(["getSelectedBook"]),
  },
  data: () => ({
    headers: [
      {
        text: "Colour",
        value: "attributeValue",
        sortable: false,
        width: "20%",
      },
      { text: "Name", value: "attributeName", sortable: false, width: "80%" },
    ],
    singleBooks: 0,
    doubleBooks: 0,
    bookId: '',
    bookIdRules: [v => !!v || 'An ID is required']
  }),
  mounted(){
      this.setSelectedBook(null)
  },
  methods: {
    ...mapActions(["reprintBook", "fetchBookById", "setSelectedBook"]),
    returnToTemplates() {
      this.$emit("returnToTemplates");
    },
    search(){
        if (this.$refs.form.validate()) {
          this.fetchBookById(this.bookId)
        }
        
    },
    resetValues() {
      this.singleBooks = 0;
      this.doubleBooks = 0;
    },
  },
};
</script>

<style scoped>
.square {
  width: 2vw;
  height: 2vw;
  min-width: 30px;
  min-height: 30px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 2px;
}

.read_only_cursor input {
  cursor: pointer;
}
</style>