import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js';

export default {
    login(user) {
        return service.post(ENDPOINTS.AUTH.LOGIN, user)
    },
    forgotPassword(userName) {
        return service.get(ENDPOINTS.AUTH.FORGOT_PASSWORD, {
            params: {
                userName: userName
            }
        });
    },
    changePassword(details) {
        return service.post(ENDPOINTS.AUTH.CHANGE_PASSWORD, details)
    },
    resetPassword(details) {
        return service.post(ENDPOINTS.AUTH.RESET_PASSWORD, details)
    }
}