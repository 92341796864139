import Books from '@/api/clients/bingoManager-core/books.js';
import CONST from '@/const/index.js'

const getPrintBooksDefaultState = () => {
    return {
        selectedTemplate: null,
        selectedBooks: [],
        preprintedBooks: [],
        printingBooks: false,
        downloadableBooks: []
    }
}
const state = getPrintBooksDefaultState();

const getters = {
    getSelectedTemplate: state => state.selectedTemplate,
    getSelectedBooks: state => state.selectedBooks,
    getPreprintedBooks: state => state.preprintedBooks,
    getPrintingBooks: state => state.printingBooks,
    getDownloadableBooks: state => state.downloadableBooks
}

const actions = {
    async printBooks({ commit, dispatch }, bookQuantities) {
        try {
            commit('updatePrintingBooks', true);
            await Books.printBooks(bookQuantities, state.selectedTemplate);
            commit('updatePrintingBooks', false);
            this._vm.$snackbar({
                message: ("Book(s) Requested. Check your email for further notifications."),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        }catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async fetchPreprintedBooks({ commit }) {
        try {
            let response = await Books.fetchPreprintedBooks();
            commit('setPreprintedBooks', response.data);
        }catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async fetchDownloadabledBooks({ commit }) {
        try {
            let response = await Books.fetchDownloadableBooks();
            commit('setDownloadableBooks', response.data);
        }catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async registerBooks({ commit, dispatch }, registration){
        try {
            let response = await Books.registerBooks(registration);
            dispatch('setSelectedBooks', []);
            commit('removePreprintedBooks', response.data);
            this._vm.$snackbar({
                message: ("Book Registered"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    setSelectedTemplate({ commit }, template){
        commit('updateSelectedTemplate', template)
    },
    setSelectedBooks({ commit }, books){
        commit('updateSelectedBooks', books)
    },
    addSelectedBook({ commit }, book){
        commit('addSelectedBook', book)
    },
    removeSelectedBook({ commit }, book){
        commit('removeSelectedBook', book)
    },
    addPreprintedBook({ commit }, book){
        commit('addPreprintedBook', book)
    }
}

const mutations = {
    updateSelectedTemplate(state, template) {
        state.selectedTemplate = template
    },
    updateSelectedBooks(state, books) {
        state.selectedBooks = books
    },
    addSelectedBook(state, book) {
        state.selectedBooks.push(book);
    },
    removeSelectedBook(state, book) {
        state.selectedBooks.splice(state.selectedBooks.indexOf(book), 1);
    },
    setPreprintedBooks(state, books) {
        state.preprintedBooks = books;
    },
    setDownloadableBooks(state, books) {
        state.downloadableBooks = books;
    },
    removePreprintedBooks(state, bookIds) {
        state.preprintedBooks = state.preprintedBooks.filter((book) => bookIds.indexOf(book.id) == -1);
    },
    updatePrintingBooks(state, printing) {
        state.printingBooks = printing;
    },
    addPreprintedBook(state, book) {
        state.preprintedBooks.push(book);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}