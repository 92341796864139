import Vue from 'vue';
import VueCurrencyInput from 'vue-currency-input';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
Vue.use(VueCurrencyInput);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#323887',
                secondary: '#9ac4f8',
                accent: '#FCBF49',
            },
            dark : {
                primary: '#323887',
                secondary: '#000000',
                accent: '#FCBF49'
            }
        }
    }
});
