var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showScheduleDetails),expression:"showScheduleDetails"}]},[_c('v-card',{staticClass:"mx-2"},[_c('v-card-text',{staticClass:"mb-4"},[_vm._l((_vm.getProductList),function(product){return _c('div',{key:product.id},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":product.winningPatterns,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"primary white--text",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-toolbar-title',{staticClass:"ml-2"},[_vm._v(_vm._s(product.productTemplateDto.title))])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","retain-focus-on-click":false},on:{"click":function($event){return _vm.copyText(product.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Product Id: "+_vm._s(product.id)+" (Click to copy)")])]):_vm._e(),_c('v-col',{staticClass:"pr-0"},[_c('v-text-field',{attrs:{"background-color":"primary","solo":"","dense":"","flat":"","hide-details":"","readonly":"","reverse":"","dark":"","value":"Price"}})],1),_c('v-col',{staticClass:"pl-0 mt-1"},[_c('currency-field',{attrs:{"solo":"","dense":"","hide-details":"","dark":""},model:{value:(product.price),callback:function ($$v) {_vm.$set(product, "price", $$v)},expression:"product.price"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.addLineItem(product)}}},[_vm._v(" New Line Item ")])],1)],1)],1)]},proxy:true},{key:"item.intOne",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number"},model:{value:(item.intOne),callback:function ($$v) {_vm.$set(item, "intOne", $$v)},expression:"item.intOne"}})]}},{key:"item.intTwo",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number"},model:{value:(item.intTwo),callback:function ($$v) {_vm.$set(item, "intTwo", $$v)},expression:"item.intTwo"}})]}},{key:"item.lineItem",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.lineItem == null? _vm.lineCounter++ : item.lineItem))])]}},{key:"item.winningPattern",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"label":"Winning Pattern","items":['ONE_LINE', 'TWO_LINE', 'FULL_HOUSE', 'JACKPOT']},model:{value:(item.winningPattern),callback:function ($$v) {_vm.$set(item, "winningPattern", $$v)},expression:"item.winningPattern"}})]}}],null,true)})],1)}),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"text-right pt-3"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.saveProductDetails(_vm.getProductList)}}},[_vm._v(" Save ")])],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }