import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js'

export default {

    save(schedule){
        return service.post(ENDPOINTS.SCHEDULER.SAVE, schedule);
    },
    fetchAll(){
        return service.get(ENDPOINTS.SCHEDULER.FETCH_ALL);
    },
    fetchAllDates(){
        return service.get(ENDPOINTS.SCHEDULER.FETCH_ALL_DATES)
    },
    fetchAllDateForDate(date){
        return service.get(ENDPOINTS.SCHEDULER.FETCH_ALL_FOR_DATE, {
            params: {
              date: date
            }
          })
    },
    fetchUpcoming(){
        return service.get(ENDPOINTS.SCHEDULER.FETCH_UPCOMING);
    },
    startSchedule(scheduleId) {
        const formData = new FormData();
        formData.append('scheduleId', scheduleId);
        return service.post(ENDPOINTS.SCHEDULER.START_SCHEDULE, formData);
    },
    cancelSchedule(scheduleId) {
        const formData = new FormData();
        formData.append('scheduleId', scheduleId);
        return service.post(ENDPOINTS.SCHEDULER.CANCEL_SCHEDULE, formData);
    },
    endSchedule(scheduleId) {
        const formData = new FormData();
        formData.append('scheduleId', scheduleId);
        return service.post(ENDPOINTS.SCHEDULER.END_SCHEDULE, formData);
    },
    startNextGame(scheduleId, gameOrder, nextGame) {
        return service.get(ENDPOINTS.SCHEDULER.NEXT_GAME, {
            params: {
                scheduleId: scheduleId,
                game: gameOrder,
                nextGame: nextGame
            }
        });
    },
    fetchStatus(scheduleId) {
        return service.get(ENDPOINTS.SCHEDULER.FETCH_STATUS, {params: {scheduleId: scheduleId}});
    },
    setBroadcast(scheduleId, isBroadcast){
        return service.post(ENDPOINTS.SCHEDULER.SET_BROADCAST, { scheduleId: scheduleId, isBroadcast: isBroadcast});
    }
}