<template>
  <div class="ladbrokes_card">
    <v-row style="height: 60vh;">
      <v-col cols="3" align="center" class="white--text patterns">
        <v-row class="mt-6">
          <v-col cols="12">
            <div class="text-h4 pattern">One Line</div>
            <div v-for="item in getWinningPatternOneLine" :key="item.id">
                <div>€{{ item.intTwo }}</div>
                <prize-graph :item="item" :ordinal="getOrdinal"></prize-graph>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-h4 pattern">Two Lines</div>
            <div v-for="item in getWinningPatternTwoLine" :key="item.id">
              <div>€{{ item.intTwo }}</div>
                <prize-graph :item="item" :ordinal="getOrdinal"></prize-graph>
              </div>         
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-h4 pattern">Full House</div>
            <div v-for="item in getWinningPatternFullHouse" :key="item.id">
              <div>€{{ item.intTwo }}</div>
                <prize-graph :item="item" :ordinal="getOrdinal"></prize-graph>
              </div>     
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <fixed-odd-bingo-flashboard
          :fontSize="fontSize"
          :playerView="true"
          :verifiedPanel="verifiedPanel"
        ></fixed-odd-bingo-flashboard>
      </v-col>
    </v-row>
    <v-row style="height: 42vh;" class="mt-0">
      <v-col cols="4" class="py-0 mt-5 ml-3">
        <div style="height: 30vh; border: 8px solid #FFC425; backdrop-filter: blur(10px); border-radius: 8px;">
            <div style="width: 100%; height: 100%; border: 4px solid black;" class="pa-1 white--text">
              <div align="center" class="text-h4 pattern">Jackpot</div>
              <!-- <div v-for="item in getWinningPatternJackpot" :key="item.id">
              <div>€{{ item.intTwo }}</div>
                <prize-graph :item="item" :ordinal="getOrdinal"></prize-graph>
              </div>  -->
              <div class="image_parent">
                <img src="../../assets/money_ball.png" style="max-height: 80%; position: absolute;"/>
                <div class="image-container"></div>
              </div>
            </div>
          </div>
      </v-col>
      <v-spacer />
      <v-col cols="3" align="center" class="py-0 mt-5" >
        <div class="d-flex fill-height">
          <div style="height: 30vh; width: 30vw; border: 8px solid #FFC425; backdrop-filter: blur(10px); border-radius: 8px;">
            <div style="width: 100%; height: 100%; border: 4px solid black;" class="pa-1 white--text">
              <img v-if="currentNumber" :src="getImgUrl(`balls/bingo_ball-${currentNumber}.png`)" style="height: 75%;"/>
              <div align="center" class="text-h4">Last Number</div>
            </div>
          </div>
        </div>
      </v-col>
      <v-spacer />
      <v-col cols="4" class="py-0 mt-5">
        <div class="d-flex fill-height">
          <div style="height: 30vh; width: 30vw; border: 8px solid #FFC425; backdrop-filter: blur(10px); border-radius: 8px;">
            <div style="width: 100%; height: 100%; border: 4px solid black;" class="pa-1 white--text">
              <div align="center" class="text-h4">Numbers Called</div>
              <div align="center" class="text-h1 mt-4">{{ getOrdinal }}</div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="10" class="white--text">
        <div class="advertMessage">{{ advertisingMessage }}</div>
      </v-col>
    </v-row>
    <v-overlay :value="getSettingUpGame">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { socketURL } from "@/api/clients/bingoManager-core/baseClient.js";
import Utils from "@/utils";
import FixedOddBingoFlashboard from '../../components/game/FixedOddBingoFlashboard.vue';
import PrizeGraph from '../../components/game/PrizeGraph.vue';

export default {
  name: "Player",
  components: {
    FixedOddBingoFlashboard,
    PrizeGraph,
  },
  data() {
    return {
      currentGame: null,
      currentNumber: "",
      connected: false,
      verifiedPanel: null,
      advertisingMessage: "",
      activePattern: 0,
      gameOrder: 1,
    };
  },
  methods: {
    ...mapActions([
      "startSchedule",
      "addCalledNumber",
      "fetchStatus",
      "setShowVerificationBoard",
      "updateNextGameForPlayer",
      "recalledNumber",
      "getSelectedSchedule"
    ]),
    textColour() {
      return Utils.getContrast(this.getActiveGame.colour.attributeValue);
    },
    connect() {
      this.socket = new SockJS(socketURL + "play");
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect(
        {},
        () => {
          this.connected = true;
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/call", (tick) => {
            this.parseCallMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/recall", (tick) => {
            this.parseRecallMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/verified", (tick) => {
            this.parseVerifiedMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/nextPattern", (tick) => {
            this.parseNextPatternMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/nextGame", (tick) => {
            this.parseNextGameMessage(JSON.parse(tick.body));
          });
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/advertUpdate", (tick) => {
            this.parseAdMessage(JSON.parse(tick.body));
          });
        },
        (error) => {
          console.log(error);
          this.connected = false;
        }
      );
    },
    getImgUrl(pic) {
      return require('../../assets/'+pic)
    },
    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      this.connected = false;
    },
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    },
    parseCallMessage(message) {
      this.currentNumber = message.drawnNumber;
      this.addCalledNumber(this.currentNumber);
      this.setShowVerificationBoard(false);
    },
    parseRecallMessage(message) {
      this.currentNumber = message.drawnNumber;
      this.recalledNumber(this.currentNumber);
      this.setShowVerificationBoard(false);
    },
    parseVerifiedMessage(message) {
      this.verifiedPanel = message.panelId;
      this.setShowVerificationBoard(true);
    },
    parseNextPatternMessage(message) {
      this.setShowVerificationBoard(false);
      if (message.newPattern == "ONE_LINE") {
        this.activePattern=0;
      } else if (message.newPattern == "TWO_LINE") {
        this.activePattern=1;
      } else if (message.newPattern == "FULL_HOUSE") {
        this.activePattern=2;
      }
    },
    parseNextGameMessage(message) {
      this.currentNumber = "0";
      this.updateNextGameForPlayer(message);
      this.activePattern = 0;
      this.gameOrder++;
    },
    parseAdMessage(message) {
      this.advertisingMessage = message.adMessage;
    },

  },
  computed: {
    ...mapGetters([
      "getOrdinal",
      "getActiveGame",
      "getSettingUpGame",
      "getAdMessage",
      "getTenantId",
      "getScheduleList"
    ]),
    getWinningPatternOneLine() {
      if (this.getActiveGame != null && this.getActiveGame.winningPatternsList != null) {
          return this.getActiveGame.winningPatternsList.filter(
        (winningPattern) => winningPattern.winningPattern == "ONE_LINE"
      ).sort((a, b) => a.intOne - b.intOne);
      }
      else {
        return [];
      }
    },
    getWinningPatternTwoLine() {
      if (this.getActiveGame != null && this.getActiveGame.winningPatternsList != null) {
          return this.getActiveGame.winningPatternsList.filter(
        (winningPattern) => winningPattern.winningPattern == "TWO_LINE"
      ).sort((a, b) => a.intOne - b.intOne);
      }
      else {
        return [];
      }
    },
    getWinningPatternFullHouse() {
      if (this.getActiveGame != null && this.getActiveGame.winningPatternsList != null) {
          return this.getActiveGame.winningPatternsList.filter(
        (winningPattern) => winningPattern.winningPattern == "FULL_HOUSE"
      ).sort((a, b) => a.intOne - b.intOne);
      }
      else {
        return [];
      }
    },
    getWinningPatternJackpot() {
      if (this.getActiveGame != null && this.getActiveGame.winningPatternsList != null) {
          return this.getActiveGame.winningPatternsList.filter(
        (winningPattern) => winningPattern.winningPattern == "JACKPOT"
      );
      }
      else {
        return [];
      }
    },
    selectedSchedule() {
      return this.getScheduleList.find(
        (schedule) => schedule.id == this.$route.params.scheduleId
      );
    },
    adMessage() {
      return this.advertisingMessage
        ? this.advertisingMessage
        : this.getAdMessage;
    },
    currentPattern() {
      if(!this.getActiveGame){
        return '';
      }
      let winningPatternEnum =
        this.getActiveGame.winningPatterns[this.activePattern];
      if (winningPatternEnum == "ONE_LINE") {
        return "Single Line";
      } else if (winningPatternEnum == "TWO_LINE") {
        return "Double Line";
      } else if (winningPatternEnum == "FULL_HOUSE") {
        return "Full House";
      }
      return "Bingo";
    },
    gameColour(){
      return this.getActiveGame && this.getActiveGame.colour ? this.getActiveGame.colour.attributeValue : null;
    },
    fontSize(){
      return `25px`;
    }
  },
  mounted() {
    let scheduleId = this.$route.params.scheduleId;
    this.fetchStatus(scheduleId);
    this.connect();
  },
};
</script>

<style scoped>

.pattern{
  -webkit-text-stroke: 1px #000;
}

.patterns {
  
}

.image_parent{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -15px;
}


.ladbrokes_card {
  background-image: url('../../assets/background-02.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  border-style: double;
  border-width: initial;
  border-color: white;
  height: 100vh;
}

.bonus_pot {
  background-image: url('../../assets/money_ball.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  height: 100%;
}
.jackpot_1 {
  background-image: url('../../assets/jackpot_1-01.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  height: 50%;
}

.jackpot_2 {
  background-image: url('../../assets/jackpot_2-01.png');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  height: 50%;
}

.lastNumber {
  background-image: url('../../assets/last_number.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.advertMessage {
  font-weight: 600;
  border-radius: 2px;
  font-size: 35px;
  border-style: solid;
  border-width: 2px;
  text-align: center;
}

</style>