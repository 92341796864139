<template>
  <v-container>
    <v-card class="mt-5 card dark white--text">
      <div class="text-right text-h5 font-weight-medium pt-3 pr-3">
        Print Books
      </div>
      <span>
        <v-btn
          small
          class="info ml-6"
          rounded
          @click="returnToTemplates"
          ><v-icon small>mdi-arrow-left</v-icon>Templates</v-btn
        ></span
      >
      <v-form ref="form" lazy-validation class="ml-12 mr-12 white--text" dark>
        <v-row class="white--text">
          <v-col cols="1" />
          <v-col cols="4" dark>
            <v-img
              height="80"
              contain
              class="mb-5"
              :src="'data:image/jpeg;base64,' + getSelectedTemplate.logo"
            />
            <v-text-field
              value="90 Number Game"
              readonly
              dark
              class="read_only_cursor"
              label="Game Type"
              required
            ></v-text-field>

            <v-text-field
              v-model="getSelectedTemplate.perm"
              label="Perm"
              class="read_only_cursor white--text"
              readonly
              dark
              placeholder="RockBingo"
            ></v-text-field>
          </v-col>
          <v-col cols="1" />
          <v-col cols="4">
            <v-text-field
              v-model="getSelectedTemplate.title"
              label="Title"
              dark
              class="read_only_cursor"
              readonly
            ></v-text-field>

            <v-text-field
              v-model="getSelectedTemplate.pages"
              type="number"
              min="1"
              max="12"
              class="read_only_cursor"
              oninput="validity.valid||(value='');"
              label="Number of Pages"
              readonly
              dark
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-model="getSelectedTemplate.allowDoubles"
                  label="Double Book"
                  class="read_only_cursor"
                  required
                  readonly
                  dark
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  v-model="getSelectedTemplate.flyer"
                  label="Flyer"
                  class="read_only_cursor"
                  required
                  readonly
                  dark
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col cols="1" />
          <v-col cols="5">
            <v-data-table
              :headers="headers"
              :items="getSelectedTemplate.gameColours"
              item-key="id"
              :show-select="false"
              :disable-pagination="true"
              :hide-default-footer="true"
              class="page__table"
            >
              <template v-slot:[`item.attributeValue`]="{ item }">
                <div
                  :style="{ background: item.attributeValue }"
                  class="square mr-1 inline-block"
                />
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="2">
            <v-text-field readonly flat solo :value="getSelectedTemplate.flyer ? 'Flyers' : 'Single Books'" />
            <v-text-field
              v-if="getSelectedTemplate.allowDoubles"
              value="Double Books"
              readonly
              flat
              solo
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              flat
              solo
              outlined
              type="number"
              min="0"
              v-model="singleBooks"
            />
            <v-text-field
              v-if="getSelectedTemplate.allowDoubles"
              flat
              solo
              outlined
              min="0"
              type="number"
              v-model="doubleBooks"
            />
          </v-col>
        </v-row>

        <v-row class="mt-16">
          <v-col class="text-right pt-3">
            <v-btn
              color="primary main_button"
              class="mr-4"
              :loading= "getPrintingBooks"
              :disabled="!printEnabled"
              @click="
                printBooks({
                  singleBooks: singleBooks,
                  doubleBooks: doubleBooks,
                })
              "
            >
              Print
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PrintBooksOptions",
  computed: {
    ...mapGetters(["getSelectedTemplate", "getPrintingBooks"]),
    printEnabled(){
      return this.singleBooks + this.doubleBooks > 0;
    }
  },
  data: () => ({
    headers: [
      {
        text: "Colour",
        value: "attributeValue",
        sortable: false,
        width: "20%",
      },
      { text: "Name", value: "attributeName", sortable: false, width: "80%" },
    ],
    singleBooks: 0,
    doubleBooks: 0,
  }),
  methods: {
    ...mapActions(["printBooks"]),
    returnToTemplates() {
        this.$emit("returnToTemplates");
    },
    resetValues(){
      this.singleBooks = 0;
      this.doubleBooks = 0;
    }
  },
};
</script>

<style scoped>
.square {
  width: 2vw;
  height: 2vw;
  min-width: 30px;
  min-height: 30px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 2px;
}

.read_only_cursor input {
  cursor: pointer;
}
</style>