<template>
  <v-container>
    <v-row class="ma-1">
      <v-spacer />
    </v-row>
    <v-card class="card" dark>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="ml-12 mr-12 mt-8"
      >
        <v-row>
          <v-col cols="1" />
          <v-col cols="4">
            <v-text-field
              v-model="schedule.title"
              :counter="30"
              :rules="nameRules"
              label="Title"
              required
            ></v-text-field>
            <v-menu
              ref="showDate"
              v-model="showDate"
              :close-on-content-click="false"
              :return-value.sync="schedule.date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="schedule.date"
                  label="Session Date"
                  prepend-icon="mdi-calendar"
                  :rules="dateRules"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="schedule.date"
                no-title
                scrollable
                :min="schedulePickerMin"
                :open-date="new Date()"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="showDate = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.showDate.save(schedule.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-model="schedule.gameDuration"
              v-if="this.schedule.type == 'FIXED_ODDS_BINGO'"
              type="number"
              min="1"
              max="12"
              label="Game Interval Duration (mins)"
              :rules="[(v) => !!v || 'Game Interval is required']"
            ></v-text-field>
            <v-select
              v-model="selectedTemplates"
              @change="updatePlayableGames(selectedTemplates)"
              :items="filterTemplates"
              item-value="id"
              label="Products To Include"
              clearable
              multiple
              :rules="productRules"
              :disabled="!schedule.type"
              max
            >
              <template v-slot:selection="{ item }">
                <v-chip text-color="white" :color="determineChipColour(item)">{{
                  item.title
                }}</v-chip>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.title }}</span>
                        <v-spacer></v-spacer>
                        <v-chip
                          v-if="item.flyer"
                          text-color="white"
                          color="success"
                          small
                          class="ml-2"
                          >Flyer</v-chip
                        >
                        <v-chip
                          v-if="item.isPackage"
                          text-color="white"
                          color="success"
                          small
                          class="ml-2"
                          >Package</v-chip
                        >
                        <span v-else>{{ item.pages }} Pages</span>
                        <img
                          contain
                          class="logo"
                          :src="'data:image/jpeg;base64,' + item.logo"
                        />
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="1" />
          <v-col cols="4">
            <v-select
              v-model="schedule.type"
              :items="gameTypes"
              :rules="[(v) => !!v || 'Type of Game is required']"
              label="Type of Game"
              required
            ></v-select>
            <v-menu
              v-if="this.schedule.type == 'FIXED_ODDS_BINGO'"
              ref="showByDate"
              v-model="showEndByDate"
              :close-on-content-click="false"
              :return-value.sync="schedule.endByDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="schedule.endByDate"
                  label="End By Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  :rules="endDateRules"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="schedule.endByDate"
                no-title
                scrollable
                :min="schedulePickerMin"
                :open-date="new Date()"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="showEndByDate = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.showByDate.save(schedule.endByDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-model="schedule.callDuration"
              v-if="this.schedule.type == 'FIXED_ODDS_BINGO'"
              type="number"
              min="1"
              max="12"
              :rules="[(v) => !!v || 'Call Duration is required']"
              label="Call Duration (seconds)"
            ></v-text-field>
            
          </v-col>
        </v-row>
        <v-row v-if="this.schedule.type != 'LOTTO'">
          <v-col cols="1" />
          <v-col cols="10">
            <v-data-table
              light
              v-show="playableGames.length > 0"
              :headers="headers"
              :items="playableGames"
              item-key="tableId"
              :show-select="false"
              :disable-pagination="true"
              :hide-default-footer="true"
              class="page__table"
            >
              <template v-slot:body="props">
                <draggable :list="props.items" tag="tbody" @end="endDrag">
                  <tr v-for="(item, index) in props.items" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <div
                        class="square"
                        :style="{ background: item.colour }"
                      />
                    </td>
                    <td>{{ item.description }}</td>
                    <td><v-icon>mdi-reorder-horizontal </v-icon></td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col class="text-right pt-3">
            <v-btn class="mr-4 accent" @click="reset"> Clear </v-btn>
            <v-btn color="primary main_button" class="mr-4" @click="submit"> Add </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Draggable from "vuedraggable";
export default {
  components: {
    Draggable,
  },
  name: "CreateSchedule",
  created() {
    this.fetchTemplates();
  },
  data: () => ({
    valid: false,
    schedule: {
      title: "",
      sessionType: "EVENING",
      date: "",
      endByDate: "",
      time: "",
      callDuration: 0,
      gameDuration: 0
    },
    showDate: false,
    showEndByDate: false,
    showTime: false,
    schedulePicker: null,
    schedulePickerMin: new Date().toISOString().substr(0, 10),
    selectedSchedule: null,
    sessionTypes: ["Morning", "Afternoon", "Evening", "Time Specific"],
    gameTypes: ["BINGO", "LOTTO", "FIXED_ODDS_BINGO"],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 30) || "Name must be less than 30 characters",
    ],
    dateRules: [
      (v) => !!v || "Please select a date",
    ],
    endDateRules: [
      (v) => !!v || "Please select a date",
    ],
    productRules : [
      (v) => !!v && v.length > 0 || "Schedules require at least one product"
    ],
    selectedTemplates: [],
    gameOrder: [],
    headers: [
      { text: "Order", sortable: false, width: "10%" },
      { text: "Colour", value: "colour", sortable: false, width: "10%" },
      {
        text: "Description",
        value: "description",
        sortable: false,
        width: "70%",
      },
      { text: "Reorder", sortable: false, width: "10%" },
    ],
  }),
  mounted() {
    this.setCurrentPage('Create new Schedule')
  },
  computed: {
    ...mapGetters(["getTemplateList"]),
    showTimePicker() {
      return this.schedule.sessionType == "Time Specific" ? true : false;
    },
    filterTemplates() {
      let templates = this.getTemplateList.filter((template) =>
          template.type == this.schedule.type
        );
      return templates;

    },
    playableGames: {
      get: function () {
        let activeTemplates = this.getTemplateList.filter((template) =>
          this.selectedTemplates.includes(template.id)
        );
        let playableGames = [].concat(
          ...activeTemplates.map((template) =>
            this.createGameTableItems(template)
          )
        );
        return playableGames;
      },
    },
  },
  methods: {
    ...mapActions(["fetchTemplates", "saveSchedule", "setCurrentPage"]),
    endDrag(event) {
      const movedItem = this.gameOrder.splice(event.oldIndex, 1)[0];
      this.gameOrder.splice(event.newIndex, 0, movedItem);
    },
    submit() {
      if(this.$refs.form.validate()){
      this.schedule.gameOrder = [];
      for (let i = 0; i < this.gameOrder.length; i++) {
        this.schedule.gameOrder.push({
          colour: {
            id: this.gameOrder[i].id,
            attributeName: this.gameOrder[i].name,
            attributeValue: this.gameOrder[i].colour,
            attributeType: this.gameOrder[i].type,
          },
          templateId: this.gameOrder[i].templateId,
        });
      }
      if (this.schedule.type == "LOTTO") {
          this.schedule.gameOrder.push({
            // colour: {
            //   id: this.gameOrder[i].id,
            //   attributeName: this.gameOrder[i].name,
            //   attributeValue: this.gameOrder[i].colour,
            //   attributeType: this.gameOrder[i].type,
            // },
            templateId: this.selectedTemplates[0],
          });
      }
      this.schedule.selectedTemplates = this.selectedTemplates;
      this.saveSchedule(this.schedule);
      }
    },
    updatePlayableGames(selectedTemplates) {
      let activeTemplates = this.getTemplateList.filter((template) =>
        selectedTemplates.includes(template.id)
      );
      let playableGames = [].concat(
        ...activeTemplates.map((template) =>
          this.createGameTableItems(template)
        )
      );
      this.gameOrder = playableGames;
    },
    determineChipColour(item) {
      if (item.flyer) {
        return item.gameColours[0].attributeValue;
      } else {
        if (item.pages == 6) {
          return "red";
        } else if (item.pages == 3) {
          return "green";
        } else {
          return "purple";
        }
      }
    },
    createGameTableItems(template) {
      let gameTableItems = [];

      for (var i = 0; i < template.gameColours.length; i++) {
        gameTableItems.push({
          tableId: template.id + i,
          templateId: template.id,
          name: template.gameColours[i].attributeName.toLowerCase(),
          id: template.gameColours[i].id,
          colour: template.gameColours[i].attributeValue,
          description: template.title + " - Game " + (i + 1),
          type: template.gameColours[i].attributeType,
        });
      }
      return gameTableItems;
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
.logo {
  max-height: 24px;
}
.square {
  width: 2vw;
  height: 2vw;
  min-width: 30px;
  min-height: 30px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 2px;
}
</style>