import axios from 'axios';
import router from '@/router';
import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import store from '../../../store';

const UNAUTHORIZED = 401;

const baseURL = process.env.VUE_APP_BASE_URL

const newAxios = function () {
  return axios.create({
    baseURL: baseURL,
  });
};

export const service = newAxios();
export const socketURL = baseURL;

let requestQueue = [];
let reauthorising = false;

// Set the AUTH token for any request
service.interceptors.request.use(function (config) {
  let token = JSON.parse(localStorage.getItem('token'));
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

service.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    const { status } = error.response;
    if (error.response?.status !== 401) {
      return Promise.reject(error);
    }
    if (status === UNAUTHORIZED) {
      console.log("401 received");
      if(!reauthorising){
        console.log("try refresh");
        reauthorising = true;
        return service.post(ENDPOINTS.AUTH.REFRESH_TOKEN, { "refreshToken": store.getters.getRefreshToken })
        .then(response => {
          if (response.data.accessToken) {
            reauthorising = false;
            localStorage.setItem('token', JSON.stringify(response.data.accessToken));
            originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
            return axios(originalRequest);
          } else {
            console.log("regresh failed, reroute to login");
            reauthorising = false;
            localStorage.removeItem('user')
            router.push("/login")
          }
          requestQueue.push(error.config);
        })
        .catch(error => {
          console.log(error);
          reauthorising = false;
          localStorage.removeItem('user')
          router.push("/login")
        })
      } else {
        reauthorising = false;
        localStorage.removeItem('user')
        router.push("/login")
      }
    }
  }
)