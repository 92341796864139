<template>
  <v-snackbar
    v-model="value"
    :timeout="-1"
    :color="value.mode"
    bottom
    right
    multi-line
  >
    <v-row>
      <p class="text-left mb-0 mx-3 fa-md" id="system-notification">
        {{ value.message || "" }}
      </p>
      <v-btn @click="dismiss" text class="float-right body-2" small>x</v-btn>
    </v-row>
    <!-- https://github.com/vuetifyjs/vuetify/issues/7310 -->
    <div v-show="false" class="v-menu__content--active" />
  </v-snackbar>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Snackbar",
  props: {
    value: Object,
  },
  created() {
    let self = this;
    setTimeout(() => {
      self.dismiss();
    }, 3500);
  },
  methods: {
    ...mapActions(["removeFromSnackbarQueue"]),
    dismiss() {
      this.removeFromSnackbarQueue(this.value);
    },
  },
};
</script>
