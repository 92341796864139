import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js';

export default {
    fetchGameSales(startDate, endDate) {
        return service.get(ENDPOINTS.REPORTING.FETCH_GAME_SALES,
            {
                params: {
                  startDate: startDate,
                  endDate: endDate
                }
              }
            
        );
    },
    fetchGameBreakdown(startDate, endDate) {
      return service.get(ENDPOINTS.REPORTING.FETCH_GAME_BREAKDOWN,
          {
              params: {
                startDate: startDate,
                endDate: endDate
              }
            }
          
      );
    },
    fetchBookBreakdown(startDate, endDate) {
      return service.get(ENDPOINTS.REPORTING.FETCH_BOOK_BREAKDOWN,
          {
              params: {
                startDate: startDate,
                endDate: endDate
              }
            }
          
      );
    },
    fetchScheduleInfo(startDate, endDate) {
        
        return service.get(ENDPOINTS.REPORTING.FETCH_SCHEDULE_INFO,
            {
                params: {
                  startDate: startDate,
                  endDate: endDate
                }
              }
            );
    },
}