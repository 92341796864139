import Caller from '@/api/clients/bingoManager-core/caller.js';
import Scheduler from '@/api/clients/bingoManager-core/scheduler.js';
import CONST from '@/const/index.js'
import router from '@/router'

const getCallerDefaultState = () => {
    return {
        currentNumber: null,
        callingNumber: false,
        checkingWinner: false,
        drawnNumbers: [],
        activePages: [],
        gameWon: false,
        activeGame: null,
        currentPattern: 0,
        showVerificationBoard: false,
        winnerVerified: false,
        verifiedWinners: [],
        missedWinners: [],
        settingUpGame: false,
        winningPatternsList: [],
        checkedPanel: {
            cardId: null,
            id: null,
            panelId: null,
            perm: null
        },
        tenantId: null
    }
}
const state = getCallerDefaultState();

const getters = {
    getCurrentNumber: state => state.currentNumber ? state.currentNumber : 'Start',
    getOrdinal: state => state.drawnNumbers.length,
    getDrawnNumbersString: state => state.drawnNumbers.join(', '),
    getDrawnNumbers: state => state.drawnNumbers,
    getDistanceFromWinning: state => state.activePages.map(e => e.distanceFromWin).sort(function (a, b) { return a - b }),
    getActivePages: state => state.activePages.length,
    getCurrentGameProductTemplate: state => state.currentGameProductTemplate,
    getCurrentPattern: state => state.currentPattern,
    getGameWon: state => state.gameWon,
    getWinners: state => state.activePages.filter(p => !!p.winner).map(e => e.playerName + ": " + e.panelId),
    getVerifiedWinners: state => state.verifiedWinners,
    getActiveGame: state => state.activeGame ? state.activeGame[0] : null,
    getShowVerificationBoard: state => state.showVerificationBoard,
    getCheckedPanel: state => state.checkedPanel,
    getCallingNumber: state => state.callingNumber,
    getCheckingWinner: state => state.checkingWinner,
    getSettingUpGame: state => state.settingUpGame,
    getWinnerVerified: state => state.winnerVerified,
    getMissedWinners: state => state.missedWinners,
    getTenantId: state => state.tenantId,
}

const actions = {
    async startSchedule({ commit, dispatch }, scheduleId) {
        try {
            let response = await Scheduler.startSchedule(scheduleId);
            if (response.data) {
                commit('resetCallerState');
                commit('updateActiveGame', new Array(response.data));
                commit('updateTenantId', response.data.tenantId);
                //TODO: remove the localStorage set, once the tenantId is available through the module on the player game screen
                localStorage.setItem("tenantId",response.data.tenantId)
                router.push("/play/game/" + response.data.type)
            }
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async endSchedule({ commit, dispatch }, scheduleId) {
        try {
            let response = await Scheduler.endSchedule(scheduleId);
            if (response.data) {
                commit('resetCallerState');
                commit('updateTenantId', response.data.tenantId);
            }
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async callNumber({ commit, dispatch }, scheduleId) {
        try {
            let response = await Caller.callNumber(scheduleId);
            if (response.data) {
                console.log("Number Call response received: " + response.data.drawnNumber);
                commit('updateCurrentNumber', response.data.drawnNumber);
                commit('updateDrawnNumbers', response.data.drawnNumber);
                commit('updateActivePages', response.data.pageDistanceFromWinning);
                dispatch('setCallingNumber', false);
                if (response.data.gameWon) {
                    commit('updateGameWon', response.data.gameWon);
                    dispatch('setCheckingWinner', true)
                }
            }
        } catch (err) {
            dispatch('setCallingNumber', false);
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async recallNumber({ commit, dispatch }, scheduleId) {
        try {
            let response = await Caller.recallNumber(state.currentNumber, scheduleId);
            if (response.data) {
                commit('updateCurrentNumber', response.data.drawnNumber);
                commit('recallNumber', response.data.drawnNumber);
                commit('updateActivePages', response.data.pageDistanceFromWinning);
                dispatch('setCallingNumber', false);
                commit('updateGameWon', response.data.gameWon);
                dispatch('setCheckingWinner', response.data.gameWon)
            }
        } catch (err) {
            dispatch('setCallingNumber', false);
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async nextPattern({ commit, dispatch }, {nextPattern: nextPattern, scheduleId: scheduleId}) {
        console.log("pattern = " + nextPattern);
            await Caller.nextPattern(nextPattern, scheduleId).then(() => {
                commit('updateGameWon', false);
                dispatch('clearWinnerVerified');
              }, () => {
                this._vm.$snackbar({
                    message: ("Oops, somethings gone wrong. Please try again"),
                    mode: CONST.THEME.COLOURS.ERROR
                }, dispatch);
              }).then(() => {
                if (nextPattern == null) {
                    dispatch('callNumber')
                }}
              )
    },
    async nextGame({ commit, dispatch }, { scheduleId: scheduleId, gameOrder: gameOrder, nextGame: nextGame }) {
        try {
            commit('updateSettingUpGame', true);
            let response = await Scheduler.startNextGame(scheduleId, gameOrder, nextGame);
            if (response.data) {
                commit('resetCallerState');
                commit('updateActiveGame', new Array(response.data));
                commit('updateGameWon', false);
                dispatch('clearWinnerVerified');
            } else {
                this._vm.$snackbar({
                    message: ("No more games scheduled for this session"),
                    mode: CONST.THEME.COLOURS.INFO
                }, dispatch);
            }
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
        commit('updateSettingUpGame', false);
    },
    async fetchPanel({ commit, dispatch }, panelId) {
        try {
            let response = await Caller.fetchPanel(panelId)
            if(response.data){
                commit('updateCheckedPanel', response.data);
            }
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async setWinnerVerified({ commit, dispatch }, winningPanel) {
        dispatch('setCallingNumber', true);
        const page = state.activePages.filter(p => p.panelId == winningPanel)[0];
        if(!page){
            console.log("no page found " + winningPanel);
            dispatch('setCallingNumber', false);
            this._vm.$snackbar({
                message: ("This is not a winner, please verify the correct winning panel"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
            return;
        }
        const pageId = page.id;
        let winner = {
            panelId: winningPanel,
            pageId: pageId,
            gameId: state.activeGame[0].id,
            winningPattern: state.activeGame[0].winningPatterns[state.currentPattern],
            ordinal: state.drawnNumbers.length,
            currentNumber: state.currentNumber,
            scheduleId: state.activeGame[0].scheduleId
        };
        try {
            let response = await Caller.verifyWinner(winner)
            if(response.data){
                let winningPage = state.activePages.filter(p => p.id == pageId);

                commit('updateVerifiedWinners', { 
                    winner: winningPage[0].playerName, 
                    winningPattern: state.currentPattern
                });
                commit('updateWinnerVerified', true)
                let editedPage = state.activePages.find((page) => page.id == pageId)
                editedPage.winner = null;
                editedPage.playerName = null;
                let newPages = state.activePages.filter((page) => page.id !== pageId)
                newPages.push(editedPage)
                commit('updateActivePages', newPages)
                dispatch('setCallingNumber', false);
            }
        } catch ( err) {
            dispatch('setCallingNumber', false);
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async setWinnerMissed({ commit, dispatch }) {
        const missedWinner = state.activePages.filter(p => !!p.winner);
        let winner = {
            panelId: missedWinner[0].panelId,
            pageId: missedWinner[0].pageId,
            gameId: state.activeGame[0].id,
            winningPattern: state.activeGame[0].winningPatterns[state.currentPattern],
            ordinal: state.drawnNumbers.length,
            currentNumber: state.currentNumber
        };
        try {
            let response = await Caller.markMissedWinner(winner)
            if(response.data){
                commit('updateMissedWinners', { 
                    page: missedWinner[0].pageId,
                    panel: missedWinner[0].panelId,
                    winningPattern: state.currentPattern
                });
                commit('updateGameWon', false);
            }
        } catch ( err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    updateGameState({ commit }, gameState){

        commit('updateDrawnNumbersBulk', gameState.numbersCalled);
        commit('updateCurrentNumber', gameState.currentNumber);
        commit('updateActiveGame', new Array(gameState.currentGame));
        commit('updateWinningPatternsOnActiveGame', gameState.winningPatterns);
        commit('updateCurrentGameProductTemplate', gameState.currentGameProductTemplate);
        commit('updateActivePages', gameState.pageDistanceFromWinning);

        if (gameState.gameWon) {
            commit('updateGameWon', gameState.gameWon);
            commit('updateWinnerVerified', false );
        }

        if (gameState.currentWinningPattern == "ONE_LINE") {
            commit('updateCurrentPattern', 0);
        } else if (gameState.currentWinningPattern == "TWO_LINE") {
            commit('updateCurrentPattern', 1);
        } else if (gameState.currentWinningPattern == "FULL_HOUSE") {
            commit('updateCurrentPattern', 2);
        }

        for (let j = 0; j < gameState.winners.length; j++) {
            let winnerWinningPattern = 0;
            if (gameState.winners[j].winningPattern == "ONE_LINE") {
                winnerWinningPattern = 0;
            } else if (gameState.winners[j].winningPattern == "TWO_LINE") {
                winnerWinningPattern = 1;
            } else if (gameState.winners[j].winningPattern == "FULL_HOUSE") {
                winnerWinningPattern = 2;
            }

            if (!gameState.winners[j].missed) {
                commit('updateVerifiedWinners', { 
                    winner: gameState.winners[j].playerName, 
                    winningPattern: winnerWinningPattern
                });
                if (gameState.currentWinningPattern == gameState.winners[j].winningPattern) {
                    commit('updateWinnerVerified', true );
                }
            }
            else {
                commit('updateMissedWinners', { 
                    page: gameState.winners[j].pageId,
                    panel: gameState.winners[j].panelId,
                    winningPattern: winnerWinningPattern
                });
                //commit('updateGameWon', false);
            }
                
            const page = state.activePages.filter(p => p.panelId == gameState.winners[j].panelId)[0];
            if (page != null) {
                const pageId = page.id;
                let editedPage = state.activePages.find((page) => page.id == pageId)
                editedPage.winner = null;
                editedPage.playerName = null;
                let newPages = state.activePages.filter((page) => page.id !== pageId)
                newPages.push(editedPage)
                commit('updateActivePages', newPages)
            }
        }

    },
    updateNextGameForPlayer({ commit }, game){
        let winningPatterns = state.activeGame[0].winningPatternsList;
        commit('resetCallerState');
        commit('updateActiveGame', new Array(game));
        commit('updateWinningPatternsOnActiveGame', winningPatterns);
        commit('updateGameWon', false);
    },
    addCalledNumber({ commit }, number){
        commit('updateDrawnNumbers', number)
        commit('updateCurrentNumber', number)
    },
    recalledNumber({ commit }, number){
        commit('recallNumber', number)
        commit('updateCurrentNumber', number)
    },
    setCurrentPattern({ commit }, pattern){
        commit('updateCurrentPattern', pattern)
    },
    resetCallerState({ commit }){
        commit('resetCallerState');
    },
    setShowVerificationBoard({ commit }, show) {
        commit('updateShowVerificationBoard', show)
    },
    setCallingNumber({ commit }, calling) {
        commit('updateCallingNumber', calling)
    },
    setCheckingWinner({ commit }, checking) {
        commit('updateCheckingWinner', checking)
    },
    clearWinnerVerified({ commit }) {
        commit('updateWinnerVerified', false)
    },
    clearCheckedPanel({ commit }){
        commit('updateCheckedPanel', {
            cardId: null,
            id: null,
            panelId: null,
            perm: null
        })
    }
}

const mutations = {
    updateCurrentNumber(state, number) {
        state.currentNumber = number;
    },
    updateDrawnNumbers(state, number) {
        state.drawnNumbers.push(number);
    },
    updateDrawnNumbersBulk(state, numbers){
        state.drawnNumbers = numbers;
    },
    recallNumber(state, number){
        if(state.drawnNumbers.indexOf(number)>-1 || (state.drawnNumbers.length === 1 && number == null)){
            state.drawnNumbers.pop();
        }
    },
    updateActivePages(state, pages) {
        state.activePages = pages;
    },
    updateCurrentGameProductTemplate(state, currentGameProductTemplate) {
        state.currentGameProductTemplate = currentGameProductTemplate;
    },
    updateGameWon(state, won) {
        state.gameWon = won;
    },
    updateActiveGame(state, game) {
        state.activeGame = game
    },
    updateWinningPatternsOnActiveGame(state, winningPatternsList) {
        if (state.activeGame[0] != null && winningPatternsList != null) {
            state.activeGame[0].winningPatternsList = winningPatternsList
        } 
    },
    updateCurrentPattern(state, patttern){
        state.currentPattern = patttern;
    },
    updateCheckedPanel(state, panel) {
        state.checkedPanel = panel;
    },
    resetCallerState: state => {
        Object.assign(state, getCallerDefaultState());
    },
    updateShowVerificationBoard(state, show) {
        state.showVerificationBoard = show 
    },
    updateCallingNumber(state, calling) {
        state.callingNumber = calling;
    },
    updateCheckingWinner(state, checking) {
        state.checkingWinner = checking;
    },
    updateWinnerVerified(state, verified) {
        state.winnerVerified = verified;
    },
    updateVerifiedWinners(state, winner){
        let currentWinners = Object.assign([], state.verifiedWinners);
        currentWinners.push(winner);
        state.verifiedWinners = currentWinners;
    },
    updateMissedWinners(state, winner){
        let currentMissedWinners = Object.assign([], state.missedWinners);
        currentMissedWinners.push(winner);
        state.missedWinners = currentMissedWinners;
    },
    updateSettingUpGame(state, settingUpGame){
        state.settingUpGame = settingUpGame;
    },
    updateTenantId(state, tenantId){
        state.tenantId = tenantId;
    },
    resetCaller (state) {
        // acquire initial state
        const s = getCallerDefaultState()
        Object.keys(s).forEach(key => {
          state[key] = s[key]
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}