var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.selectableTemplates,"sort-by":"calories","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-toolbar-title',[_vm._v("Templates")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.flyer",fn:function(ref){
var item = ref.item;
return [(item.flyer)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.allowDoubles",fn:function(ref){
var item = ref.item;
return [(item.allowDoubles)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"logo",attrs:{"contain":"","src":'data:image/jpeg;base64,' + item.logo}})]}},{key:"item.gameColours",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fluid-container"},_vm._l((item.gameColours),function(colour){return _c('div',{key:colour.id,staticClass:"square mr-1",style:({ background: colour.attributeValue })})}),0)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"success",attrs:{"small":"","rounded":""},on:{"click":function($event){return _vm.selectTemplate(item)}}},[_vm._v("Print")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }