import Customers from '@/api/clients/bingoManager-core/customers.js';
import CONST from '@/const/index.js'

const getCustomerDefaultState = () => {
    return {
        customers: []
    }
}
const state = getCustomerDefaultState();

const getters = {
    getCustomerList: state => state.customers
}

const actions = {
    async registerCustomer({ commit, dispatch }, customer) {
        try {
            let result = await Customers.register(customer);
            if(result && result.data){
                commit('addNewCustomer', result.data);
                this._vm.$snackbar({
                    message: ("Customer saved"),
                    mode: CONST.THEME.COLOURS.SUCCESS
                }, dispatch);
                return customer;
            }
        }catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async updateCustomer({ commit, dispatch }, customer) {
        try {
            let result = await Customers.update(customer);
            if(result && result.data){
                customer.id = result.data;
                commit('updateCustomer', customer);
                this._vm.$snackbar({
                    message: ("Customer Updated."),
                    mode: CONST.THEME.COLOURS.SUCCESS
                }, dispatch);
                return customer;
            }
        }catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async deleteCustomer({ commit, dispatch }, customer) {
        try {
            let result = await Customers.delete(customer);
            if(result && result.data){
                //customer.id = result.data;
                commit('deleteCustomer', customer);
                this._vm.$snackbar({
                    message: ("Customer Deleted."),
                    mode: CONST.THEME.COLOURS.SUCCESS
                }, dispatch);
                return customer;
            }
        }catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async resetCustomerPassword({  dispatch }, customer) {
        try {
            let result = await Customers.resetPassword(customer);
            if(result && result.data){
                customer.id = result.data;
                this._vm.$snackbar({
                    message: ("Password Reset."),
                    mode: CONST.THEME.COLOURS.SUCCESS
                }, dispatch);
                return customer;
            }
        }catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async fetchCustomers({ commit, dispatch }) {
        try {
            const result = await Customers.fetchAll();
            commit('addCustomers', result.data)
        }catch (err) {
            this._vm.$snackbar({
                message: ("Cannot retreive customers. If problem persists, please contact support"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);  
        }
    },
}

const mutations = {
    addCustomers(state, customers) {
        state.customers = customers
    },
    addNewCustomer(state, customer) {
        state.customers.push(customer)
    },
    updateCustomer(state, customer) { 
        var foundIndex = state.customers.findIndex(x => x.id == customer.id);
        Object.assign(state.customers[foundIndex], customer);
    },
    deleteCustomer(state, customer) { 
        var foundIndex = state.customers.findIndex(x => x.id == customer.id);
        state.customers.splice(foundIndex,1)
    },
    resetCustomer (state) {
        // acquire initial state
        const s = getCustomerDefaultState()
        Object.keys(s).forEach(key => {
          state[key] = s[key]
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}