import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js';

export default {
    fetchAllScheduleId(scheduleId) {
        return service.get(ENDPOINTS.PRODUCTS.FETCH_ALL_FOR_SCHEDULE_ID, {
            params: {
                scheduleId: scheduleId
            }
        });
    },
    saveProductDetails(products) {
        // const formData = new FormData();
        // formData.append('productId', product.id);
        // formData.append('productPrice', product.price);
        // if(product.doublePrice){
        //     formData.append('doubleProductPrice', product.doublePrice) 
        // }
        return service.post(ENDPOINTS.PRODUCTS.SAVE_DETAILS, products);
    },
    posPurchase(purchase) {
        return service.post(ENDPOINTS.PRODUCTS.POS_PURCHASE, purchase);
    }
}