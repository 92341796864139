import { render, staticRenderFns } from "./NinteyNumberBoard10by9.vue?vue&type=template&id=4329501c&scoped=true&"
import script from "./NinteyNumberBoard10by9.vue?vue&type=script&lang=js&"
export * from "./NinteyNumberBoard10by9.vue?vue&type=script&lang=js&"
import style0 from "./NinteyNumberBoard10by9.vue?vue&type=style&index=0&id=4329501c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4329501c",
  null
  
)

export default component.exports