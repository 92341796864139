const NotificationsPlugin = {};

NotificationsPlugin.install = function(Vue) {
  Vue.prototype.$snackbar = function(notification, dispatch) {
    dispatch = dispatch || (this.$store && this.$store.dispatch);
    if (!dispatch) {
      return;
    }
    dispatch('displaySnackbar', notification, { root: true });
  };
  Vue.prototype.$dialog = function(dialog, dispatch) {
    dispatch = dispatch || (this.$store && this.$store.dispatch);
    if (!dispatch) {
      return;
    }
    dispatch('displayDialog', dialog, { root: true });
  };
};

export default NotificationsPlugin;