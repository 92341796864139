<template>
  <div class="base">
    <!--<img class="base" src="../../assets/usa2.jpg"/> -->
    <table class="numberBoard" :style="{ 'font-size': fontSize }">
      <tr>
        <td v-for="n in 10" :key="n" :id="n" :style="[{ background: backgroundColour(n)}]">{{ n }}</td>
      </tr>
      <tr>
        <td v-for="n in 10" :key="n+10" :id="n+10" :style="[{ background: backgroundColour(n+10)}]">{{ n+10 }}</td>
      </tr>
      <tr>
        <td v-for="n in 10" :key="n+20" :id="n+20" :style="[{ background: backgroundColour(n+20)}]">{{ n+20 }}</td>
      </tr>
      <tr>
        <td v-for="n in 10" :key="n+30" :id="n+30" :style="[{ background: backgroundColour(n+30)}]">{{ n+30 }}</td>
      </tr>
       <tr>
        <td v-for="n in 10" :key="n+40" :id="n+40" :style="[{ background: backgroundColour(n+40)}]">{{ n+40 }}</td>
      </tr>
      <tr>
        <td v-for="n in 10" :key="n+50" :id="n+50" :style="[{ background: backgroundColour(n+50)}]">{{ n+50 }}</td>
      </tr>
      <tr>
        <td v-for="n in 10" :key="n+60" :id="n+60" :style="[{ background: backgroundColour(n+60)}]">{{ n+60 }}</td>
      </tr>
      <tr>
        <td v-for="n in 10" :key="n+70" :id="n+70" :style="[{ background: backgroundColour(n+70)}]">{{ n+70 }}</td>
      </tr>
      <tr>
        <td v-for="n in 10" :key="n+80" :id="n+80" :style="[{ background: backgroundColour(n+80)}]">{{ n+80 }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NinetyNumberBoard10by9",
  props: {
      fontSize: {
          default: "55x"
      }
  },
  methods: {
      
  },
  computed: {
      ...mapGetters(["getDrawnNumbers", "getCurrentNumber"]),
      backgroundColour(){
          return number => this.getCurrentNumber == number ? '#8a04ff' : this.getDrawnNumbers.indexOf(number) > -1 ? '#FFC425' : null
      } 
  }
};
</script>

<style scoped>
.base {
  width: 100%;
  height: 100%;
  background-color: #FFC425;
  padding: 1%;
  border-radius: 10px;
}

.numberBoard {
    width: 100%;
    background-color: #f04a36;
    color: white;
    border-collapse: collapse;
    text-align: center;
    font-weight: bold;
}

td {
  border: 6px solid black;
}

</style>