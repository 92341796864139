<template>
  <v-data-table
    :headers="headers"
    :items="getBookSales"
    :search="search"
    class="elevation-3"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Product Sales</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn small icon class="pt-1" @click="updateGameSales()">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
          <vue-json-to-csv
              :json-data   = getBookSales>
              <button small icon class="pt-1">
                <v-icon>mdi-arrow-collapse-down</v-icon>
              </button>
          </vue-json-to-csv>
          <v-spacer></v-spacer>
            <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="updateTableOnDateChange(dates)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-col cols="12" sm="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field
          ></v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:[`item.date`]="{ item }">
      {{ new Date(item.date).toDateString() }}
    </template>
    <template v-slot:[`item.double_price`]="{ item }">
      {{ item.double_price ? item.double_price : "-" }}
    </template>
    <template v-slot:[`item.bookSold`]="{ item }">
      {{ item.singles + item.doubles }}
    </template>
    <template v-slot:[`item.revenue`]="{ item }">
      {{
        (
          item.singles * item.price +
          item.doubles * item.double_price
        ).toLocaleString("en-IE", {
          style: "currency",
          currency: "EUR",
        })
      }}
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ReportingProductTable",
  computed: {
    ...mapGetters(["getBookSales"]),
    dateRangeText () {
        return this.dates.join(' ~ ')
      },
  },
  data: () => ({
    search: "",
    dates: [(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)).toISOString().substr(0, 10),
    (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)).toISOString().substr(0, 10)],
    menu: false,
    headers: [
      {
        text: "Date",
        align: "start",
        value: "date",
      },
      {
        text: "Product",
        align: "start",
        value: "title",
      },
      { text: "Singles", value: "singles" },
      { text: "€", value: "price" },
      { text: "Doubles", value: "doubles" },
      { text: "€", value: "double_price" },
      { text: "Total Books Sold", value: "bookSold" },
      { text: "Revenue", value: "revenue", align: "center" },
    ],
  }),

  created() {
    this.fetchGameSales(this.dates);
  },
  methods: {
    ...mapActions(["fetchGameSales"]),
    updateGameSales() {
      this.fetchGameSales(this.dates);
    },
    selectTemplate(template) {
      this.setSelectedTemplate(template);
      this.$emit("templateSelected");
    },
    updateTableOnDateChange(dates) {
      this.updateGameSales();
      this.$refs.menu.save(dates)
    }
  },
};
</script>

<style scoped>
.logo {
  max-height: 25px;
}

.square {
  width: 1.25vw;
  height: 1.25vw;
  min-width: 10px;
  min-height: 10px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 0.5px;
}

.fluid-container {
  display: flex;
}
</style>