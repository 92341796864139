import Reporting from '@/api/clients/bingoManager-core/reporting.js';
import CONST from '@/const/index.js'

const getReportingDefaultState = () => {
    return {
        bookSales: [],
        schedules: [],
        games: [],
        books: [],
    }
}
const state = getReportingDefaultState();

const getters = {
    getBookSales: state => state.bookSales,
    getScheduleInfo: state => state.schedules,
    getGames: state => state.games,
    getBooks: state => state.books
}

const actions = {
    async fetchGameSales({ commit, dispatch }, dates) {
        try {
            const result = await Reporting.fetchGameSales(dates[0], dates[1]);
            for(let i=0; i<result.data.length; i++){
                result.data[i].date = new Date(result.data[i].date);
            }
            commit('addBookSales', result.data)
        }catch (err) {
            this._vm.$snackbar({
                message: ("Cannot retreive sales. If problem persists, please contact support"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);  
        }
    },
    async fetchGameBreakdown({ commit, dispatch }, dates) {
        try {
            const result = await Reporting.fetchGameBreakdown(dates[0], dates[1]);
            for(let i=0; i<result.data.length; i++){
                result.data[i].date = new Date(result.data[i].date);
            }
            commit('addGames', result.data)
        }catch (err) {
            this._vm.$snackbar({
                message: ("Cannot retreive games. If problem persists, please contact support"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);  
        }
    },
    async fetchBookBreakdown({ commit, dispatch }, dates) {
        try {
            const result = await Reporting.fetchBookBreakdown(dates[0], dates[1]);
            for(let i=0; i<result.data.length; i++){
                result.data[i].date = new Date(result.data[i].date);
            }
            commit('addBooks', result.data)
        }catch (err) { 
            this._vm.$snackbar({
                message: ("Cannot retreive books. If problem persists, please contact support"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);  
        }
    },
    async fetchScheduleInfo({ commit, dispatch }, dates) {
        try {
            const result = await Reporting.fetchScheduleInfo(dates[0], dates[1]);
            commit('addSchedules', result.data)
        }catch (err) {
            this._vm.$snackbar({
                message: ("Cannot retreive schedules. If problem persists, please contact support"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);  
        }
    }
}

const mutations = {
    addBookSales(state, products) {
        state.bookSales = products
    },
    addSchedules(state, schedules) {
        state.schedules = schedules
    },
    addGames(state, games) {
        state.games = games
    },
    addBooks(state, books) {
        state.books = books
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}