import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js'

export default {

    saveColours(colours){
        return service.post(ENDPOINTS.SYSTEM_ADMIN.SAVE_COLOURS, colours);
    },
    fetchColours(){
        return service.get(ENDPOINTS.SYSTEM_ADMIN.FETCH_COLOURS);
    }
    
}