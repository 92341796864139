<template>
  <v-container fluid>
    <v-row justify="center" class="mt-4">
      <v-col cols="2" align="center">
        <v-img
          height="80"
          contain
          :src="'data:image/jpeg;base64,' + getActiveGame.logo"
        />
        <v-row class="mt-0">
          <v-spacer />
          <v-col>
            <v-btn class="primary" rounded block @click="openPlayerView"
              >Launch Player Board</v-btn
            >
          </v-col>
          <v-spacer />
        </v-row>
      </v-col>
      <v-col cols="7" class="text-body-1" justify="center">        
      </v-col>
      
    </v-row>
    <v-row justify="center">
      <v-spacer />
      <v-col cols="2" align="center">
        <v-sheet rounded elevation="2" class="mb-2">
          <div class="pt-2 text-h6">Numbers Called</div>
          <div class="pt-1 pb-4 ml-2 mr-2 numbersCalled">
            {{ getDrawnNumbersString }}
          </div>
        </v-sheet>
        <v-sheet rounded elevation="2" class="mb-2">
          <div class="pt-2 text-h6">Live Players</div>
          <div class="pt-2 pb-1">{{ livePlayers }}</div>
          <div class="pb-4">{{ closestWinner }}</div>
        </v-sheet>
        <v-sheet rounded elevation="2" class="mb-2">
          <div class="pt-1 text-h6">Winners</div>
          <div class="pt-5 pb-5">{{ winners }}</div>
          <v-divider />
          <div class="ma-2 pb-2" v-if="winningPatternOneWinners">
            <div v-for="winner in winningPatternOneWinners" :key="winner">
              {{ getWinningPatternString(0) }}: {{ winner }}
            </div>
          </div>
          <div class="ma-2 pb-2" v-if="winningPatternTwoWinners">
            <div v-for="winner in winningPatternTwoWinners" :key="winner">
              {{ getWinningPatternString(1) }}: {{ winner }}
            </div>
          </div>
          <div class="ma-2 pb-2" v-if="winningPatternFullHouseWinners">
            <div v-for="winner in winningPatternFullHouseWinners" :key="winner">
              {{ getWinningPatternString(2) }}: {{ winner }}
            </div>
          </div>
        </v-sheet>
        <v-sheet rounded elevation="2" class="mb-2" v-if="getMissedWinners.length > 0">
          <div class="pt-1 text-h6">Missed Winner</div>
          <div class="pt-5 pb-5">{{ missedWinners }}</div>
          <v-divider />
        </v-sheet>
      </v-col>
      <v-col align="center" cols="5">
        <flashboard :fontSize="'25px'"/>
        <v-text-field outlined solo label="Ad Message" v-model="adMessage" class="mt-3"/>
      </v-col>
      <v-col cols="2" align="center">
        <v-sheet rounded elevation="2" class="mb-2 noselect">
          <div class="pt-3 text-h6">Ordinal</div>
          <div class="pt-5 pb-5 text-h4 primary--text">{{ getOrdinal }}</div>
        </v-sheet>
        <v-sheet rounded elevation="2" class="mb-2 noselect">
          <div class="pt-3 text-h6">Last Number</div>
          <div class="pt-5 pb-5 text-h3 primary--text">
            {{ getCurrentNumber }}
          </div>
        </v-sheet>
        <!--v-select
          :items="items"
          label="Game/Pattern Selection"
          solo
          v-model="nextCallSelected"
        ></v-select-->
        
      </v-col>
      <v-spacer />
    </v-row>
    <v-overlay :value="getSettingUpGame">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Flashboard from "@/components/game/Flashboard.vue";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { socketURL } from '@/api/clients/bingoManager-core/baseClient.js';
import CONST from '@/const';
export default {
  name: "Caller",
  components: {
    Flashboard,
  },
  data() {
    return {
      adMessage: "",
      patternSelected: "",
      gameSelected: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentNumber",
      "getOrdinal",
      "getDrawnNumbersString",
      "getActivePages",
      "getDistanceFromWinning",
      "getGameWon",
      "getWinners",
      "getVerifiedWinners",
      "getActiveGame",
      "getCallingNumber",
      "getCheckingWinner",
      "getSettingUpGame",
      "getWinnerVerified",
      "getCurrentPattern",
      "getMissedWinners",
      "getTenantId",
      "getSelectedSchedule",
      "getScheduleList"
    ]),
    selectedSchedule() {
      return this.getScheduleList.find(
        (schedule) => schedule.id == this.getActiveGame.scheduleId
      );
    },
    livePlayers() {
      let numberOfPages = this.getActiveGame.numberOfActivePlayers ? this.getActiveGame.numberOfActivePlayers : this.getActivePages;
      if(numberOfPages == 0){
        return '';
      }
      return numberOfPages + " Active Pages";
    },
    disableCallButton() {
      return (
        this.getCheckingWinner ||
        (this.getWinnerVerified &&
          this.getCurrentPattern ==
            this.getActiveGame.winningPatterns.length - 1) ||
            this.getActiveGame.gameOwner 
      );
    },
    showNextGameButton() {
      if (
        this.getCurrentPattern <
        this.getActiveGame.winningPatterns.length - 1
      ) {
        return false;
      } else {
        return true;
      }
    },
    closestWinner() {
      let closestPlayer = this.getDistanceFromWinning[0];
      let numberOfTiedPlayers =
        this.getDistanceFromWinning.lastIndexOf(closestPlayer) + 1;
      if (numberOfTiedPlayers == 0) {
        return "Game Not Started";
      } else if (closestPlayer == 0) {
        return numberOfTiedPlayers > 1
          ? `We have ${numberOfTiedPlayers} winners!`
          : `We have a winner!`;
      } else {
        return numberOfTiedPlayers > 1
          ? ` ${numberOfTiedPlayers} players are ${closestPlayer} from winning`
          : ` ${numberOfTiedPlayers} player is ${closestPlayer} from winning`;
      }
    },
    winningPatterns() {
      let winningPatternTitles = [];
      for (const pattern of this.getActiveGame.winningPatterns) {
        if (pattern == CONST.GAME.ONE_LINE) {
          winningPatternTitles.push({"value": "ONE_LINE" , "text": "Single Line"});
        } else if (pattern == CONST.GAME.TWO_LINE) {
          winningPatternTitles.push({"value": "TWO_LINE" , "text": "Double Line"});
        } else if (pattern == CONST.GAME.FULL_HOUSE) {
          winningPatternTitles.push({"value": "FULL_HOUSE" , "text": "Full House"});
        }
      }

      return winningPatternTitles;
    },
    playableGames() {
      let playableGamesList = [];
      for (const game of this.selectedSchedule.gameOrder) { 
        let text = game.colour.attributeName  + " Game (" + game.gameOrder + ")";
         playableGamesList.push({"value": game.gameOrder , "text": text});
      }

      return playableGamesList;
    },
    currentGame() {
      let winningPatternEnum = this.getActiveGame.winningPatterns[
        this.getCurrentPattern
      ];
      if (winningPatternEnum == "ONE_LINE") {
        return "Single Line";
      } else if (winningPatternEnum == "TWO_LINE") {
        return "Double Line";
      } else if (winningPatternEnum == "FULL_HOUSE") {
        return "Full House";
      }
      return "Bingo";
    },
    winningPatternOneWinners() {
      if (this.getVerifiedWinners) {
        return this.getVerifiedWinners
          .filter((winner) => winner.winningPattern == 0)
          .filter((winner) => winner.winner != null)
          .map((winner) => winner.winner);
      } else {
        return null;
      }
    },
    winningPatternTwoWinners() {
      if (this.getVerifiedWinners) {
        return this.getVerifiedWinners
          .filter((winner) => winner.winningPattern == 1)
          .filter((winner) => winner.winner != null)
          .map((winner) => winner.winner);
      } else {
        return null;
      }
    },
    winningPatternFullHouseWinners() {
      if (this.getVerifiedWinners) {
        return this.getVerifiedWinners
          .filter((winner) => winner.winningPattern == 2)
          .filter((winner) => winner.winner != null)
          .map((winner) => winner.winner);
      } else {
        return null;
      }
    },
    winners() {
      if (!this.getWinners.length > 0) {
        return "No Winner Yet";
      } else {
        return this.getWinners.join(", ");
      }
    },
    missedWinners() {
      return "Panel(s) " + this.getMissedWinners.map(mw => mw.panel).join(", ")
    },
    buttonColour: function () {
      if (!this.getGameWon) {
        return { primary: true };
      } else {
        if (this.getWinnerVerified) {
          return { warning: true };
        } else {
          return { error: true };
        }
      }
    },
    // TODO - Make this a util
    gameString() {
      let patternString = "";
      for (const pattern of this.getActiveGame.winningPatterns) {
        if (pattern == CONST.GAME.ONE_LINE) {
          patternString += "Single Line, ";
        } else if (pattern == CONST.GAME.TWO_LINE) {
          patternString += "Double Line, ";
        } else if (pattern == CONST.GAME.FULL_HOUSE) {
          patternString += "Full House, ";
        }
      }
      let endChar = patternString.lastIndexOf(", ");
      return patternString.substring(0, endChar);
    },
  },
  methods: {
    ...mapActions([
      "callNumber",
      "nextPattern",
      "setShowVerificationBoard",
      "setCallingNumber",
      "nextGame",
      "setCurrentPattern",
      "setCheckingWinner",
      "setWinnerMissed",
      "recallNumber",
      "addCalledNumber"
    ]),
    nextGameClick() {
      this.$dialog({
        mode: "primary",
        title: "Continue to Next Game",
        message:
          "Have you verified ALL winners for this pattern before moving onto the next game?",
        action: {
          text: "Continue",
          callback: this.setupNextGame,
        },
      });
    },
    connect() {
      this.socket = new SockJS(socketURL + "play");
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect(
        {},
        () => {
          this.connected = true;
          this.stompClient.subscribe("/topic/" + this.$route.params.scheduleId + "/call", (tick) => {
            this.parseCallMessage(JSON.parse(tick.body));
          });
        },
        (error) => {
          console.log(error);
          this.connected = false;
        }
      );
    },
    parseCallMessage(message) {
      //this.currentNumber = message.drawnNumber;
      this.addCalledNumber(message.drawnNumber);
      //this.setShowVerificationBoard(false);
    },
    getWinningPatternString(pattern){
      let winningPatternEnum = this.getActiveGame.winningPatterns[pattern];
      if (winningPatternEnum == "ONE_LINE") {
        return "Single Line";
      } else if (winningPatternEnum == "TWO_LINE") {
        return "Double Line";
      } else if (winningPatternEnum == "FULL_HOUSE") {
        return "Full House";
      }
    },
    setupNextGame() {
  
      this.nextGame({
        scheduleId: this.getActiveGame.scheduleId,
        gameOrder: this.getActiveGame.gameOrder,
      });
      this.setCurrentPattern(0);
      
    },
    changePattern() {

    },
    playNextPattern() {
      if (this.getActiveGame.winningPatterns.length > this.getCurrentPattern) {
        this.nextPattern();
        this.setCurrentPattern(this.getCurrentPattern + 1);
      } else {
        this.call();
      }
    },
    checkPotentialWinner() {
      this.setCheckingWinner(false);
      this.setShowVerificationBoard(true);
    },
    callClick() {
      if (this.nextCallSelected == "Next Game") {
        this.nextGameClick();
      }
      else if (this.getGameWon && !this.getWinnerVerified) {
        this.$dialog({
          mode: "error",
          title: "Call next number?",
          message:
            "There appears to be a winner for this pattern. Are you sure you want to continue with the next call? This winner will be marked as missed.",
          action: {
            text: "Call",
            callback: this.missedWinner,
          },
          dismiss: {
            text: "Cancel",
          },
        });
      } else if (this.getGameWon && this.getWinnerVerified) {
        this.$dialog({
          mode: "warning",
          title: "Continue to next pattern?",
          message:
            "Do you want to move onto the next pattern? Any unverified winners will be marked as missed.",
          action: {
            text: "Call",
            callback: this.playNextPattern,
          },
          dismiss: {
            text: "Cancel",
          },
        });      
      } else {
        this.call();
      }
      this.nextCallSelected = "Next Number";
    },
    call() {
      this.setCallingNumber(true);
      this.callNumber();
    },
    recall() {
      this.setCallingNumber(true);
      this.recallNumber();
    },
    missedWinner() {
      this.setWinnerMissed();
      this.setCallingNumber(true);
      this.callNumber();
    },
    openPlayerView() {
      let route = this.$router.resolve({ name: "View Fixed Odds Bingo Game", params: { scheduleId: this.getActiveGame.scheduleId } });
      window.open(route.href, "_blank");
    },
    connectToStomp() {
      this.socket = new SockJS(socketURL + "play");
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect({}, () => {
        this.connected = true;
      });
    },
    updateAdMessage(message) {
      if (this.stompClient && this.stompClient.connected) {
        const msg = { adMessage: message, tenantId: localStorage.tenantId, scheduleId: this.getActiveGame.scheduleId };
        console.log(JSON.stringify(msg));
        this.stompClient.send("/app/advert", JSON.stringify(msg), {});
      }
    },
    changeGameClick() {
      this.$dialog({
          mode: "warning",
          title: "Continue to specified game?",
          message:
            "Are you sure you want to change the game?",
          action: {
            text: "Continue",
            callback: this.forceGame,
          },
          dismiss: {
            text: "Cancel",
          },
        });
    },
    nextPatternClick() {
      this.$dialog({
          mode: "warning",
          title: "Continue to specified pattern?",
          message:
            "Are you sure you want to change the winning pattern?",
          action: {
            text: "Continue",
            callback: this.forceNextPattern,
          },
          dismiss: {
            text: "Cancel",
          },
        });
    },
    forceNextPattern() {
      this.nextPattern(this.patternSelected);
      if (this.patternSelected == "ONE_LINE") {
        this.setCurrentPattern(0);
      } else if (this.patternSelected == "TWO_LINE") {
        this.setCurrentPattern(1);
      } else if (this.patternSelected == "FULL_HOUSE") {
        this.setCurrentPattern(2);
      }
      this.patternSelected = "";
      this.gameSelected = 0;
    },
    forceGame() {
      console.log(this.gameSelected);
      this.nextGame({
        scheduleId: this.getActiveGame.scheduleId,
        gameOrder: this.getActiveGame.gameOrder,
        nextGame: this.gameSelected
      });
      this.setCurrentPattern(0);
      this.patternSelected = "";
      this.gameSelected = 0;
    }
  },
  mounted() {
    this.connect();
  },
  watch: {
    adMessage(message) {
      this.updateAdMessage(message);
    },
  },
};
</script>

<style scoped>
.borders {
  border-style: solid;
  border: #0067B1;
}

.numbersCalled {
  min-height: 140px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>