var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-5"},[_c('div',{staticClass:"text-right text-h5 font-weight-medium pa-3"},[_vm._v(" Create Package ")]),_c('v-form',{ref:"form",staticClass:"ml-12 mr-12 mt-8",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"counter":30,"rules":_vm.nameRules,"id":"titleTxt","label":"Title"},model:{value:(_vm.packageTemplate.title),callback:function ($$v) {_vm.$set(_vm.packageTemplate, "title", $$v)},expression:"packageTemplate.title"}})],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-file-input',{attrs:{"label":"Logo","rules":_vm.imageRules,"id":"logoFileInput","required":"","accept":"image/png, image/jpeg, image/bmp","show-size":"","clearable":""},model:{value:(_vm.logo),callback:function ($$v) {_vm.logo=$$v},expression:"logo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.selectableTemplates,"item-value":"id","rules":_vm.templateRules,"label":"Templates To Include","clearable":"","id":"templateToIncludeSelect","multiple":"","max":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"text-color":"white","color":_vm.determineChipColour(item)}},[_vm._v(_vm._s(item.title))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('v-spacer'),(item.flyer)?_c('v-chip',{staticClass:"ml-2",attrs:{"text-color":"white","color":"success","small":""}},[_vm._v("Flyer")]):_c('span',[_vm._v(_vm._s(item.pages)+" Pages")]),_c('img',{staticClass:"logo",attrs:{"contain":"","src":'data:image/jpeg;base64,' + item.logo}})],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.children),callback:function ($$v) {_vm.children=$$v},expression:"children"}})],1)],1),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-data-table',{staticClass:"page__table",attrs:{"headers":_vm.headers,"items":_vm.playableGames,"item-key":"tableId","show-select":false,"disable-pagination":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"body",fn:function(props){return _vm._l((props.items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_c('v-text-field',{attrs:{"rules":_vm.quantityRules,"label":"Quantity","type":"number"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)])})}}])})],1)],1),_c('v-row',{staticClass:"mt-16"},[_c('v-col',{staticClass:"text-right pt-3"},[_c('v-btn',{staticClass:"mr-4",on:{"click":_vm.reset}},[_vm._v(" Clear ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }