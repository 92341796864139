<template>
  <v-card class="pa-2">
    <v-tabs class="tabs" centered>
      <v-tab>
        <v-icon left> mdi-barcode-scan </v-icon>
        Register Books
      </v-tab>
      <v-tab>
        <v-icon left> mdi-book-open-variant </v-icon>
        Print Books
      </v-tab>
      <v-tab>
        <v-icon left> mdi-arrow-collapse-down </v-icon>
        Download Books
      </v-tab>
      <v-tab>
        <v-icon left> mdi-barcode-scan </v-icon>
        Books In Play
      </v-tab>
      <v-tab>
        <v-icon left> mdi-book-open-variant </v-icon>
        Reprint Books
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <register-books-home />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <print-books-home />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <download-books-home />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <books-home />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <books-reprint />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import PrintBooksHome from "@/components/physical/PrintBooksHome.vue";
import RegisterBooksHome from "../../components/physical/RegisterBooksHome.vue";
import DownloadBooksHome from "../../components/physical/DownloadBooksHome.vue";
import BooksHome from "@/components/books/BooksTable.vue";
import BooksReprint from "@/components/books/BooksReprint.vue";

export default {
  name: "PhysicalBooks",
  components: {
    PrintBooksHome,
    RegisterBooksHome,
    DownloadBooksHome,
    BooksHome,
    BooksReprint
  },
};
</script>

<style
    RegisterBooksHomeang="scss" scoped>
div.tabs [role="tab"] {
  justify-content: flex-start;
}
</style>