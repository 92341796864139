import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import NotificationsPlugin from '@/plugins/notifications/install';
import VueJsonToCsv from 'vue-json-to-csv';

Vue.use(NotificationsPlugin);
Vue.use(VueJsonToCsv);
Vue.component('vueJsonToCsv', VueJsonToCsv)
Vue.config.productionTip = false

var operationState = { current: "Adding"}; 
var lastScannedBarcode = "";

export const EventBus = new Vue();

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

document.onkeypress = onGlobalKeyPressd;

function onGlobalKeyPressd(e) {
  console.log(e);

  var charCode = (typeof e.which == "number") ? e.which : e.keyCode;

  if (charCode != 13) {
    lastScannedBarcode += String.fromCharCode(charCode);
  }
  else {
    var lastCode = lastScannedBarcode;
    if (lastCode == "CommandAdd") {
      operationState.current = "Adding";
    }
    else if (lastCode == "CommandAdd") {
      operationState.current = "Deleting";
    }
    else {
      console.log(lastScannedBarcode);
      EventBus.$emit("BARCODE_SCANNED", lastScannedBarcode);
    }

    lastScannedBarcode = "";

  }  
}
