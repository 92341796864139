import Users from '@/api/clients/bingoManager-core/users.js';
import CONST from '@/const/index.js'

const getUsersDefaultState = () => {
    return {
        users: []
    }
}
const state = getUsersDefaultState();

const getters = {
    getUserList: state => state.users
}

const actions = {
    async saveUser({ commit, dispatch }, user) {
        try {
            let result = await Users.save(user);
            if(result && result.data){ 
                commit('addNewUser', result.data);
            }
            this._vm.$snackbar({
                message: ("User Saved"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async updateUser({ commit, dispatch }, user) {
        try {
            await Users.update(user);
            commit('updateUser', user);
            this._vm.$snackbar({
                message: ("User Updated"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async deleteUser({ commit, dispatch }, user) {
        try {
            await Users.delete(user);
            commit('deleteUser', user);
            this._vm.$snackbar({
                message: ("User Deleted"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async fetchUsers({ commit, dispatch }) {
        try {
            const result = await Users.fetchAll();
            commit('addUsers', result.data)
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again."),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    }
}

const mutations = {
    addNewUser(state, user) {
        state.users.push(user);
    },
    updateUser(state, user) {
        var foundIndex = state.users.findIndex(x => x.id == user.id);
        Object.assign(state.users[foundIndex], user);
    },
    deleteUser(state, user) {
        var foundIndex = state.users.findIndex(x => x.id == user.id);
        state.users.splice(foundIndex,1)
    },
    addUsers(state, templates) {
        state.users = templates
    },
    resetUsers (state) {
        // acquire initial state
        const s = getUsersDefaultState()
        Object.keys(s).forEach(key => {
          state[key] = s[key]
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}