<template>
  <v-card class="ma-2 mb-5 card">
    <div class="text-right text-h5 font-weight-medium pt-3 pr-3">
      Register Books
    </div>
    <span>
      <v-btn small class="accent ml-6" rounded @click="returnToBooks"
        ><v-icon small>mdi-arrow-left</v-icon>Books</v-btn
      ></span
    >
    <v-autocomplete
      class="ma-5"
      clearable
      :persistent-hint="true"
      solo
      hide-details
      :label="'Select a schedule'"
      v-model="selectedSchedule"
      :items="getScheduleList"
      :item-value="(obj) => obj"
      :item-text="
        (obj) => obj.title + ' - ' + obj.date + ' , ' + obj.sessionType
      "
    ></v-autocomplete>
    <div v-if="selectedSchedule">
      <v-card class="pa-2">
        <v-row>
          <v-autocomplete
            v-show="!addCustomer"
            class="ml-6 mr-6 mt-6 mb-2"
            hide-details
            :return-object="false"
            v-model="selectedCustomer"
            :items="getCustomerList"
            clearable
            :persistent-hint="true"
            solo
            :item-text="
              (obj) =>
                obj.displayName +
                ', ' +
                obj.firstName +
                ' ' +
                obj.lastName +
                ', ' +
                obj.email +
                ', ' +
                obj.barCodeId
            "
            :item-value="(obj) => obj"
            :label="'Find a customer'"
          ></v-autocomplete>
        </v-row>
        <v-row align="end" class="mb-2">
          <v-spacer />
          <v-col align="end" cols="2" v-if="addCustomer" justify="end">
            <v-btn @click="addCustomer = false" class="mt-5">Cancel</v-btn>
          </v-col>
          <v-col cols="2" v-if="!selectedCustomer && !addCustomer">
            <v-text-field
              readonly
              solo
              flat
              dense
              hide-details="true"
              class="caption pa-0"
              value="Cannot find customer?"
            />
            <v-btn class="accent" @click="showAddCustomer">
              Add New Customer
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="addCustomer">
          <v-col>
            <customer-add @customerSaved="setCustomer"/>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-row class="mt-2">
      <v-col class="text-right pt-3">
        <v-btn color="primary" class="mr-4" v-if="showRegisterButton" @click="register"> Register ({{ getSelectedBooks.length }})</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomerAdd from "../customers/CustomerAdd.vue";

export default {
  components: { CustomerAdd },
  name: "RegisterBooks",
  created() {
    this.fetchUpcoming();
    this.fetchCustomers();
  },
  data() {
    return {
      selectedSchedule: null,
      selectedCustomer: null,
      addCustomer: false,
      headers: [
        {
          text: "Order",
          value: "id",
        },
        {
          text: "Game Colour",
          align: "start",
          value: "colour",
          width: "45%",
        },
        {
          text: "One Line",
          value: "winningPatterns.ONE_LINE",
        },
        {
          text: "Two Line",
          value: "winningPatterns.TWO_LINE",
        },
        {
          text: "Full House",
          value: "winningPatterns.FULL_HOUSE",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["fetchUpcoming", "fetchCustomers", "registerBooks"]),
    register() {
      if (this.selectedSchedule && this.selectedCustomer) {
        this.registerBooks({ schedule: this.selectedSchedule, customer: this.selectedCustomer, books: this.getSelectedBooks});
        this.returnToBooks();
      }
    },
    returnToBooks() {
      this.$emit("returnToBooks");
    },
    setCustomer(customer){
        this.addCustomer = false;
        this.selectedCustomer = customer;
    },
    showAddCustomer(){
        this.addCustomer = true;
        this.selectedCustomer = null;
    }
  },
  computed: {
    ...mapGetters(["getScheduleList", "getCustomerList", "getSelectedBooks"]),
    showRegisterButton(){
        return this.selectedCustomer
    }
  },
};
</script>

<style scoped>
.square {
  width: 1.25vw;
  height: 1.25vw;
  min-width: 10px;
  min-height: 10px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 0.5px;
}

.inline-block {
  display: inline-block;
}
</style>