import ENDPOINTS from '@/api/endpoints/bingoManager-core/endpoints.js';
import { service } from '@/api/clients/bingoManager-core/baseClient.js';

export default {
    printBooks(bookQuantities, template) {
        const formData = new FormData();
        formData.append('singleBooks', bookQuantities.singleBooks);
        formData.append('doubleBooks', bookQuantities.doubleBooks);
        formData.append('productTemplateDto', JSON.stringify(template));
        return service.post(ENDPOINTS.BOOKS.PRINT_BOOKS, formData);
    },
    fetchPreprintedBooks(){
        return service.get(ENDPOINTS.BOOKS.FETCH_PREPRINTED_BOOKS);
    },
    fetchBookById(bookId){
        return service.get(ENDPOINTS.BOOKS.FETCH_BOOK_BY_ID, {
            params: {
                bookId
            }
        });
    },
    fetchBooksById(bookIds){
        return service.get(ENDPOINTS.BOOKS.FETCH_BOOKS_BY_ID, {
             params: {
                bookIds
             }
        });
    },
    downloadBooks(printRequestAuditId){
        return service.get(ENDPOINTS.BOOKS.DOWNLOAD_BOOKS, {
            params: {
                printRequestAuditId
             }
        });
    },
    fetchDownloadableBooks(){
        return service.get(ENDPOINTS.BOOKS.FETCH_DOWNLOADABLE_BOOKS);
    },
    reprintBook(bookId){
        return service.get(ENDPOINTS.BOOKS.REPRINT_BOOK, {
            params: {
                bookId: bookId
            }
        })
    },
    fetchBooksForScheduleId(scheduleId){
        return service.get(ENDPOINTS.BOOKS.FETCH_BOOKS_FOR_SCHEDULE, {
            params: {
                scheduleId: scheduleId
            }
        });
    },
    registerBooks(registration){
        const formData = new FormData();
        formData.append('bookIds', registration.books);
        formData.append('customerId', registration.customer.id);
        formData.append('scheduleId', registration.schedule.id);
        return service.post(ENDPOINTS.BOOKS.REGISTER_BOOK, formData)
    }
}