<template>
    <div v-show="showScheduleDetails">
      <v-card class="mx-2">
        <v-card-text class="mb-4">
        <div
          v-for="product in getProductList"
          :key="product.id"
        >
          <v-data-table 
            :headers="headers"
            :items="product.winningPatterns"
            class="elevation-2"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat class="primary white--text">
                <v-row>
                  <v-col cols="6" md="2">
                    <v-toolbar-title class="ml-2">{{
                      product.productTemplateDto.title
                    }}</v-toolbar-title>
                  </v-col>
                  <v-spacer />
                  <v-col cols="2">
                    <v-row>
                      <v-tooltip bottom v-if="$vuetify.breakpoint.smAndUp">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            dark
                            :retain-focus-on-click="false"
                            v-bind="attrs"
                            v-on="on"
                            @click="copyText(product.id)"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span
                          >Product Id: {{ product.id }} (Click to copy)</span
                        >
                      </v-tooltip>
                      <v-col class="pr-0">
                        <v-text-field
                          background-color="primary"
                          solo
                          dense
                          flat
                          hide-details
                          readonly
                          reverse
                          dark
                          value="Price"
                      /></v-col>
                      <v-col class="pl-0 mt-1"
                        ><currency-field
                          solo
                          dense
                          hide-details
                          dark
                          v-model="product.price"
                      /></v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                                  <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                   @click="addLineItem(product)"
                                  >
                                  New Line Item
                                </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.intOne`]="{ item }">
              <v-text-field
                  v-model="item.intOne"
                  type="number"
                ></v-text-field>
            </template>
            <template v-slot:[`item.intTwo`]="{ item }">
              <v-text-field
                  v-model="item.intTwo"
                  type="number"
                ></v-text-field>
            </template>
            <template v-slot:[`item.lineItem`]="{ item }">
              <div> {{item.lineItem == null? lineCounter++ : item.lineItem}}</div>
            </template>
            <template v-slot:[`item.winningPattern`]="{ item }">
              <v-select
                label="Winning Pattern"
                :items="['ONE_LINE', 'TWO_LINE', 'FULL_HOUSE', 'JACKPOT']"
                v-model="item.winningPattern"
                ></v-select>
            </template>
          </v-data-table>
        </div>
        <v-row class="mt-2">
          <v-col class="text-right pt-3">
            <v-btn
              color="primary"
              class="mr-4"
              @click="saveProductDetails(getProductList)"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CurrencyField from "@/components/common/CurrencyField.vue";

export default {
  components: { CurrencyField },
  name: "FOBProducts",
  created() {
    this.fetchUpcoming();
  },
  mounted() {
    let scheduleId = this.$route.params.scheduleId;
    if (scheduleId) {
      this.selectedSchedule = this.getScheduleList.find((schedule) => {
        return schedule.id == scheduleId;
      });
      this.fetchProductsForSchedule(this.selectedSchedule.id);
    }
    if (this.getSelectedSchedule) {
      this.selectedSchedule = this.getScheduleList.find((schedule) => {
        return schedule.id == this.getSelectedSchedule;
      });
      this.fetchProductsForSchedule(this.getSelectedSchedule);
    }
    this.setCurrentPage('Edit Schedule')
  },
  data() {
    return {
      selectedSchedule: null,
      lineItem: 1,
      lineCounter: 1,
      headers: [
        // {
        //   text: "Line",
        //   value: "lineItem",
        //   width: "10%"
        // },
        {
          text: "Winning Pattern",
          value: "winningPattern",
          width: "15%"
        },
        {
          text: "Ordinal Number",
          value: "intOne",
          width: "10%"
        },
        {
          text: "Payout",
          value: "intTwo",
        }
      ],
    };
  },
  methods: {
    ...mapActions([
      "fetchUpcoming",
      "fetchProductsForSchedule",
      "saveProductDetails",
      "setCurrentPage"
    ]),
    gameSupportsWinningPattern(pattern, product) {
      if (product.productTemplateDto.type == "BINGO") {
        return false;
      }
      if (product.productTemplateDto.type == "LOTTO") {
        return true;
      }
    },
    addLineItem(product) {
      product.winningPatterns.push({winningPattern: 0, intOne: 0, intTwo: 0});
    },
    selectSchedule(schedule) {
      if (schedule && schedule.id) {
        // TODO - Combobox triggering twice - believe the change is triggered twice, needs investigation
        this.fetchProductsForSchedule(schedule.id);
      }
    },
    toggleWinningPattern(item, pattern) {
      if (item.winningPatterns.includes(pattern)) {
        item.winningPatterns = item.winningPatterns.filter(function (
          winningPattern
        ) {
          return winningPattern !== pattern;
        });
      } else {
        item.winningPatterns.push(pattern);
      }
    },
    toggleWinningPatternCount(item, pattern) {
      if (item.winningPatternCounts.includes(pattern)) {
        item.winningPatternCounts = item.winningPatternCounts.filter(function (
          winningPattern
        ) {
          return winningPattern !== pattern;
        });
      } else {
        item.winningPatternCounts.push(pattern);
      }
    },
    calculateOrder(item) {
      return (
        this.selectedSchedule.gameOrder
          .map(function (game) {
            return game.id;
          })
          .indexOf(item.id) + 1
      );
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
    },
  },
  computed: {
    ...mapGetters(["getScheduleList", "getProductList", "getSelectedSchedule", ]),
    showScheduleDetails() {
      return this.selectedSchedule != null;
    },
  },
};
</script>

<style scoped>
.square {
  width: 1.25vw;
  height: 1.25vw;
  min-width: 10px;
  min-height: 10px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 0.5px;
}

.inline-block {
  display: inline-block;
}
</style>