<template>
  <div>
    <v-container>
      <v-card class="mt-2">
        <div class="text-right text-h5 font-weight-medium mr-1 mt-1">
          Upcoming Schedules
        </div>
        <span class="ma-4 font-weight-medium">Select Schedule</span>
        <v-row>
          <v-col>
            <v-sheet class="mr-3">
              <v-slide-group
                :items="getScheduleList"
                class="pa-4"
                center-active
                show-arrows
                v-model="selectedSchedule"
              >
                <v-slide-item
                  v-for="item in getScheduleList"
                  :key="item.id"
                  v-slot="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'accent lighten-1' : 'white'"
                    class="ma-4 text-color"
                    height="225"
                    width="300"
                    @click="toggle"
                    style="position: relative"
                  >
                    <div style="height: 100%">
                      <v-card-title class="schedule-card-title">
                        <div class="title font-weight-light">
                          {{ item.title }}
                        </div>
                      </v-card-title>

                      <v-card-text
                        class="font-weight-light mt-4"
                        style="height: 50%"
                      >
                        <v-row
                          v-for="template in item.selectedTemplates"
                          :key="template"
                          class="ma-1"
                        >
                          {{ template }}
                        </v-row>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-text class="pa-2 schedule-card-footer">
                        {{ item.sessionType }}
                      </v-card-text>
                    </div>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container>
      <v-card class="mt-5">
        <v-sheet class="mr-3" v-if="showSelectedSchedule">
          <v-slide-group
            :items="viewableSchedule.gameOrder"
            class="pa-4"
            center-active
            show-arrows
            v-model="selectedGame"
          >
            <v-slide-item
              v-for="item in viewableSchedule.gameOrder"
              :key="item.id"
            >
              <v-card
                :color="'white'"
                class="ma-4 text-color"
                height="265"
                width="300"
                style="position: relative"
              >
                <div style="height: 80%">
                  <v-card-title
                    :style="[{ background: item.colour.attributeValue }]"
                    class="white--text"
                  >
                    <div class="title font-weight-light">
                      {{ item.colour.attributeName }}
                    </div>
                  </v-card-title>

                  <v-card-text
                    class="font-weight-light mt-4 mb-2"
                    style="height: 55%"
                  >
                    <v-row
                      class="ma-4 font-weight-medium"
                    >
                      {{ gameString(item.winningPatterns) }}
                    </v-row>
                  </v-card-text>

                  <v-divider class="pa-2"></v-divider>
                </div>
                <div class="text-center">
                  <v-img
                    height="50"
                    contain
                    :src="'data:image/jpeg;base64,' + item.logo"
                  />
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <div class="text-right">
            <v-btn class="primary ma-2" @click="startGame(viewableSchedule.id)"
              >Start</v-btn
            >
          </div>
        </v-sheet>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CONST from "@/const";
export default {
  name: "SchedulesOld",
  mounted(){
    this.setCurrentPage("Schedules")
  },
  computed: {
    ...mapGetters(["getScheduleList"]),
    showSelectedSchedule() {
      if (this.selectedSchedule || this.selectedSchedule == 0) {
        return true;
      } else {
        return false;
      }
    },
    showSchedule() {
      return this.showSelectedSchedule ? 0 : -1;
    },
    viewableSchedule() {
      return this.getScheduleList[this.selectedSchedule];
    },
  },
  methods: {
    ...mapActions(["fetchUpcoming", "fetchStatus", "setCurrentPage"]),
    goToAddTemplate() {
      this.$router.push("/template/designer");
    },
    editTemplate() {
      this.$router.push("/template/designer");
    },
    gameHeader(item) {
      return item.flyer ? "background-color: secondary" : "schedule-card-title";
    },
    startGame(scheduleId) {
      this.fetchStatus(scheduleId);
      this.$router.push({ path: `/start/${scheduleId}` });
    },
    getAllowedDates(val) {
      return (
        this.getScheduledDates
          .map((scheduleDate) => scheduleDate.substr(0, 10))
          .indexOf(val) !== -1
      );
    },
    gameString(winningPatterns) {
      let patternString = "";
      for (const pattern of winningPatterns) {
        if (pattern == CONST.GAME.ONE_LINE) {
          patternString += "Single Line, ";
        } else if (pattern == CONST.GAME.TWO_LINE) {
          patternString += "Double Line, ";
        } else if (pattern == CONST.GAME.FULL_HOUSE) {
          patternString += "Full House, ";
        }
      }
      let endChar = patternString.lastIndexOf(", ");
      return patternString.substring(0, endChar);
    },
  },
  created() {
    this.fetchUpcoming();
  },
  data: () => ({
    valid: false,
    schedule: {
      title: "",
      sessionType: "",
      date: "",
      time: "",
    },
    selectedSchedule: null,
    selectedGame: null,
    panel: [0],
  }),
};
</script>

<style>
#scheduler-viewer.v-date-picker-table {
  height: 200px;
  width: 280px;
  line-height: 1;
}

#scheduler-viewer.v-picker--landscape .v-picker__title {
  width: 140px;
}

.schedule-card-title {
  background-color: #0067B1;
  color: white;
}

.schedule-card-footer {
  background-color: #FFC425;
  color: white;
}
</style>