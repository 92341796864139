<template>
  <v-data-table
    :headers="headers"
    :items="getTemplateList"
    sort-by="calories"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Templates</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-col cols="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              id="templateSearchBtn"
              hide-details
            ></v-text-field
          ></v-col>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card class="card">
              <v-card-title>
                <span class="headline">Edit Template</span>
              </v-card-title>
              <v-card-text>
                <template-add />
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to archive this template?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:[`item.packaged`]="{ item }">
      <v-icon v-if="item.packaged">mdi-check</v-icon>
    </template>
    <template v-slot:[`item.flyer`]="{ item }">
      <v-icon v-if="item.flyer">mdi-check</v-icon>
    </template>
    <template v-slot:[`item.allowDoubles`]="{ item }">
      <v-icon v-if="item.allowDoubles">mdi-check</v-icon>
    </template>
    <template v-slot:[`item.logo`]="{ item }">
      <img v-if="item.logo" contain class="logo" :src="'data:image/jpeg;base64,' + item.logo" />
    </template>
    <template v-slot:[`item.gameColours`]="{ item }">
      <div class="fluid-container">
        <div
          v-for="colour in item.gameColours"
          :key="colour.id"
          :style="{ background: colour.attributeValue }"
          class="square mr-1"
        />
      </div>
      <div class="fluid-container">

        <v-chip v-for="child in item.children" :key="child.id"
                          text-color="white"
                          color="success"
                          small
                          class="ml-2"
                          >{{ child.title }} ({{child.quantity}})</v-chip>
        
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small  class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import TemplateAdd from "@/components/templates/TemplateAdd.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TemplateList",
  components: {
    TemplateAdd,
  },
  computed: {
    ...mapGetters(["getTemplateList"]),
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [
      {
        text: "Title",
        align: "start",
        value: "title",
        width: "25%",
      },
      { text: "Package", value: "packaged" },
      { text: "Flyer", value: "flyer" },
      { text: "Doubles", value: "allowDoubles"},
      {
        text: "Pages",
        align: "start",
        value: "pages",
      },
      { text: "Details", value: "gameColours", width: "15%" },
      { text: "Logo", value: "logo", align: "center" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.fetchTemplates();
  },

  methods: {
    ...mapActions(["fetchTemplates","archiveTemplate"]),

    editItem(item) {
      this.editedIndex = this.getTemplateList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.getTemplateList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // TODO - use vuex
      // this.desserts.splice(this.editedIndex, 1);
      this.archiveTemplate(this.editedItem.id);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      // TODO - Use vuex
      // if (this.editedIndex > -1) {
      //   Object.assign(this.desserts[this.editedIndex], this.editedItem);
      // } else {
      //   this.desserts.push(this.editedItem);
      // }
      // this.close();
    },
  },
};
</script>

<style scoped>
.logo {
  max-height: 25px;
}

.square {
  width: 1.25vw;
  height: 1.25vw;
  min-width: 10px;
  min-height: 10px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 0.5px;
}

.fluid-container {
  display: flex;
}
</style>