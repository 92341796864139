var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.getBooks,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-toolbar-title',[_vm._v("Book Sales")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"pt-1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.updateBookBreakdown()}}},[_c('v-icon',[_vm._v("mdi-cached")])],1),_c('vue-json-to-csv',{attrs:{"json-data":_vm.getBooks}},[_c('button',{staticClass:"pt-1",attrs:{"small":"","icon":""}},[_c('v-icon',[_vm._v("mdi-arrow-collapse-down")])],1)]),_c('v-spacer'),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.updateTableOnDateChange(_vm.dates)}}},[_vm._v(" OK ")])],1)],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date).toDateString())+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(( item.price ).toLocaleString("en-IE", { style: "currency", currency: "EUR", }))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }