import Auth from '@/api/clients/bingoManager-core/auth.js';
import CONST from '@/const/index.js'
import router from '@/router'

const getAuthDefaultState = () => {
    return {
        token: null,
        requiresPasswordReset: false,
        email: null,
        id: null,
        refreshToken: null,
        roles: [],
        userName: null
    }
}
const state = getAuthDefaultState();

const getters = {
    getToken: state => {
        let storedToken = localStorage.getItem('user');
        if(state.token || storedToken){
            return state.token;
        } else {
           return null;
        } 
    },
    getRequiresPasswordReset: state => state.requiresPasswordReset,
    getRefreshToken: state => state.refreshToken
}

const actions = {
    async login({ commit, dispatch }, user) {
        try {
            let response = await Auth.login(user);
            if(response.data.passwordResetRequired === true){
                commit('updateRequiresPasswordReset', true);
            }
            if (response.data.token) {
                localStorage.setItem('token', JSON.stringify(response.data.token));
                commit('updateLocalStorageToken', JSON.stringify(response.data))
            }
            commit('updateUserInfo', response.data);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async forgotPassword( { dispatch }, userName) {
        try {
            let response = await Auth.forgotPassword(userName);
            
            if (response.data) {
                this._vm.$snackbar({
                    message: ("If this username exists, a password reset mail will be sent to the related email address"),
                    mode: CONST.THEME.COLOURS.WARNING
                }, dispatch);
            }
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async changePassword ({ commit, dispatch }, passwordDetails) {
        try {
            let response = await Auth.changePassword(passwordDetails);
            router.push("/");
            if (response.data) {
                commit('updateRequiresPasswordReset', false);
                this._vm.$snackbar({
                    message: ("Your password has been changed successfully"),
                    mode: CONST.THEME.COLOURS.SUCCESS
                }, dispatch);
            }
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async resetPassword ({ dispatch }, passwordDetails) {
        try {
            let response = await Auth.resetPassword(passwordDetails);
            if (response.data) {
                this._vm.$snackbar({
                    message: ("Your password has been changed successfully"),
                    mode: CONST.THEME.COLOURS.SUCCESS
                }, dispatch);
            }
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    logout({ commit }) {
        commit("resetAuth"); 
        commit("resetSchedule"); 
        commit("resetBooks"); 
        commit("resetCaller"); 
        commit("resetCustomer"); 
        commit("resetProducts"); 
        commit("resetUsers"); 
        commit("resetTemplates"); 
        commit('updateLocalStorageToken', null);
        localStorage.removeItem('user');
        router.push("/login")
    }
}

const mutations = {
    updateLocalStorageToken(state, token) {
        state.token = token;
    },
    updateRequiresPasswordReset(state, required) {
        state.requiresPasswordReset = required;
    },
    updateUserInfo(state, data) {
        state.email = data.email;
        state.id = data.id;
        state.refreshToken = data.refreshToken;
        state.roles = data.roles;
        state.userName = data.userName;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}