<template>
  <v-data-table
    :headers="headers"
    :items="selectableTemplates"
    sort-by="calories"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Templates</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-col cols="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field
          ></v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:[`item.flyer`]="{ item }">
      <v-icon v-if="item.flyer">mdi-check</v-icon>
    </template>
    <template v-slot:[`item.allowDoubles`]="{ item }">
      <v-icon v-if="item.allowDoubles">mdi-check</v-icon>
    </template>
    <template v-slot:[`item.logo`]="{ item }">
      <img contain class="logo" :src="'data:image/jpeg;base64,' + item.logo" />
    </template>
    <template v-slot:[`item.gameColours`]="{ item }">
      <div class="fluid-container">
        <div
          v-for="colour in item.gameColours"
          :key="colour.id"
          :style="{ background: colour.attributeValue }"
          class="square mr-1"
        />
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="success" small rounded @click="selectTemplate(item)">Print</v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PrintBooksTemplateTable",
  computed: {
    ...mapGetters(["getTemplateList"]),
    selectableTemplates: {
            get: function () {
                let activeTemplates = this.getTemplateList.filter((template) =>
                    !template.packaged
                );
                let selectableTemplates = [].concat(activeTemplates);
                return selectableTemplates;
            }
        }
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "Title",
        align: "start",
        value: "title",
        width: "25%",
      },
      {
        text: "Pages",
        align: "start",
        value: "pages",
      },
      { text: "Perm", value: "perm" },
      { text: "Flyer", value: "flyer" },
      { text: "Doubles", value: "allowDoubles"},
      { text: "Colours", value: "gameColours", width: "15%" },
      { text: "Logo", value: "logo", align: "center" },
      { text: "Actions", value: "actions", sortable: false },
    ]
  }),

  created() {
    this.fetchTemplates();
  },
  methods: {
    ...mapActions(["fetchTemplates", "setSelectedTemplate"]),
    selectTemplate(template){
      this.setSelectedTemplate(template);
      this.$emit("templateSelected");
    }
  },
};
</script>

<style scoped>
.logo {
  max-height: 25px;
}

.square {
  width: 1.25vw;
  height: 1.25vw;
  min-width: 10px;
  min-height: 10px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 0.5px;
}

.fluid-container {
  display: flex;
}
</style>