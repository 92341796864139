import Products from '@/api/clients/bingoManager-core/products.js';
import CONST from '@/const/index.js'
import router from '@/router'

const getProductsDefaultState = () => {
    return {
        savedProducts: [],
        productOrderPurchased: null
    }
}
const state = getProductsDefaultState();

const getters = {
    getProductList: state => state.savedProducts,
    getProductsForSale: state => {
        let products = []
        for(const product of state.savedProducts){
            if(product.doublePrice){
                let double = Object.assign({}, product);
                double.price = product.doublePrice;
                product.doublePrice = null;
                products.push(double);
            }
            products.push(product);
        }
        return products
    },
    getProductOrderPurchased: state => state.productOrderPurchased
}

const actions = {
    async saveProductDetails({ dispatch }, products) {
        try {
            for (let i = 0; i < products.length; i++) {
                let product = products[i];
                product.productTemplateDto.logo = null;
                product.scheduleDto = null;
                for (let j = 0; j < product.games.length; j++) {
                    let game = product.games[j];
                    game.logo = null;
                }
            }
            await Products.saveProductDetails(products);
            this._vm.$snackbar({
                message: ("Products updated"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
            router.push({path:"/scheduler"});
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async saveProduct({ commit, dispatch }, product) {
        try {
            await Products.save(product);
            commit('addNewProduct', product);
            this._vm.$snackbar({
                message: ("Product saved"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        }catch(err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            });
        }
    },
    async fetchProducts({ commit, dispatch }) {
        try {
            const result = await Products.fetchAll();
            commit('addProducts', result.data)
        }catch (err) {
            this._vm.$snackbar({
                message: ("Cannot retreive products. If problem persists, please contact support"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);  
        }
    },
    async fetchProductsForSchedule({ commit, dispatch }, scheduleId) {
        try {
            const result = await Products.fetchAllScheduleId(scheduleId);
            commit('addProducts', result.data);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Cannot retreive products. If problem persists, please contact support"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);  
        }
    },
    async posPurchase({ commit, dispatch }, products) {
        try {
            const result = await Products.posPurchase(products);
            commit('setProductOrderPurchased', result.data);
            this._vm.$snackbar({
                message: ("Products bought."),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
}

const mutations = {
    addNewProduct(state, product) {
        state.savedProducts.push(product);
    },
    addProducts(state, products) {
        state.savedProducts = products
    },
    setProductOrderPurchased(state, productOrderPurchased) {
        state.productOrderPurchased = productOrderPurchased
    },
    resetProducts (state) {
        // acquire initial state
        const s = getProductsDefaultState()
        Object.keys(s).forEach(key => {
          state[key] = s[key]
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}