<template>
  <v-carousel v-model="model" light hide-delimiters :show-arrows="false" height="auto">
    <v-carousel-item>
      <print-books-template-table @templateSelected="showPrintOptions"/>
    </v-carousel-item>
    <v-carousel-item> 
        <print-books-options @returnToTemplates="showTemplates" ref="printOptions"/>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import PrintBooksOptions from './PrintBooksOptions.vue';
import PrintBooksTemplateTable from "./PrintBooksTemplateTable.vue";
export default {
  components: { PrintBooksTemplateTable, PrintBooksOptions },
  name: "PrintBooksHome",
  data() {
    return {
      model: 0,
    };
  },
  methods: {
      showPrintOptions(){
          this.model = 1;
      },
      showTemplates(){
        this.$refs.printOptions.resetValues();
        this.model = 0;
      }
  }
};
</script>