<template>
  <v-data-table
    :headers="headers"
    :items="getBooksForSchedule"
    sort-by="calories"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-row>
          <v-toolbar-title>Printed Books</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-col cols="2" class="pb-0">
            <v-select
              :items="getScheduleList"
              v-model="selectedSchedule"
              return-object
              id="scheduleBtn"
              label="Schedule"
              item-text="title"
            ></v-select>
          </v-col>
          <v-col cols="2" class="pb-0">
            <v-select
              :disabled="getSelectedBookSchedule == null"
              :items="gameList"
              return-object
              label="Game"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="3" class="pt-0 pb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              id="searchBtn"
              single-line
              hide-details
            ></v-text-field
          ></v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:[`item.dateCreated`]="{ item }">
      <span>{{ new Date(item.dateCreated).toLocaleString() }}</span>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "BooksTable",
  computed: {
    ...mapGetters([
      "getScheduleList",
      "getSelectedBookSchedule",
      "getBooksForSchedule",
    ]),
    gameList() {
      if (this.selectedSchedule) {
        return this.selectedSchedule.selectedTemplates;
      } else {
        return [];
      }
    },
  },
  data: () => ({
    search: "",
    selectedSchedule: null,
    headers: [
      {
        text: "Book ID",
        value: "id",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Pages",
        value: "pages",
      },
      { text: "Player", value: "player.displayName" },
      { text: "Date created", value: "dateCreated" },
    ],
  }),
  mounted() {
    this.setSelectedSchedule(null);
  },
  created() {
    this.fetchSchedules();
  },
  methods: {
    ...mapActions([
      "fetchSchedules",
      "setSelectedSchedule",
      "fetchPreprintedBooks",
      "fetchBooksForSchedule",
      "setSelectedSchedule",
    ]),
  },
  watch: {
    selectedSchedule(schedule) {
      this.fetchBooksForSchedule(schedule.id);
    },
  },
};
</script>

<style scoped>
.logo {
  max-height: 25px;
}

.square {
  width: 1.25vw;
  height: 1.25vw;
  min-width: 10px;
  min-height: 10px;
  border: rgb(73, 73, 73);
  border-style: solid;
  border-radius: 0.5px;
}

.fluid-container {
  display: flex;
}
</style>