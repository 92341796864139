import TemplateDesigner from '@/api/clients/bingoManager-core/templateDesigner.js';
import CONST from '@/const/index.js'

const getTemplateDesignerDefaultState = () => {
    return {
        savedTemplates: []
    }
}
const state = getTemplateDesignerDefaultState();

const getters = {
    getTemplateList: state => state.savedTemplates
}

const actions = {
    async saveTemplate({ dispatch }, { template, logo }) {
        try {
            const response = await TemplateDesigner.save(template, logo);
            const vue = this;
            if (response.data != null) {
                vue._vm.$snackbar({
                    message: ("Template Saved"),
                    mode: CONST.THEME.COLOURS.SUCCESS
                }, dispatch)
                dispatch('fetchTemplates');
            }
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async fetchTemplates({ commit, dispatch }) {
        try {
            const result = await TemplateDesigner.fetchAll();
            commit('addTemplates', result.data)
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async archiveTemplate({ commit, dispatch }, templateId) {
        try {
            await TemplateDesigner.archive(templateId);
            commit('deleteTemplate', templateId);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
}

const mutations = {
    addNewTemplate(state, template) {
        state.savedTemplates.push(template);
    },
    addTemplates(state, templates) {
        state.savedTemplates = templates
    },
    deleteTemplate(state, templateId) {
        var foundIndex = state.savedTemplates.findIndex(x => x.id == templateId);
        state.savedTemplates.splice(foundIndex,1)
    },
    resetTemplates (state) {
        // acquire initial state
        const s = getTemplateDesignerDefaultState()
        Object.keys(s).forEach(key => {
          state[key] = s[key]
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}