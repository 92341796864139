import Scheduler from '@/api/clients/bingoManager-core/scheduler.js';
import CONST from '@/const'
import router from '@/router'

const getSchedulerDefaultState = () => {
    return {
        savedSchedules: [],
        scheduledDates: [],
        selectedSchedule: null,
        adMessage: null
    }
}
const state = getSchedulerDefaultState();

const getters = {
    getScheduleList: state => state.savedSchedules,
    getScheduledDates: state => state.scheduledDates,
    getAdMessage: state => state.adMessage,
    getSelectedSchedule: state => state.selectedSchedule
}

const actions = {
    async saveSchedule({ commit, dispatch }, schedule) {
        try {
            let response = await Scheduler.save(schedule);
            if(response.data){
                schedule.id = response.data;
                commit('addNewSchedule', schedule);
                commit('setSelectedSchedule', response.data)
                let path = "/products" + "/" + schedule.type + "/" + response.data;
                router.push({path:path});
            }
            this._vm.$snackbar({
                message: ("Schedule Saved"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async fetchSchedules({ commit, dispatch }) {
        try {
            const result = await Scheduler.fetchAll();
            commit('addSchedules', result.data)
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async fetchScheduleDates({ commit, dispatch }) {
        try {
            const result = await Scheduler.fetchAllDates();
            commit('addScheduleDates', result.data)
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async fetchSchedulesForDate({ commit, dispatch }, date) {
        try {
            const result = await Scheduler.fetchAllDateForDate(date);
            commit('addSchedules', result.data);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async cancelSchedule({ commit, dispatch }, scheduleId) {
        try {
            await Scheduler.cancelSchedule(scheduleId);
            commit('deleteSchedule', scheduleId);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async fetchUpcoming({ commit, dispatch }) {
        try {
            const result = await Scheduler.fetchUpcoming();
            commit('addSchedules', result.data);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async fetchStatus({ commit, dispatch }, scheduleId) {
        try {
            const result = await Scheduler.fetchStatus(scheduleId);
            commit('updateAdMessage', result.data.adMessage)
            dispatch("updateGameState", result.data, { root: true });
        } catch (err) {
            console.log("err = " + err);
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async saveBroadcast({ dispatch }, schedule){
        try {
            await Scheduler.setBroadcast(schedule.id, schedule.broadcast);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Could not update broadcast. Please try again later"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    }
}

const mutations = {
    addNewSchedule(state, schedule) {
        state.savedSchedules.push(schedule);
    },
    addSchedules(state, schedule) {
        state.savedSchedules = schedule
    },
    addScheduleDates(state, scheduledDates) {
        state.scheduledDates = scheduledDates
    },
    setSelectedSchedule(state, schedule) {
        state.selectedSchedule = schedule;
    },
    updateAdMessage(state, message) {
        state.adMessage = message;
    },
    deleteSchedule(state, scheduleId) {
        var foundIndex = state.savedSchedules.findIndex(x => x.id == scheduleId);
        state.savedSchedules.splice(foundIndex,1)
    },
    resetSchedule (state) {
        // acquire initial state
        const s = getSchedulerDefaultState()
        Object.keys(s).forEach(key => {
          state[key] = s[key]
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}