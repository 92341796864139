<template>
    <div class="progress-bar ml-3">
      <div class="progress-bar__fill" :style="{ width: `${percentage}%`, backgroundColor: activeColour }"></div>
    </div>
</template>

<script>
export default {
  props: {
    item: null,
    color: {
        type: String,
        default: '#C0C0C0'
    },
    ordinal: {
        type: Number,
        default: 0
    }
  },
  computed: {
    percentage(){
        let percent = (this.ordinal/this.item.intOne) * 100;
        return percent > 100 ? 100 : percent;
    },
    activeColour(){
        return this.percentage < 100 ? this.color : '#FFD700'
    }
  }
}
</script>

<style scoped>
.progress-bar {
  width: 100%;
  height: 16px;
  position: relative;
  display: flex;
  border: 2px solid black;
  border-radius: 10px;
}

.progress-bar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.6);
  border-radius: 10px;
  pointer-events: none; /* Allows interactions with the child elements */
}

.progress-bar__fill {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;

}
</style>