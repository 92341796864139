<template>
  <div class="home">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" class="d-flex justify-center">
          <v-card elevation="8" class="card" dark>
            <v-form @submit.prevent="login(userDetails)">
              <v-img
                alt="Bingo Manager Logo"
                class="shrink mt-2"
                contain
                height="140"
                src="@/assets/BM_Logo_Full_Size.png"
                transition="scale-transition"
              />
              <div>
                <v-row
                  :class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-10'"
                  class="d-flex justify-center"
                >
                  <v-col
                    v-if="hasRole(['ROLE_ADMIN','ROLE_SUPER_ADMIN'])"
                    cols="12"
                    md="6"
                    class="d-flex justify-center"
                    :class="$vuetify.breakpoint.smAndDown ? 'pb-0' : ''"
                  >
                    <v-btn
                      class="primary main_button"
                      v-if="hasRole(['ROLE_ADMIN','ROLE_SUPER_ADMIN'])"
                      block
                      id="scheduleManagerBtn"
                      :disabled="$vuetify.breakpoint.smAndDown"
                      @click="navigateTo('/scheduler/create')"
                      >New Session
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  :class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-10'"
                  class="d-flex justify-center"
                >
                  <v-col
                    v-if="hasRole(['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_SUPER_ADMIN', 'ROLE_CALLER'])"
                    cols="12"
                    md="6"
                    class="d-flex justify-center"
                    :class="$vuetify.breakpoint.smAndDown ? 'pb-0' : ''"
                  >
                    <v-btn
                      v-if="hasRole(['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_SUPER_ADMIN', 'ROLE_CALLER'])"
                      class="primary main_button"
                      block
                      @click="navigateTo('scheduler')"
                      >Existing Session</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row
                  :class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-10'"
                  class="d-flex justify-center"
                >
                  <v-col
                    v-if="hasRole(['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_SUPER_ADMIN'])"
                    cols="12"
                    md="6"
                    class="d-flex justify-center"
                    :class="$vuetify.breakpoint.smAndDown ? 'pb-0' : ''"
                  >
                    <v-btn v-if="hasRole(['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_SUPER_ADMIN'])"
                      class="primary main_button"
                      block
                      id="systemAdminBtn main_button"
                      :disabled="$vuetify.breakpoint.smAndDown"
                      @click="navigateTo('admin')"
                      >Back Office</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row justify="center" class="mb-3">
                  <v-col class="d-flex justify-center">
                    <v-btn class="accent mr-3 main_button" @click="logout" id="logoutBtn"
                      >Logout
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Home",
  data: () => ({
    userDetails: {
      userName: null,
      password: null,
    },
  }),
  mounted(){
    this.setCurrentPage("")
  },
  methods: {
    ...mapActions(["logout", "setCurrentPage"]),
    navigateTo(path) {
      this.$router.push({ path: path });
    },
    hasRole (roles) {
      const hasRole = roles.some(role => this.$store.state.auth.roles.includes(role));
      return hasRole;
    },
  }
};
</script>