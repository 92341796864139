<template>
  <v-container :fluid="playerView">
    <transition-group name="fade">
      <nintey-number-board-10by9 key="numberBoard" :fontSize="fontSize"/>
    </transition-group>
    <verification-dialog
      v-model="showModel"
      :playerView="playerView"
      :verifiedPanel="verifiedPanel"
      :fontSize="fontSize"
    ></verification-dialog>
  </v-container>
</template> 

<script>
import { mapGetters } from "vuex";
import VerificationDialog from "./VerificationDialog.vue";
import Utils from '@/utils'
import NinteyNumberBoard10by9 from './NinteyNumberBoard10by9.vue';

export default {
  name: "FixedOddBingoFlashboard",
  components: {
    VerificationDialog,
    NinteyNumberBoard10by9,
  },
  data: () => ({
    
    NinteyNumberBoardModel: true,
  }),
  props: {
    fontSize: {
      type: String,
      reqiured: false,
      default: null,
    },
    playerView: {
      type: Boolean,
      required: false,
      default: false,
    },
    verifiedPanel: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getDrawnNumbers", "getCurrentNumber", "getActiveGame"]),
  },
  methods: {
    calculateId(x, y) {
      return (x - 1) * 10 + y;
    },
    textColour(number){
      if (this.getCurrentNumber == number) {
        return Utils.getContrast(this.getActiveGame.colour.attributeValue);
      }
      return 'black';
    }
  },
};
</script>

<style scoped>
.callBorder {
  border-style: solid;
  border-width: 6px;
  border-color: goldenrod;
}
</style>