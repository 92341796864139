const getNavigationDefaultState = () => {
    return {
        currentPage: '',
    }
}

const state = getNavigationDefaultState();

const getters = {
    getCurrentPage: state => state.currentPage
};

const actions = {
    setCurrentPage({ commit }, currentPage) {
        commit('updateCurrentPage', currentPage);
    }
}

const mutations = {
    updateCurrentPage: (state, value) => {
        state.currentPage = value;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
