import SystemAdmin from '@/api/clients/bingoManager-core/systemAdmin.js';
import CONST from '@/const'

const getSystemAdminDefaultState = () => {
    return {
        systemColours: []
    }
}
const state = getSystemAdminDefaultState();

const getters = {
    getSystemColours: state => state.systemColours
}

const actions = {
    async saveColours({ dispatch }) {
        try {
            await SystemAdmin.saveColours(state.systemColours);
            this._vm.$snackbar({
                message: ("Colours Saved"),
                mode: CONST.THEME.COLOURS.SUCCESS
            }, dispatch);
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    async fetchColours({ commit, dispatch }) {
        try {
            const result = await SystemAdmin.fetchColours();
            commit('addColours', result.data)
        } catch (err) {
            this._vm.$snackbar({
                message: ("Oops, somethings gone wrong. Please try again"),
                mode: CONST.THEME.COLOURS.ERROR
            }, dispatch);
        }
    },
    addSystemColour({ commit }) {
        commit('addColour', {
            attributeName: "",
            attributeType: "SYSTEM_COLOURS",
            attributeValue: "",
            id: ""
        });
    }
}

const mutations = {
    addColours(state, colours) {
        state.systemColours = colours;
    },
    addColour(state, colour) {
        state.systemColours.push(colour);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}