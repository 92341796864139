<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-spacer />
      <v-col align="center" cols="5">
        <v-card>
          <v-card-title primary-title class="justify-center primary white--text">{{
            selectedSchedule.title
          }}</v-card-title>
          <v-card-subtitle class="text-h6 primary white--text">{{
            new Date(selectedSchedule.date).toDateString()
          }}</v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col class="text-subtitle-1"
                  >{{
                    selectedSchedule.sessionType[0] +
                    selectedSchedule.sessionType.substr(1).toLowerCase()
                  }}
                  Session</v-col
                >
              </v-row>
              <v-row class="mt-0">
                <v-spacer />
                <v-col
                  v-for="template in selectedSchedule.selectedTemplates"
                  :key="template"
                  dense
                  class="text-subtitle-2"
                >
                  {{ template }}
                </v-col>
                <v-spacer />
              </v-row>
              <v-row class="mt-0">
                <v-col class="text-h6">Game Order</v-col>
              </v-row>
              <v-row
                v-for="game in selectedSchedule.gameOrder"
                :key="game.id"
                dense
                justify="end"
              >
                <v-col>
                  <span :style="{ color: game.colour.attributeValue }">{{ game.colour.attributeName }} Page</span>{{
                  " - " +
                  gameString(game.winningPatterns)
                }}</v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-btn  v-if="getActiveGame" class="error ma-2" @click="endGame()" :disabled="gameStarted"
          >End GAME</v-btn
        >
        <v-btn  v-if="getActiveGame" class="accent ma-2" @click="joinGame()" :disabled="gameStarted"
          >Join GAME</v-btn
        >
        <v-btn v-if="scheduleOwner" class="error ma-2" @click="startGame()" :disabled="gameStarted"
          >START GAME</v-btn
        >
        <v-row align-content="center" justify="center">
          <v-col cols="8">
            <v-progress-linear
              v-show="gameStarted"
              color="primary accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CONST from "@/const";

export default {
  name: "VerifySchedule",
  data: () => ({
    gameStarted: false,
  }),
  computed: {
    ...mapGetters(["getScheduleList", "getActiveGame"]),
    selectedSchedule() {
      return this.getScheduleList.find(
        (schedule) => schedule.id == this.$route.params.scheduleId
      );
    },
    scheduleOwner() {
      return this.selectedSchedule.scheduleOwner;
    }
  },
  methods: {
    ...mapActions(["startSchedule", "endSchedule"]),
    startGame() {
      this.gameStarted = true;
      this.startSchedule(this.$route.params.scheduleId);
    },
    endGame() {
      this.gameStarted = false;
      this.endSchedule(this.$route.params.scheduleId);
    },
    joinGame() {
      localStorage.setItem("tenantId",this.selectedSchedule.tenantId);
      if (this.selectedSchedule.linkedGame && !this.selectedSchedule.scheduleOwner) {
        let route = this.$router.resolve({ name: "View Game", params: { scheduleId: this.getActiveGame.scheduleId } });
        window.open(route.href, "_blank");
      }
      else {
        console.log("type=" + this.selectedSchedule.type)
        this.$router.push("/play/game/" + this.selectedSchedule.type);
      }
      
    },
    gameColourString(template) {
      return template.gameColours
        .map((colour) => colour.attributeName)
        .join(", ");
    },
    gameString(winningPatterns) {
      let patternString = "";
      for (const pattern of winningPatterns) {
        if (pattern == CONST.GAME.ONE_LINE) {
          patternString += "Single Line, ";
        }
        else if (pattern == CONST.GAME.TWO_LINE) {
          patternString += "Double Line, ";
        }
        else if (pattern == CONST.GAME.FULL_HOUSE) {
          patternString += "Full House, ";
        }
      }
      let endChar = patternString.lastIndexOf(", ");
      return patternString.substring(0, endChar);
    },
  },
};
</script>