<script>
  (function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})(
    'cwr',
    'd7a6ac07-d6aa-40d3-8b6b-5d827c9776c7',
    '1.0.0',
    'eu-west-1',
    'https://client.rum.us-east-1.amazonaws.com/1.14.0/cwr.js',
    {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::766354984532:role/RUM-Monitor-eu-west-1-766354984532-9899449995961-Unauth",
      identityPoolId: "eu-west-1:38972c58-1822-4cf5-a7f5-f963e2b40919",
      endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    }
  );
</script>
<template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="!hideAppBar">
      <div class="d-flex align-center">
        <v-btn v-if="!hideBackButton" class="accent" rounded @click="back"
          ><v-icon small>mdi-arrow-left</v-icon></v-btn
        >

        <v-btn text disabled>
          <span class="mr-2 white--text">{{ getCurrentPage }}</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <v-img
        alt="Rock Bingo Logo"
        class="shrink mr-2"
        contain
        src="@/assets/BM_Logo_Full_Size.png"
        transition="scale-transition"
        width="140"
      />
      v1.1.0
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <span v-for="(snackbar, i) in getSnackbarQueue" :key="i">
      <snackbar :value="snackbar"></snackbar>
    </span>
    <bm-dialog :value="getDialogQueue" v-if="getDialogQueue"></bm-dialog>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Snackbar from "@/plugins/notifications/Snackbar";
import BmDialog from "@/plugins/notifications/Dialog";

export default {
  name: "App",
  computed: {
    ...mapGetters(["getSnackbarQueue", "getDialogQueue", "getCurrentPage"]),
    hideAppBar() {
      return this.$router.currentRoute.meta.hideAppBar == true;
    },

  },
  data(){
    return {
      hideBackButton: true
    }
  },
  watch: {
    $route(){
      this.hideBackButton = !!(this.$router.currentRoute.meta.hideBackButton == true)
    }
  },
  components: {
    Snackbar,
    BmDialog,
  },
  methods: {
    back() {
      if (this.$route.name == "Schedules") {
        this.$router.push("/");
      }
      else {
        this.$router.go(-1);
      }
      
    },
  },
  mounted() {
    console.log(process.env.VUE_APP_BASE_URL)
    console.log(process.env.NODE_ENV)
  },
};
</script>

<style>
.primary.main_button.v-btn {
      border-color: #9ac4f8 !important;
      border-style: double;
}
</style>

<style lang="scss">
.card{
    background-color: #2f3693 !important;
    border: 4px solid #FCBF49 !important;
    border-radius: 24px !important;
    position: relative;
    z-index: 1;
  
    &:before{
      position: absolute;
      content: "";
      background-image: url('../src/assets/session-left-bg.png');
      background-repeat: no-repeat;
      background-position: left;
      background-size: contain;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  
    &:after{
      position: absolute;
      content: "";
      background-image: url('../src/assets/session-top-bg.png');
      background-repeat: no-repeat;
      background-position: top;
      background-size: contain;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  
  }
</style>
