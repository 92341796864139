import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import Login from '../views/login/Login.vue'
import TemplateDesigner from '../views/templates/TemplateDesigner.vue'
import PlayableSchedules from '../views/scheduleAdmin/PlayableSchedules.vue'
import CreateSchedule from '../views/scheduleAdmin/CreateSchedule.vue'
import LottoProducts from '../views/products/LottoProducts.vue'
import BingoProducts from '../views/products/BingoProducts.vue'
import FOBProducts from '../views/products/FOBProducts.vue'
import Templates from '../views/templates/Templates.vue'
import Schedules from '../views/scheduleAdmin/Schedules.vue'
import CallerScreen from '../views/game/CallerScreen.vue'
import LottoCallerScreen from '../views/game/LottoCallerScreen.vue'
import FixedOddsBingoCallerScreen from '../views/game/FixedOddsBingoCallerScreen.vue'
import VerifySchedule from '../views/scheduleAdmin/VerifySchedule.vue'
import PlayerScreen from '../views/game/PlayerScreen.vue'
import FixedOddBingoPlayerScreen from '../views/game/FixedOddBingoPlayerScreen.vue'
import LottoPlayerScreen from '../views/game/LottoPlayerScreen.vue'
import PhysicalBooks from '../views/physical/PhysicalBooks.vue'
import Reporting from '../views/reporting/Reporting.vue'
import Books from '../views/books/Books.vue'
import Sales from '../views/sales/Sales.vue'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      needLogin: true,
      hideBackButton: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      needLogin: false,
      hideBackButton: true
    }
  },
  {
    path: '/template/designer',
    name: 'Template Designer',
    component: TemplateDesigner,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/sales/:scheduleId',
    name: 'Sales',
    component: Sales,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/scheduler',
    name: 'Schedules',
    component: Schedules,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/caller/schedules',
    name: 'PlayableSchedules',
    component: PlayableSchedules,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/start/:scheduleId',
    name: 'Caller',
    component: VerifySchedule,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/play/game/fixed_odds_bingo',
    name: 'Game',
    component: FixedOddsBingoCallerScreen,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/play/game/lotto',
    name: 'Game',
    component: LottoCallerScreen,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/play/game/bingo',
    name: 'Game',
    component: CallerScreen,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/scheduler/create',
    name: 'Create Scheduler',
    component: CreateSchedule,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/products/fixed_odds_bingo/:scheduleId',
    name: 'FOBProducts',
    component: FOBProducts,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/products/bingo/:scheduleId',
    name: 'BingoProducts',
    component: BingoProducts,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/products/lotto/:scheduleId',
    name: 'LottoProducts',
    component: LottoProducts,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/reporting',
    name: 'Reporting',
    component: Reporting,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/play/game/view/:scheduleId',
    name: 'View Game',
    component: PlayerScreen,
    meta: {
      needLogin: false,
      hideAppBar: true
    }
  },
  {
    path: '/play/game/viewFixedOddBingo/:scheduleId',
    name: 'View Fixed Odds Bingo Game',
    component: FixedOddBingoPlayerScreen,
    meta: {
      needLogin: false,
      hideAppBar: true
    }
  },
  {
    path: '/play/game/viewlotto/:scheduleId',
    name: 'View Lotto Game',
    component: LottoPlayerScreen,
    meta: {
      needLogin: false,
      hideAppBar: true
    }
  },
  {
      path:'/products/physical',
      name: 'Physical',
      component: PhysicalBooks,
      meta: {
        needLogin: true
      }
  },
  {
    path:'/books',
    name: 'Books',
    component: Books,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/systemAdmin/Admin.vue'),
    meta: {
      needLogin: true,
      hideAppBar: true
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  let proceed = true;
  if (to.meta.needLogin && !store.getters.getToken) {
    proceed = false;
  }

  proceed ? next() : next('/login');
});


export default router
