<template>
  <v-carousel v-model="model" light hide-delimiters :show-arrows="false" height="auto">
    <v-carousel-item>
      <reporting-books-table @templateSelected="showPrintOptions"/>
    </v-carousel-item>
    <!-- <v-carousel-item> 
        <print-books-options @returnToTemplates="showTemplates" ref="printOptions"/>
    </v-carousel-item> -->
  </v-carousel>
</template>

<script>
import ReportingBooksTable from "./ReportingBooksTable.vue";
export default {
  components: { ReportingBooksTable },
  name: "ReportingBooksHome",
  data() {
    return {
      model: 0,
    };
  },
  methods: {
      showPrintOptions(){
          this.model = 1;
      },
      showTemplates(){
        this.$refs.printOptions.resetValues();
        this.model = 0;
      }
  }
};
</script>